import {CURRENT_API_BASE_URL} from "../../../util/constants";
import {multipleClasses} from "../../../util";
import Icon from "../../../components/icon/Icon";
import CheckIcon from "../../../assets/icons/send.svg";
import stylesVariables from "../../../assets/styles/_variables.scss";
import React from "react";
import activityImageClasses from "./activityImage.module.scss";
import {useSelector} from "react-redux";

const {
    containerImage,
    imageElement,
    imageSelected,
    imageSelectedCheckbox,
    containerImages,
    noImages,
    informationApiMessageContainer,
    noImagesText
} = activityImageClasses

export const ActivityImageSelector = ({handleSelectedImage, images, imgSelected}) => {
    const {
        noImagetext
    } = useSelector((state) => state.language[state.language.default]);
    return (
            <div
                className={images.length === 0 ? noImages : containerImages}
            >
                {images.length === 0 && (
                    <div className={informationApiMessageContainer}>
                        <span className={noImagesText}>
                            {noImagetext}
                        </span>
                    </div>
                )}
                {images.map((image) => {
                    return (
                        <div key={image.id} className={containerImage} onClick={() => handleSelectedImage(image)}>
                            <img src={`${CURRENT_API_BASE_URL}${image.filePath}`} className={imgSelected.id === image["@id"] ? multipleClasses(imageElement, imageSelected) : imageElement} alt={image.alt}/>
                            {imgSelected.id === image["@id"] && (
                                <div className={imageSelectedCheckbox}>
                                    <Icon
                                        src={CheckIcon}
                                        size={26}
                                        color={stylesVariables.siteColor3}
                                    />
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
    )
}
