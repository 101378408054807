import inputClasses from "./input.module.scss";
import {createRef, useCallback, useEffect} from "react";
import {showNotification} from "../../util";
import {useSelector} from "react-redux";
import {DANGER} from "../../util/constants";

const {
    timePickerOverlay,
    timeSection,
    time,
    validate
} = inputClasses;

/**
 * Forced time picker for incompatible browser
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const ForcedTimePicker = ({hide, onChange, value}) => {

    const timeRef = createRef();

    useEffect(() => {
        if (timeRef.current) {
            timeRef.current.focus()
        }
    }, [timeRef])

    const {
        chooseValidTime
    } = useSelector((state) => state.language[state.language.default]);

    const sendTime = useCallback(() => {
        if (timeRef.current && timeRef.current.value !== "") {
            onChange && onChange({target: {value: timeRef.current.value}});
            hide()
        } else {
            showNotification(chooseValidTime, DANGER)
        }
    }, [timeRef, onChange, chooseValidTime, hide])

    const handleKeyUp = useCallback((event) => {

        if (event.keyCode === 13) {
            sendTime()
        }
    },[sendTime])

    return <div onKeyUp={handleKeyUp} onClick={hide} className={timePickerOverlay}>
        <div onClick={event => event.stopPropagation()} className={timeSection}>
            <input onChange={onChange} value={value} ref={timeRef} className={time} type="time"/>
            <div onClick={sendTime} className={validate}>OK</div>
        </div>
    </div>
}

export default ForcedTimePicker