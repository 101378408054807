import loginPopUpClasses from "./loginPopUp.module.scss";
import {useSelector} from "react-redux";
import Button from "../../components/button/Button";
import {APP_ROUTE} from "../../util/constants";
import Link from "../../components/link/Link";
import {globalDispatcher} from "../../util";
import {closeModal} from "../../redux/modalSlice/modalSlice";

const {wrapper, title, subTitle, authButton,linkClassName} = loginPopUpClasses;

/**
 * Login Popup component
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const LoginPopUp = () => {

    const {
        authRequired,
        mustLogin,
        loginText,
        createAnAccount
    } = useSelector((state) => state.language[state.language.default]);

    return <div className={wrapper}>
        <div className={title}>{authRequired}</div>
        <div className={subTitle}>{mustLogin}</div>
        <Link linkClassName={linkClassName} target={APP_ROUTE.LOGIN}>
            <Button onClick={() => globalDispatcher(closeModal, null)} title={loginText} className={[authButton]}
                    label={loginText}/>
        </Link>
        <Link linkClassName={linkClassName} target={APP_ROUTE.REGISTER_OPTIONS}>
            <Button onClick={() => globalDispatcher(closeModal, null)} title={createAnAccount} className={[authButton]}
                    label={createAnAccount}/>
        </Link>
    </div>
}

export default LoginPopUp
