import {makeIndex, multipleClasses} from "../../../util";
import defaultAvatar from "../../../assets/avatar/defaultAvatar.png";
import Button from "../../../components/button/Button";
import {PROCESSING} from "../../../util/constants";
import stylesVariables from "../../../assets/styles/_variables.scss";
import MessagesIcon from "../../../assets/icons/messages.svg";
import SentIcon from "../../../assets/icons/check.svg";
import AddUserIcon from "../../../assets/icons/addUser.svg";
import membersClasses from "./members.module.scss";
import TransportIcon from "../../../assets/icons/transport.svg";
import GuideIcon from "../../../assets/icons/guide.svg"
import {useSelector} from "react-redux";
import Icon from "../../../components/icon/Icon";
import React from "react";

const {
    avatar,
    member,
    memberDetails,
    memberUsername,
    friendClass,
    notFriendClass,
    sentClass,
    friendClassOwner,
    notFriendClassOwner,
    iconInformationWrapper,
    memberOwnerContainer,
    personInformation,
    personInformationAvatar,
    containerGuideShuttle,
    containerGuideShuttleShuttle,
    containerGuideShuttleGuide,
    containerIcon,
    textIcon
} = membersClasses;

/**
 *
 * @param p participant
 * @param isPassed
 * @param cancelParticipation
 * @param engageConversation
 * @param receiveInvitations
 * @param askFriend
 * @param index
 * @param workingState
 *
 * @returns {*|JSX.Element}
 *
 * @constructor
 */
export const MemberOwner = ({p, isPassed, cancelParticipation, engageConversation, receiveInvitations, askFriend, index, workingState}) => {
    const {
        cancelRegister,
        activityAlreadyPassed,
        hideProfile,
        guide
    } = useSelector((state) => state.language[state.language.default]);

    const auth = useSelector((state) => state.auth.data);
    const authId = auth["@id"];

    return (
        <div className={multipleClasses(member, memberOwnerContainer)} key={makeIndex('members', p.id)}>
            <div className={personInformation}>
                <div className={personInformationAvatar}>
                    <img
                        src={p.user["@id"] === authId ? p.user.filePath ?? defaultAvatar : p.user.profilVisibleActivities ? p.user.filePath ?? defaultAvatar : defaultAvatar}
                        alt="avatar" className={avatar}/>
                    <div
                        className={memberUsername}>{p.user["@id"] === authId ? p.user.username : p.user.profilVisibleActivities ? p.user.username : hideProfile}</div>
                </div>
                <div className={memberDetails}>
                    {p.user["@id"] === authId ?
                        <Button
                            title={isPassed ? activityAlreadyPassed : cancelRegister}
                            className={[isPassed ? sentClass : notFriendClass]}
                            label={isPassed ? activityAlreadyPassed : cancelRegister}
                            onClick={isPassed ? null : () => cancelParticipation(p)}
                            indicator={
                                workingState[p.id] === PROCESSING && {
                                    color: stylesVariables.neutralColor
                                }}
                        />
                        :
                        p.user.profilVisibleActivities ? p.user.isFriend ?
                            <Button
                                onClick={() => engageConversation(p.user)}
                                icon={{
                                    src: MessagesIcon,
                                    color: stylesVariables.siteColor3,
                                    size: 20
                                }}
                                className={[friendClass, friendClassOwner]}
                        />
                            :
                            (p.user.isFriendPending || receiveInvitations[p.user["@id"]]) ? <Button
                                icon={{
                                    src: SentIcon,
                                    color: stylesVariables.neutralColor,
                                    size: 20
                                }}
                                className={[sentClass, friendClassOwner]}
                                />
                                :
                                <Button
                                onClick={() => askFriend(p, index)}
                                icon={{
                                    src: AddUserIcon,
                                    keepColor: true,
                                    size: 20
                                }}
                                className={[notFriendClass, notFriendClassOwner]} indicator={
                                workingState[p.id] === PROCESSING && {
                                    color: stylesVariables.neutralColor
                                }
                            }
                                /> : null
                    }
                </div>
            </div>
            {(p.guide || p.wantShuttle) && (
                <div className={containerGuideShuttle}>
                    {p.wantShuttle && (
                        <div className={containerGuideShuttleShuttle}>
                            <div className={containerIcon}>
                                <Icon
                                    color={"#80070C"}
                                    wrapperClassName={iconInformationWrapper}
                                    src={TransportIcon}
                                    size={18}
                                    noStroke={true}
                                />
                            </div>
                            <span className={textIcon}>{p.shuttleLocalisation}</span>
                        </div>
                    )}
                    {p.guide && (
                        <div className={containerGuideShuttleGuide}>
                            <div className={containerIcon}>
                                <Icon
                                    color={"#80070C"}
                                    wrapperClassName={iconInformationWrapper}
                                    src={GuideIcon}
                                    size={18}
                                    noStroke={true}
                                />
                            </div>
                            <span className={textIcon}>{guide}</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
