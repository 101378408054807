import friendsClasses from "../friends.module.scss";
import {useSelector} from "react-redux";
import MessageIcon from "../../../assets/icons/messages.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import ReportIcon from "../../../assets/icons/report.svg";
import BlockIcon from "../../../assets/icons/block.svg";
import stylesVariables from "../../../assets/styles/_variables.scss";
import Icon from "../../../components/icon/Icon";
import {useCallback, useState} from "react";
import {API, globalDispatcher, SAFE, showNotification} from "../../../util";
import {
    APP_ROUTE,
    CURRENT_ENDPOINT,
    DANGER,
    FAILED,
    FRIENDS_BLOCK,
    FRIENDS_OPTIONS,
    FRIENDS_REPORT,
    PROCESSING,
    SUCCESS,
    WARNING
} from "../../../util/constants";
import ActivityIndicator from "../../../components/activityIndicator/ActivityIndicator";
import {closeModal, showModal} from "../../../redux/modalSlice/modalSlice";
import {useNavigate} from "react-router-dom";

const {friendsOptions,deskFriendsOptions, header, card, cardEl, cardElLabel} = friendsClasses;

/**
 * Friends options component
 * @param choice
 * @param onAction {function}
 * @param mobile
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const FriendsOptions = ({choice, onAction, mobile}) => {

    const {
        blockContact,
        removeFromFriends,
        reportProfile,
        sendMessage,
        removingFriendInProgress, friendRemoved, serverError
    } = useSelector((state) => state.language[state.language.default]);

    const auth = useSelector((state) => state.auth.data)

    const [workingState, setWorkingState] = useState("")

    const removeFriend = useCallback(() => {
        if (workingState === PROCESSING) {
            showNotification(removingFriendInProgress, WARNING);
            return
        }

        setWorkingState(PROCESSING);

        API.delete(CURRENT_ENDPOINT.FRIENDS.ONE.replace(":id", choice.id))
            .then(response => {
                if (response.status === 204) {
                    setWorkingState(SUCCESS)
                    showNotification(friendRemoved, SUCCESS);
                    onAction && onAction(choice);
                    globalDispatcher(closeModal, null);
                }
            }).catch(_ => {
            setWorkingState(FAILED)
            showNotification(serverError, DANGER);
        })

    }, [choice, workingState, onAction, friendRemoved, serverError, removingFriendInProgress]);

    const block = useCallback(() => {
        globalDispatcher(showModal, {
            type: FRIENDS_BLOCK,
            wrapperStyles: {
                height: mobile ? "90%": "50%",
                top: "55%",
                width: "100%",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
            choice: choice,
            action: onAction,
            backTo: FRIENDS_OPTIONS,
            extra: {
                mobile
            }
        });
    }, [choice, onAction,mobile])

    const report = useCallback(() => {
        globalDispatcher(showModal, {
            type: FRIENDS_REPORT,
            wrapperStyles: {
                height: mobile ? "90%": "50%",
                top: "55%",
                width: "100%",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
            choice: choice,
            action: onAction,
            backTo: FRIENDS_OPTIONS,
            extra: {
                mobile
            }
        });
    }, [choice, onAction,mobile])

    const navigate = useNavigate();

    const target = choice.userTo["@id"] === auth["@id"] ? "userFrom" : "userTo"

    const openChat = useCallback(() => {
        navigate(APP_ROUTE.MESSAGE.replace(":id", SAFE.encode(choice[target].id + "_" + choice[target].username)));
        globalDispatcher(closeModal, null)
    }, [choice, navigate, target])

    return <div className={mobile ? friendsOptions: deskFriendsOptions}>
        <div className={header}>{choice[target].username}</div>
        <div className={card}>
            {mobile && <div onClick={openChat} className={cardEl}>
                <Icon noStroke={true} src={MessageIcon} color={stylesVariables.siteColor3} size={26}/>
                <div className={cardElLabel}>{sendMessage}</div>
            </div>}
            {
                mobile ? <>
                    <div onClick={block} className={cardEl}>
                        <Icon noStroke={true} src={BlockIcon} keepColor size={32}/>
                        <div className={cardElLabel}>{blockContact}</div>
                    </div>
                    <div onClick={removeFriend} className={cardEl}>
                        {workingState !== PROCESSING ?
                            <Icon noStroke={true} src={CloseIcon} color={stylesVariables.siteColor3} size={22}/> :
                            <ActivityIndicator color={stylesVariables.siteColor3}/>}
                        <div className={cardElLabel}>{removeFromFriends}</div>
                    </div>
                    <div onClick={report} className={cardEl}>
                        <Icon noStroke={true} src={ReportIcon} keepColor size={32}/>
                        <div className={cardElLabel}>{reportProfile}</div>
                    </div>
                </> : <>
                    <div onClick={removeFriend} className={cardEl}>
                        {workingState !== PROCESSING ?
                            <Icon noStroke={true} src={CloseIcon} color={stylesVariables.siteColor3} size={22}/> :
                            <ActivityIndicator color={stylesVariables.siteColor3}/>}
                        <div className={cardElLabel}>{removeFromFriends}</div>
                    </div>
                    <div onClick={report} className={cardEl}>
                        <Icon noStroke={true} src={ReportIcon} keepColor size={32}/>
                        <div className={cardElLabel}>{reportProfile}</div>
                    </div>
                    <div onClick={block} className={cardEl}>
                        <Icon noStroke={true} src={BlockIcon} keepColor size={32}/>
                        <div className={cardElLabel}>{blockContact}</div>
                    </div>
                </>
            }
        </div>
    </div>
}

export default FriendsOptions