import {createSlice} from '@reduxjs/toolkit'
import {API} from "../../util";
import {CURRENT_ENDPOINT} from "../../util/constants";


/**
 * Auth slice
 * A reducer for the authenticated user
 * @author Arnaud LITAABA
 */
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        comeFrom: window.location.pathname,
        data: {}
    },
    reducers: {
        login: (state, actions) => {
            state.data = {
                ...actions.payload
            }
        },
        setRedirectRoute: (state, action) => {
            state.comeFrom = action.payload
        },
        update: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload
            }
        },
        logout: (state) => {
            state.data = {}
        }
    }
})

export const {update, logout,setRedirectRoute} = authSlice.actions;


/**
 * Connect user
 * A login function to connect the authenticated user asynchronously
 * @param authInfo {object} All user information needed by the app
 * @param callback {function} A callback on action
 * @return function A dispatcher to dispatch the action and sync with reducer
 * @author Arnaud LITAABA
 */
export const connectUser = (authInfo, callback) => (dispatch) => {
    API.post(CURRENT_ENDPOINT.AUTH.LOGIN, authInfo, true).then(response => {
        if(response.status === 200) {
            localStorage.setItem("jwtToken", response.data.token)
            localStorage.setItem("jwtRefreshToken", response.data.refresh_token)
        }
        callback({
            status: response.status
        });
    }).catch(error => {
        callback({
            status: error.response.status,
        })
    })
}

/**
 * Register user
 * A register function to create user account asynchronously
 * @param registerInfo {object} All user information needed by the app
 * @param step {string} The current step onf the creation process
 * @param callback {function} A callback on action
 * @return function A dispatcher to dispatch the action and sync with reducer
 * @author Arnaud LITAABA
 */
export const registerUser = (registerInfo, step, callback) => (_) => {
    API.post(CURRENT_ENDPOINT.AUTH.REGISTER[step], registerInfo, true).then(response => {
        callback({
            status: response.status,
            data: response.data,
        })
    }).catch(error => {
        callback({
            status: error.response.status,
        })
    })
}

/**
 * Init update password user
 * A update function to help user retrieve its account
 * @param updateInfo {object} All user information needed by the app
 * @param step {string} The current step onf the creation process
 * @param callback {function} A callback on action
 * @return function A dispatcher to dispatch the action and sync with reducer
 * @author Arnaud LITAABA
 */
export const initUpdatePassword = (updateInfo, step, callback) => (_) => {
    API.post(CURRENT_ENDPOINT.AUTH.FORGOT_PASSWORD[step], updateInfo, true).then(response => {
        callback({
            status: response.status,
            data: response.data,
        })
    }).catch(error => {
        callback({
            status: error.response.status,
        })
    })
}

/**
 * Logout user
 * A logout function to disconnect the authenticated user asynchronously
 * @return function A dispatcher to dispatch the action and sync with reducer
 * @author Arnaud LITAABA
 */
export const disconnectUser = () => (dispatch) => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("jwtRefreshToken");
    dispatch(logout())
}

export default authSlice.reducer