import deleteProfileClasses from "./deleteProfile.module.scss";
import Button from "../../../components/button/Button";
import Icon from "../../../components/icon/Icon";
import stylesVariables from "../../../assets/styles/_variables.scss";
import ProfileLogo from "../../../assets/otherPic/profile.svg";
import {useCallback, useState} from "react";
import {PROCESSING, SUCCESS,} from "../../../util/constants";
import {useSelector} from "react-redux";


const {
    wrapper,
    header,
    headerLogo,
    title,
    subTitle,
    validateButton
} = deleteProfileClasses;

/**
 * Register to activity Popup component
 * @param choice {any}
 * @param oneDone {function} The action on done
 * @param mode
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const DeleteProfile = ({choice: activity, oneDone, mode}) => {
    const {
        profileText,
        confirm,
        confirmDeleteProfile,
        // serverError,
        // successDeleteProfile
        // deletingProfile
    } = useSelector((state) => state.language[state.language.default]);

     const auth = useSelector((state) => state.auth.data);


    const [workingState, setWorkingState] = useState("")


    const next = useCallback(() => {
        setWorkingState(SUCCESS)
    }, [])


    return <div className={wrapper}>
        <div className={header}>{profileText}</div>
        <Icon keepColor wrapperClassName={headerLogo} color={stylesVariables.siteColor3} src={ProfileLogo} size={180}/>
        <div className={title}>
            {auth.username}
        </div>
        <div className={subTitle}>
            {confirmDeleteProfile}
        </div>

        <Button indicator={workingState === PROCESSING ? {
            color: stylesVariables.neutralColor
        } : false} onClick={next} className={[validateButton]} title={confirm}
                label={confirm}/>
    </div>
}

export default DeleteProfile