import chatsSuggestionsClasses from "../../messages/messages.module.scss";
import {useSelector} from "react-redux";
import stylesVariables from "../../../assets/styles/_variables.scss";
import SearchIcon from "../../../assets/icons/search.svg"
import Icon from "../../../components/icon/Icon";
import ClassifiedData from "../../../components/classifedData/ClassifiedData";
import {NestedFallbackLoading} from "../../loading/fallbackLoading/FallBackLoading";
import {APP_ROUTE, CURRENT_ENDPOINT, DANGER} from "../../../util/constants";
import FriendsCard from "../../../components/friendsCard/FriendsCard";
import {API, globalDispatcher, makeIndex, SAFE, showNotification} from "../../../util";
import {createRef, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {closeModal} from "../../../redux/modalSlice/modalSlice";

const {
    chatsSuggestionsOptions,
    headerBlack,
    searchWrapper, input, toClass, defaultValueClass, defaultWrapper, friendsList, friendsListEl, emptyClass
} = chatsSuggestionsClasses;

/**
 * Chat suggestions component
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const ChatSuggestions = () => {

    const {
        newMessage,
        makeSearch,
        to, suggestion, canNotRetrieveMessages, noResult
    } = useSelector((state) => state.language[state.language.default]);

    const [friendsState, setFriendsState] = useState({
        loading: true,
        data: []
    })

    useEffect(() => {
        API.get(CURRENT_ENDPOINT.FRIENDS.BASE).then(response => {
            setFriendsState(_ => {
                return {
                    data: [...response.data["hydra:member"].filter(f => f.accepted)],
                    loading: false
                }
            })
        }).catch(_ => {
            setFriendsState(state => {
                return {
                    ...state,
                    loading: false
                }
            })
            showNotification(canNotRetrieveMessages, DANGER)
        })
    }, [canNotRetrieveMessages])

    const emptyRef = createRef();

    const search = (searchValue) => {
        let elements = document.getElementsByClassName(friendsListEl);
        let found = 0;
        Array.from(elements).forEach(element => {
            let txtValue = element.innerHTML;
            if (txtValue.toLowerCase().indexOf(searchValue) > -1 || txtValue.indexOf(searchValue) > -1 || txtValue.toUpperCase().indexOf(searchValue) > -1) {
                element.style.display = "flex";
                found += 1;
            } else {
                element.style.display = "none";
            }
        });
        if (found === 0 && emptyRef.current) {
            emptyRef.current.style.display = "flex"
        } else {
            emptyRef.current.style.display = "none"
        }
    }

    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth.data);

    const openChat = useCallback((f) => {
        const target = f.userTo["@id"] === auth["@id"] ? "userFrom" : "userTo";
        navigate(APP_ROUTE.MESSAGE.replace(":id", SAFE.encode(f[target].id + "_" + f[target].username)));
        globalDispatcher(closeModal, null)
    }, [navigate, auth])

    return <div className={chatsSuggestionsOptions}>
        <div className={headerBlack}>{newMessage}</div>
        <div className={searchWrapper}>
            <div className={toClass}>{to}</div>
            <input onChange={({target}) => search(target.value)} className={input} type="text"
                   placeholder={makeSearch}/>
            <Icon src={SearchIcon} color={stylesVariables.siteColor3}/>
        </div>
        <ClassifiedData wrapperClasses={[defaultWrapper]} valueClasses={[defaultValueClass]}
                        title={suggestion}/>
        <div className={friendsList}>
            {
                friendsState.loading ? <NestedFallbackLoading/> :
                    friendsState.data.map(f => {
                        return <FriendsCard classNames={[friendsListEl]}
                                            onMessage={() => openChat(f)}
                                            mobile={true} data={f}
                                            key={makeIndex("chats-suggestions-friends", f["@id"])}/>

                    })
            }
        </div>
        <div className={emptyClass} ref={emptyRef}>
            {noResult}
        </div>
    </div>
}

export default ChatSuggestions