import classifiedDataClasses from "./classifiedData.module.scss";
import {multipleClasses} from "../../util";

const {
    classifiedActivities,
    classifiedActivitiesHeader,
    classifiedActivitiesContent,
    line, value
} = classifiedDataClasses;

/**
 * Classified Data
 * @param title
 * @param lineClasses {string[]}
 * @param valueClasses {string[]}
 * @param wrapperClasses {string[]}
 * @param headerClasses {string[]}
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
const ClassifiedData = ({
                            title,
                            lineClasses = [],
                            valueClasses = [],
                            wrapperClasses = [],
                            headerClasses = [],
                            ...rest
                        }) => {

    return <div className={multipleClasses(classifiedActivities, ...wrapperClasses)}>
        <div className={multipleClasses(classifiedActivitiesHeader, ...headerClasses)}>
            <div className={multipleClasses(line, ...lineClasses)}/>
            <div className={multipleClasses(value, ...valueClasses)}>{title}</div>
            <div className={multipleClasses(line, ...lineClasses)}/>
        </div>
        {rest.children && <div className={classifiedActivitiesContent}>
            {rest.children}
        </div>}
    </div>
}

export default ClassifiedData