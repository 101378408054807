import friendsClasses from "../../friends.module.scss";
import React, {useCallback, useState} from "react";
import {
    APP_ROUTE,
    CURRENT_ENDPOINT,
    DANGER,
    FAILED,
    PROCESSING,
    SUCCESS,
    WARNING
} from "../../../../util/constants";
import {API, globalDispatcher, multipleClasses, SAFE, showNotification} from "../../../../util";
import {setCustomData} from "../../../../redux/globalSlice/globalSlice";
import defaultAvatar from "../../../../assets/avatar/defaultAvatar.png";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeModal} from "../../../../redux/modalSlice/modalSlice";
import Icon from "../../../../components/icon/Icon";
import MessagesIcon from "../../../../assets/icons/messages.svg";
import SentIcon from "../../../../assets/icons/check.svg";
import AddUser from "../../../../assets/icons/add_user.svg";
import stylesVariables from "../../../../assets/styles/_variables.scss";
import FriendsCardClasses from "../../../../components/friendsCard/friendsCard.module.scss";
const {deskWrapper, options, optionsBtn, optionsBtnLabel, send} = FriendsCardClasses;

/**
 *
 * @param manager Activity manager
 * @returns {JSX.Element}
 * @constructor
 */
export const AddManagerToFriend = ({manager}) => {
    const [workingState, setWorkingState] = useState({})
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        sendMessage,
        addFriend,
        friendRequestSent,
        askingFriend,
        serverError
    } = useSelector((state) => state.language[state.language.default])

    const askFriend = useCallback(async (p) => {
        if (workingState[p.id] === PROCESSING || p.isFriendPending || workingState[p.id] === SUCCESS) {
            showNotification(askingFriend, WARNING);
            return
        }
        setWorkingState(state => {
            return {
                ...state,
                [p.id]: PROCESSING
            }
        })
        try {
            const response = await API.post(CURRENT_ENDPOINT.FRIENDS.ASK, {userTo: p["@id"]}, false, {"Content-Type": "application/ld+json"});
            if (response.status === 201) {
                showNotification(friendRequestSent, SUCCESS);
                setWorkingState(state => {
                    return {
                        ...state,
                        [p.id]: SUCCESS
                    }
                })
                globalDispatcher(setCustomData, {
                    target: "receiveInvitations",
                    data: {
                        [p["@id"]]: true
                    }
                })
            }
        } catch (e) {
            setWorkingState(state => {
                return {
                    ...state,
                    [p.id]: FAILED
                }
            })
            showNotification(serverError, DANGER);
        }
    }, [workingState, serverError, askingFriend, friendRequestSent])

    const openChat = useCallback(() => {
        navigate(APP_ROUTE.MESSAGE.replace(":id", SAFE.encode(manager.id + "_" + manager.username)));
        dispatch(closeModal())
    }, [navigate])

    return (
        <div className={friendsClasses.addManagerWrapper}>
            <h3 className={friendsClasses.manager}>Organisateur</h3>
            <div className={friendsClasses.avatarWrapper}>
                <img src={manager.filePath ?? defaultAvatar} alt="avatar" className={friendsClasses.avatar}/>
                <h4 className={friendsClasses.pseudo}>{manager.username}</h4>
            </div>
            <div className={multipleClasses(deskWrapper, friendsClasses.messageWrapper)}>
                <div className={multipleClasses(options, friendsClasses.options)}>
                    <div onClick={openChat} className={multipleClasses(friendsClasses.optionsBtn, send, friendsClasses.messageBtn)}>
                        <Icon size={22} globalWrapperClassName={options} src={MessagesIcon}
                              color={stylesVariables.siteColor3}/>
                        <span className={optionsBtnLabel}>{sendMessage}</span>
                    </div>
                    {!manager.isFriend && (
                        <div onClick={() => askFriend(manager)} className={multipleClasses(optionsBtn, send)}>
                            <Icon
                                size={22}
                                globalWrapperClassName={options}
                                src={manager.isFriendPending || workingState[manager.id] === PROCESSING || workingState[manager.id] === SUCCESS ? SentIcon : AddUser}
                                color={stylesVariables.neutralColor}
                            />
                            <span className={optionsBtnLabel}>{manager.isFriendPending || workingState[manager.id] === PROCESSING || workingState[manager.id] === SUCCESS ? friendRequestSent : addFriend}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
