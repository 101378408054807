import {useSelector} from "react-redux";
import {
    APP_ROUTE,
    CURRENT_ENDPOINT,
    DANGER,
    FAILED,
    PROCESSING,
    SUCCESS,
    WARNING
} from "../../../util/constants";
import {useCallback, useState} from "react";
import {API, globalDispatcher, makeIndex, SAFE, showNotification} from "../../../util";
import {useNavigate} from "react-router-dom";
import {closeModal} from "../../../redux/modalSlice/modalSlice";
import {setCustomData} from "../../../redux/globalSlice/globalSlice";
import {MemberNotOwner} from "./MemberNotOwner";
import {MemberOwner} from "./MemberOwner";

const {FRIENDS, ACTIVITIES} = CURRENT_ENDPOINT;

/**
 * Members component
 * @param isActivityOwner
 * @param participants
 * @param onFriendRequest
 * @param onCancelParticipation
 * @param isPassed
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Members = ({isActivityOwner, participants = [], onFriendRequest, onCancelParticipation, isPassed}) => {

    const {
        askingFriend, cancelingInscription,
        serverError, friendRequestSent, successCancelInscription
    } = useSelector((state) => state.language[state.language.default]);

    const auth = useSelector((state) => state.auth.data);

    const {receiveInvitations} = useSelector((state) => state.global.data);

    const [workingState, setWorkingState] = useState({})

    const askFriend = useCallback((p, index) => {

        if (workingState[p.id] === PROCESSING) {
            showNotification(askingFriend, WARNING);
            return
        }
        setWorkingState(state => {
            return {
                ...state,
                [p.id]: PROCESSING
            }
        })

        API.post(FRIENDS.ASK, {userTo: p.user["@id"]}, false, {"Content-Type": "application/ld+json"}).then(response => {
            if (response.status === 201) {
                showNotification(friendRequestSent, SUCCESS);
                setWorkingState(state => {
                    return {
                        ...state,
                        [p.id]: SUCCESS
                    }
                })
                onFriendRequest && onFriendRequest(index);

                globalDispatcher(setCustomData, {
                    target: "receiveInvitations",
                    data: {
                        [p.user["@id"]]: true
                    }
                })
            }
        }).catch(_ => {
            setWorkingState(state => {
                return {
                    ...state,
                    [p.id]: FAILED
                }
            })
            showNotification(serverError, DANGER);
        })

    }, [workingState, serverError, askingFriend, friendRequestSent, onFriendRequest])

    const navigate = useNavigate();

    const engageConversation = useCallback((target) => {
        navigate(APP_ROUTE.MESSAGE.replace(":id", SAFE.encode(target.id + "_" + target.username)));
        globalDispatcher(closeModal, null)
    }, [navigate])

    const cancelParticipation = useCallback((p) => {

        if (workingState[p.id] === PROCESSING) {
            showNotification(cancelingInscription, WARNING);
            return
        }
        setWorkingState(state => {
            return {
                ...state,
                [p.id]: PROCESSING
            }
        })


        API.delete(ACTIVITIES.CANCEL_INSCRIPTION.replace(":id", p.id)).then(_ => {
            showNotification(successCancelInscription, SUCCESS, 4000);
            setWorkingState(state => {
                return {
                    ...state,
                    [p.id]: SUCCESS
                }
            })

            onCancelParticipation && onCancelParticipation(p);
        }).catch(_ => {
            setWorkingState(state => {
                return {
                    ...state,
                    [p.id]: FAILED
                }
            })
            showNotification(serverError, DANGER);
        })
    }, [successCancelInscription, cancelingInscription, serverError, workingState, onCancelParticipation])

    return <>
        {participants.map((p, index) => {
            return isActivityOwner ?
                <MemberOwner
                    key={makeIndex('members', p.id)}
                    askFriend={askFriend}
                    p={p}
                    index={index}
                    engageConversation={engageConversation}
                    isPassed={isPassed}
                    workingState={workingState}
                    cancelParticipation={cancelParticipation}
                    receiveInvitations={receiveInvitations}
                /> :
                <MemberNotOwner
                    key={makeIndex('members', p.id)}
                    askFriend={askFriend}
                    p={p}
                    index={index}
                    engageConversation={engageConversation}
                    isPassed={isPassed}
                    workingState={workingState}
                    cancelParticipation={cancelParticipation}
                    receiveInvitations={receiveInvitations}
                />
        })}
    </>
}

export default Members
