import Axios from "axios";
import {store} from "../redux/store";
import {
    APP_ROUTE,
    AS_NUMBER,
    CLEAR,
    CURRENT_API_BASE_URL,
    CURRENT_ENDPOINT,
    DANGER,
    DEFAULT_LANGUAGE,
    EXPIRED_JWT_TOKEN,
    INVALID_JWT_TOKEN,
    LOGIN,
    PARTICIPANTS,
    PROFILE_DELETE,
    READ,
    SEARCH
} from "./constants";
import {notify} from "../redux/notifySlice/notifySlice";
import {showModal} from "../redux/modalSlice/modalSlice";
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from "dayjs";
import {disconnectUser, setRedirectRoute, update} from "../redux/authSlice/authSlice";

const {AUTH, NOTIFICATIONS, USER} = CURRENT_ENDPOINT;

/**
 * Multiple classes
 * @param {...string} classes All desired StyleSheet classes
 * @summary A helper for conveniently add multiple StyleSheet classes
 * @author Arnaud LITAABA
 */
export const multipleClasses = (...classes) => classes.join(" ");

/**
 * ToArray
 * @param value {object} The object to transform to array
 * @return {array} An  array of object with object key as id and object value as data
 * @author Arnaud LITAABA
 */
export const toArray = (value) => {
    const finalArray = [];
    for (const valueKey in value) {
        if (value.hasOwnProperty(valueKey)) {
            finalArray.push({
                id: valueKey,
                data: value[valueKey]
            })
        }
    }
    return finalArray;
}

/**
 * Get base64 image data
 * @param file {Blob} The blob image
 * @param successCallback {function}
 * @param failedCallback {function}
 * @return {string} A base64 image
 * @author Arnaud LITAABA
 */
export const getBase64 = (file, successCallback = null, failedCallback = null) => {
    const reader = new FileReader();
    if (file) {
        reader?.readAsDataURL(file);
        reader.onload = () => successCallback && successCallback(reader.result);
        reader.onerror = (error) => failedCallback && failedCallback(error);
    }
}

/**
 * RetrieveBlob from image url
 * @param url {string} The image url
 * @param callback {function} the callback to get the blob
 * @author Arnaud LITAABA
 */
export const retrieveBlob = (url, callback) => {
    const nameTab = url.split("/");
    const name = nameTab[nameTab.length - 1];
    const extTab = name.split(".");
    fetch(url).then(r => r.blob()).then(blob => {
        callback(new File([blob], name, {
            type: 'image/' + extTab[extTab.length - 1],
            lastModified: Date.now()
        }))
    })
}

/**
 * Validate
 * @param value {any} The value to validate
 * @param rules {object} The rules to  use for validation
 * @return {boolean} true if valid and false if not
 * @author Arnaud LITAABA
 */
export const validate = (value, rules) => {
    let isValid = true;
    if (rules.required) {
        isValid = value?.trim() !== '' && isValid;
    }
    if (rules.requiredSelect) {
        isValid = typeof value !== "undefined";
    }
    if (rules.minLength) {
        isValid = value?.length >= rules.minLength && isValid;
    }
    if (rules.min) {
        isValid = Number(value) >= Number(rules.min) && isValid;
    }

    if (rules.minDate) {
        isValid = date(value).isAfter(new Date().toString()) && isValid;
    }

    if (rules.max) {
        isValid = Number(value) <= Number(rules.max) && isValid;
    }
    if (rules.maxLength) {
        isValid = value?.length <= rules.maxLength && isValid;
    }
    if (rules.regex) {
        isValid = rules.regex.test(value) && isValid;
    }
    if (rules.isPhoneNumber) {
        isValid = Number(value) && isValid;
    }
    if (rules.match) {
        isValid = value === rules.match.value && isValid;
    }

    return isValid;
}

export const commonPasswordTask = (passwordsData, target, value, {
    mustMatchPassword,
    mustMatchConfirmation,
    enterAtLeast8Char
}) => {
    const validation = {
        ...passwordsData[target].validation,
        match: {
            value: target === "password" ? passwordsData["confirmPassword"].value : passwordsData["password"].value
        }
    };

    const valid = validate(value, validation);

    const newData = {
        ...passwordsData,
        [target]: {
            ...passwordsData[target],
            value: value,
            touched: true,
            ...validation,
            valid,
        }
    }
    if (target === "password") {
        newData.confirmPassword.valid = valid;
        newData.confirmPassword.errorMessage = mustMatchPassword;
        newData.password.errorMessage = value.length >= 6 ? mustMatchConfirmation : enterAtLeast8Char;
    }
    if (target === "confirmPassword") {
        newData.password.valid = valid;
    }

    return newData;
}

/**
 * Form is valid
 * @param data {object} The value to validate
 * @return {boolean} true if valid and false if not
 * @author Arnaud LITAABA
 */
export const formIsValid = (data) => {
    let arrayData = toArray(data);
    let isPaying = false;
    let isShuttleAvailable = false;
    let formIsValid = true;
    for (const el of arrayData) {
        formIsValid = formIsValid && el.data.valid;
        if (el.data.label === 'paying') {
            isPaying = el.data.value;
        }
        if (el.data.label === 'price' && !isPaying) {
            formIsValid = true;
        }
        if (el.data.label === 'shuttleAvailable') {
            isShuttleAvailable = el.data.value;
        }
        if (!formIsValid) {
            showNotification(el.data.errorMessage, DANGER)
            break;
        }
    }
    return formIsValid;
}

/**
 * Check and cache
 * @param callback {function} the function for caching data if we do not exceed quota
 * @param toClear {string} the target cache id to clear
 * @author Arnaud LITAABA
 */
export const checkAndCache = (callback, toClear) => {
    try {
        navigator.storage.estimate().then(_ => {
            try {
                callback();
            } catch (_) {
                localStorage.removeItem(toClear);
            }
        }).catch(_ => {
            try {
                callback();
            } catch (_) {
                localStorage.removeItem(toClear);
            }
        })
    } catch (_) {
        localStorage.removeItem(toClear);
    }
}

/**
 * Uuid
 * @param type {'string' | 'number'} the type of uuid
 * @default string
 * @summary A helper to generate UUID
 * @return {string} a UUID
 * @author Arnaud LITAABA
 */
export const uuid = (type = 'string') => {
    const id = {
        'string': () => {
            return 'aaaaaaaaaaaaaaa4aaaaabaaa'.replace(/[ab]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c === 'a' ? r : (r & (0x3 | 0x8));
                return v.toString(16);
            });
        },
        'number': () => {
            return 'aa4ab'.replace(/[ab]/g, (c) => {
                let r = Math.random() * 8 | 0;
                return c === 'x' ? r : (r & (0x3 | 0x8));
            });
        }
    }
    return id[type]()

}

/**
 * Make index
 * @param {...Any, String, number} values All values for making unique index
 * @summary A helper for conveniently add unique key to component
 * @author Arnaud LITAABA
 */
export const makeIndex = (...values) => values.join("-|-");

/**
 * GlobalDispatcher
 * @summary A global action dispatcher when using redux
 * @param dispatcher {function | any} The dispatcher
 * @param data {any} The data to dispatch
 * @author Arnaud LITAABA
 */
export const globalDispatcher = (dispatcher, data) => {
    store.dispatch(dispatcher(data))
}

/**
 * Show notification
 * @summary A global notification dispatcher
 * @param content {string} The notification content
 * @param type {string} The notification type
 * @param duration {number} The notification duration
 * @author Arnaud LITAABA
 */
export const showNotification = (content, type, duration = 2000) => {
    globalDispatcher(notify, {
        id: uuid(),
        content,
        duration,
        type,
    })
}

/**
 * Format Data
 * @summary format islands data
 * @param data {array | Object} Originals islands data content
 * @param userLanguage {string} User language
 * @return {array | Object} The formated data
 * @author Arnaud LITAABA
 */
export const formatData = (data, userLanguage = DEFAULT_LANGUAGE) => {
    return data.map(c => ({
        ...c,
        render: <span>{c.translations[userLanguage] ? c.translations[userLanguage].name : c.translations.fr.name}</span>
    }))
}

/**
 * Set select Data
 * @param data
 * @param userLanguage
 * @param matched {'id' | '@id'}
 * @param value
 * @return {{}|{name, id}}
 * @author Arnaud LITAABA
 */
export const setSelectData = (data, userLanguage = DEFAULT_LANGUAGE, value, matched = "id") => {
    if (!value) {
        return {}
    }
    const el = data.find(a => a[matched].toString() === value);
    if (!el) {
        return {}
    }
    return {
        id: el.id,
        name: el.translations[userLanguage] ? el.translations[userLanguage].name : el.translations.fr.name
    }
}

/**
 * Reset a form
 * @param formData
 * @return {*}
 * @author Arnaud LITAABA
 */
export const resetAForm = (formData) => {
    const data = {...formData};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            data[key] = {
                ...data[key],
                value: data[key].defaultValue,
                ...typeof data[key].valid !== 'undefined' ? {valid: false} : {},
                touched: false
            }
        }
    }
    return data
}

/**
 * Extract id from string
 * @param data {string}
 * @return {string}
 */
export const extractId = (data) => {
    // In ios 14 and below, the new [].at() is not available so
    const tab = data.split("/");
    return tab[tab.length - 1];
}

/**
 * AxiosInstance
 * A simple axios instance with basic config like
 * Base url, timeout etc...
 * @author Arnaud LITAABA
 */
export const AxiosInstance = Axios.create({
    baseURL: CURRENT_API_BASE_URL,
    timeout: 60000,
});

/**
 * Decode Token
 * This function allows us to decode loggedInUser token
 * @param token {string}
 * @return {object} A decode token axios instance with the token attached or deleted
 * @author Arnaud LITAABA
 */
export const tokenTools = (token) => {

    if (!!!token.split('.')[1]) {
        return {
            isExp: true
        }
    }

    const decodedToken = JSON.parse(
        atob(token.split('.')[1].replace('-', '+').replace('_', '/'))
    )
    return {
        isExp: (Date.now() / 1000) > decodedToken.exp
    }
}

/**
 * Is Loggged in
 * This function allows us to know if user is logged In
 * @return {object} false if not ok and true if ok
 * @author Arnaud LITAABA
 */
export const isLoggedIn = (callback) => {
    const token = getToken();
    if (token) {
        if (tokenTools(token).isExp) {
            refreshToken({response: {data: {code: 401, message: EXPIRED_JWT_TOKEN}}}, (_) => {
                callback(false)
            }, () => {
                callback(true)
            })
        } else {
            callback(true)
        }
        return
    }
    callback(false)
}

/**
 * Check login
 * @param next {function} The next action if logged In
 * @author Arnaud LITAABA
 */
export const checkLogin = (next) => {
    isLoggedIn((state => {
        if (state) {
            next && next()
            return
        }
        globalDispatcher(showModal, {
            type: LOGIN, wrapperStyles: {
                ...window.innerWidth <= 968 ? {
                    height: "350px"
                } : {
                    minHeight: "35%",
                    height: "350px"
                }
            }
        });
        globalDispatcher(setRedirectRoute, window.location.pathname);
    }))
}

/**
 * Can use pop url
 * @param comFrom {string} The pop url
 * @author Arnaud LITAABA
 */
export const canUsePopUrl = (comFrom) => {
    const {LOGIN, REGISTER, REGISTER_OPTIONS, LOGIN_OPTIONS} = APP_ROUTE;
    return ![LOGIN, LOGIN_OPTIONS, REGISTER, REGISTER_OPTIONS].includes(comFrom)
}

/**
 * Delete profile
 * @author Arnaud LITAABA
 */
export const deleteProfileAction = () => {
    globalDispatcher(showModal, {
        type: PROFILE_DELETE,
        wrapperStyles: {
            height: "80%",
            top: "60%",
            width: "100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    });
}
/**
 * Show Search
 * @author Arnaud LITAABA
 */
export const showSearchPop = () => {
    globalDispatcher(showModal, {
        type: SEARCH,
        from: SEARCH
    });
}

/**
 * Use query
 * Extract query param from URI
 * @param uri {string}
 * @author Arnaud LITAABA
 */
export const useQuery = (uri) => {
    return new Proxy(new URLSearchParams(uri), {
        get: (searchParams, props) => searchParams.get(props),
    })
}

/**
 * User participate  ?
 * @param activity {any | object} The activity
 * @param authId {any} The auth id
 * @author Arnaud LITAABA
 */
export const isIn = (activity, authId) => {
    return !!activity.participants.find(p => p.user["@id"] === authId)
}

/**
 * Get DateTime in different format with useful tools
 * @param date {Date | any}
 * @return {object}
 * @author Arnaud LITAABA
 */
export const date = (date = new Date()) => {
    dayjs.locale('fr');
    dayjs.extend(relativeTime);
    let dateFromDayjs = dayjs(date);
    const result = {
        format: (target = "dateTime") => {
            const supportedFormat = {
                dateTime: "DD MMM YYYY [à] HH[h]mm",
                fullDateTime: "dddd DD MMMM YYYY [à] HH[h]mm",
                dt: "DD MMMM YYYY HH[h]mm",
                fullDate: "DD MMMM YYYY",
                time: "HH[h]mm",
                date: "DD MMM YYYY",
                shortDate: "MMM YYYY",
                picker: "YYYY-MM-DD",
                forcedDate: "DD/MM/YYYY",
                pickerTime: "HH:mm",
                day: "DD",
                fullDay: "dddd",
                fullDayTime: "dddd [à] HH[h]mm",
                month: "MMM",
                fullMonth: "MMMM",
                year: "YYYY"
            };
            return target === AS_NUMBER ? dayjs().valueOf() : dateFromDayjs.format(supportedFormat[target]).replace(".", "");
        },
        add: (value, type) => {
            dateFromDayjs = dateFromDayjs.add(value, type);
            return result
        },
        subtract: (value, type) => {
            dateFromDayjs = dateFromDayjs.subtract(value, type);
            return result
        },
        toNow: () => dateFromDayjs.toNow(true),
        isSame: (value, type) => dayjs().isSame(value, type),
        isAfter: (value) => dateFromDayjs.isAfter(dayjs(value)),
        getYear: () => dateFromDayjs.year()
    }
    return result;
};

/**
 * Show participants
 * @param e {Event}
 * @param participants {array}
 * @param action
 * @param extra
 */
export const showParticipants = (e, participants, action = () => null, extra = {}) => {
    e && e.stopPropagation();
    checkLogin(() => {
        globalDispatcher(showModal, {
            type: PARTICIPANTS,
            wrapperStyles: {
                width: "40%"
            },
            choice: participants,
            action,
            extra
        });
    })
}

export const openInNewTab = (url) => {
    window.open(url, "_blank")
}

export const isSafariMacOrFirefox = () => {
    const safariAgent = navigator.userAgent.indexOf("Safari") > -1;

    const chrome = navigator.userAgent.indexOf("Chrome") > -1;

    const isIphone = navigator.userAgent.indexOf("iPhone") > -1;

    const firefox = navigator.userAgent.indexOf("Firefox") > -1;

    return (!chrome && safariAgent && !isIphone) || (firefox && !isIphone);
}

export const getToken = () => localStorage.getItem("jwtToken");

/**
 * SetAuthToken
 * This function allows us to put loggedInUser token
 * in the header of each request made to the server.
 * It also delete the token from the header when not available.
 * Need to be call for each request for security reasons
 * @param AxiosInstance {Object} Any instance of axios
 * @param noToken {boolean} Set token or not
 * @param callback
 * @return {object} A modified axios instance with the token attached or deleted
 * @author Arnaud LITAABA
 */
const setAuthToken = (AxiosInstance, noToken = false, callback) => {
    let token = getToken();
    if (noToken) {
        delete AxiosInstance.defaults.headers.common["Authorization"];
        callback(AxiosInstance)
        return;
    }
    if (token) {
        if (!tokenTools(token).isExp) {
            AxiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
            callback(AxiosInstance)
            return;
        }
        refreshToken({response: {data: {code: 401, message: EXPIRED_JWT_TOKEN}}}, (_) => {
            delete AxiosInstance.defaults.headers.common["Authorization"];
            callback(AxiosInstance)
        }, () => {
            token = getToken();
            AxiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
            callback(AxiosInstance)
        })
        return;
    }
    delete AxiosInstance.defaults.headers.common["Authorization"];
    callback(AxiosInstance)
}

/**
 * Api
 * The request tool used along the whole app to
 * get, post, put and delete data from the server
 * @return {object} A request tool binded
 * @author Arnaud LITAABA
 */
export const API = {
    /**
     * Api Get
     * The request tool to get data from the server
     * @param url {string} The url for fetching data
     * @param noToken {boolean} Set token or not
     * @return {Promise} A promise
     * @author Arnaud LITAABA
     */
    get: (url, noToken = false) => {
        return new Promise((resolve, reject) => {
            const request = () => {
                setAuthToken(AxiosInstance, noToken, newInstance => {
                    newInstance.get(url).then(response => {
                        resolve(response);
                    }).catch(error => {
                        refreshToken(error, reject, request)
                    })
                })
            }
            request();
        });
    },
    /**
     * Api Patch
     * The request tool to patch or update data to the server
     * @param url {string} The url for patching data
     * @param data {object} The data to patch
     * @param headersOptions {object} Additional headers options
     * @return {Promise} A promise
     * @author Arnaud LITAABA
     */
    patch: (url, data, headersOptions = {}) => {
        return new Promise((resolve, reject) => {
            const request = () => {
                setAuthToken(AxiosInstance, false, newInstance => {
                    newInstance.patch(url, data, {headers: headersOptions}).then(response => {
                        resolve(response);
                    }).catch(error => {
                        refreshToken(error, reject, request)
                    })
                })
            }
            request();
        });
    },
    /**
     * Api Post
     * The request tool to post data to the server
     * @param url {string} The url for posting data
     * @param noToken {boolean} Set token or not
     * @param headersOptions {object} Additional headers options
     * @param data {object} The data to post
     * @return {Promise} A promise
     * @author Arnaud LITAABA
     */
    post: (url, data, noToken = false, headersOptions = {}) => {
        return new Promise((resolve, reject) => {
            const request = () => {
                setAuthToken(AxiosInstance, noToken, newInstance => {
                    newInstance.post(url, data, {headers: headersOptions}).then(response => {
                        resolve(response);
                    }).catch(error => {
                        refreshToken(error, reject, request)
                    })
                })
            }
            request();
        });
    },
    /**
     * Api Put
     * The request tool to put data to the server
     * @param url {string} The url for posting data
     * @param noToken {boolean} Set token or not
     * @param headersOptions {object} Additional headers options
     * @param data {object} The data to post
     * @return {Promise} A promise
     * @author Arnaud LITAABA
     */
    put: (url, data, noToken = false, headersOptions = {}) => {
        return new Promise((resolve, reject) => {
            const request = () => {
                setAuthToken(AxiosInstance, noToken, newInstance => {
                    newInstance.put(url, data, {headers: headersOptions}).then(response => {
                        resolve(response);
                    }).catch(error => {
                        refreshToken(error, reject, request)
                    })
                })
            }
            request();
        });
    },
    /**
     * Api Delete
     * The request tool to delete data on the server
     * @param url {string} The url for deleting data
     * @return {Promise} A promise
     * @author Arnaud LITAABA
     */
    delete: (url) => {
        return new Promise((resolve, reject) => {
            const request = () => {
                setAuthToken(AxiosInstance, false, newInstance => {
                    newInstance.delete(url).then(response => {
                        resolve(response);
                    }).catch(error => {
                        refreshToken(error, reject, request)
                    })
                })
            }
            request();
        });
    }
};

/**
 * Refresh token
 * This function allows us to refresh token
 * @param error {any}
 * @param reject {function}
 * @param callback {function}
 * @author Arnaud LITAABA
 */
const refreshToken = (error, reject, callback) => {
    const {response: {data: {code, message}}} = error;
    if (code === 401 && [EXPIRED_JWT_TOKEN, INVALID_JWT_TOKEN].includes(message)) {
        const refreshToken = localStorage.getItem('jwtRefreshToken');
        if (refreshToken) {
            API.post(AUTH.REFRESH_TOKEN, {refresh_token: refreshToken}, true).then(response => {
                if (response.status === 200) {
                    localStorage.setItem("jwtToken", response.data.token)
                    localStorage.setItem("jwtRefreshToken", response.data.refresh_token);
                    callback()
                }
            }).catch(_ => {
                globalDispatcher(disconnectUser, null)
                reject && reject(error);
            })
            return
        }
        reject && reject(error);
        return
    }
    reject && reject(error);

}

/**
 * Get current user notifications
 */
export const getNotifications = (readNotificationsAt = "2021-01-01T00:00:00.000Z") => {
    API.get(NOTIFICATIONS.BASE).then(response => {
        globalDispatcher(update, {
            totalNotifications: response.data["hydra:member"].filter(n => {
                return date(n.createdAt).isAfter(readNotificationsAt)
            }).length,
            notificationsData: [...response.data["hydra:member"]]
        });
    })
}

/**
 * Clear not readed user notifications
 */
export const clearNotifications = (callback, target = CLEAR) => {
    API.get(NOTIFICATIONS[target]).then(_ => {
        if (target === READ) {
            globalDispatcher(update, {
                totalNotifications: 0,
                readNotificationsAt: new Date().toString(),
            });
            return
        }
        globalDispatcher(update, {
            totalNotifications: 0
        });
        callback && callback()
    })
}

/**
 * Delete user account
 */
export const deleteUserAccount = (id, callback) => {
    API.get(USER.DELETE.replace(":id", id.toString())).then(_ => {
        callback && callback()
    })
}

/**
 * Encode and decode data with maximum compatibility
 * @author Arnaud LITAABA
 */
export const SAFE = {
    encode: (string) => {
        if (typeof window !== 'undefined') {
            return window.btoa(string);
        }
        return Buffer.from(string).toString('base64');
    },
    decode: (string) => {
        if (typeof window !== 'undefined') {
            return window.atob(string);
        }
        return Buffer.from(string, 'base64').toString();
    },
    brakeLine: (line) => line.replace(/(\r\n|\r|\n)/g, "<br>"),
    unBrakeLine: (line) => line.replace(/(<br>)/g, "\n")
}

/**
 * Scroll function
 * @author Arnaud LITAABA
 */
export const scroll = (target = 'chat', callback) => {
    const actions = {
        'chat': () => {
            if (window.innerWidth <= 968) {
                window.scroll({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                })
                callback(false)
            } else {
                callback(true);
            }
        }
    }

    actions[target]();
}

export const isMobile = (breakpoint) => {
    return breakpoint === "mobile";
}
