import {useEffect, useState} from "react";
import {CURRENT_ENDPOINT} from "../util/constants";
import {API} from "../util";

const {ACTIVITY_IMAGES: ACTIVITY_IMAGES_ENDPOINT} = CURRENT_ENDPOINT;

export const useActivityImage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            try {
                const response = await API.get(ACTIVITY_IMAGES_ENDPOINT.BASE, true);
                setImages(response.data["hydra:member"]);
                setIsLoading(false);
            } catch (e) {
                setError(e.message);
                setIsLoading(false)
            }
        }
        fetch();
    }, [])

    return {
        isLoading,
        images,
        error
    }
}
