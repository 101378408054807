import iconClasses from "./icon.module.scss";
import {multipleClasses} from "../../util";
import {DEFAULT_ICON_SIZE} from "../../util/constants";
import {ReactSVG} from "react-svg";

const {iconWrapper, sizeToWrapperClass} = iconClasses;

/**
 * Icon component
 * Contains icon and tooltip with icon label
 * @param src {any} The svg source
 * @param size {number} the size of icon
 * @param height {number | undefined} the height of icon
 * @param width {number | undefined} the width of icon
 * @param color {string} the color of icon
 * @param keepColor {boolean} to keep original svg color or not
 * @param svgClassName {array} an array of desired classname for the icon
 * @param globalWrapperClassName {string} the global icon wrapper classname
 * @param wrapperClassName {string} the svg wrapper classname
 * @param wrapperElement {"span" | "svg"} the wrapper element type 'span' | 'svg'
 * @param onClick {function} on click function to handle click action
 * @param sizeToWrapper {boolean} Set the same size to the wrapper
 * @param imgTag {boolean} set svg in img tag
 * @param alt {string} set img alt
 * @param noMarginBottom {boolean} set if margin bottom on logo
 * @param noStroke {boolean}
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Icon = ({
                  src,
                  size = DEFAULT_ICON_SIZE,
                  height ,
                  width ,
                  color = "#242424",
                  keepColor = false,
                  svgClassName = ["_"],
                  wrapperClassName = "",
                  globalWrapperClassName = "",
                  wrapperElement = "span",
                  onClick = () => void 0,
                  sizeToWrapper = false,
                  imgTag = false,
                  alt = "icon",
                  noMarginBottom = false,
    noStroke = false
              }) => {

    const getSpanStyle = () => {
        const defaultStyle = {"--size": `${size}px`};

        if (noMarginBottom) {
            return {
                ...defaultStyle,
                marginBottom: 0
            }
        }

        return defaultStyle;
    }

    return <span data-keep-color={keepColor} style={getSpanStyle()}
                 className={multipleClasses(iconWrapper, globalWrapperClassName, sizeToWrapper ? sizeToWrapperClass : "_")}>
        {imgTag ? <img onClick={onClick} className={wrapperClassName} style={{
            height: `${height ?? size}px`,
            width: `${width ?? size}px`
        }} src={src} alt={alt}/> : <ReactSVG
            beforeInjection={(svg) => {
                svgClassName && svg.classList.add(svgClassName.join(" "));
                let styles = `height: ${height ?? size}px; width: ${width ?? size}px;`;
                styles = keepColor ? styles : styles + ` fill: ${color} !important; stroke: ${color}; --color: ${color};`;
                styles = !noStroke ? styles : styles + ` stroke-width: 0;`;
                svg.setAttribute('style', styles)
            }}
            src={src}
            className={wrapperClassName}
            wrapper={wrapperElement}
            onClick={onClick}
        />}
        </span>

}

export default Icon
