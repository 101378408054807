import shareViewClasses from "../share/shareView.module.scss";
import {openInNewTab, showNotification} from "../../util";
import FacebookIcon from "../../assets/icons/facebook.svg"
import TwitterIcon from "../../assets/icons/twitter.svg"
import LinkedInIcon from "../../assets/icons/linkedin.svg"
import EmailIcon from "../../assets/icons/email.svg"
import Icon from "../../components/icon/Icon";
import stylesVariables from "../../assets/styles/_variables.scss";
import {useSelector} from "react-redux";
import {createRef, useCallback} from "react";
import {SUCCESS} from "../../util/constants";

const {
    wrapper,
  //  title,
    subTitle,
    content, contentEl, contentElFull, link, copy, icon
} = shareViewClasses;

const ICON_SIZE = 30;
const ICON_COLOR = stylesVariables.siteColor4;

/**
 * Share view component
 * @param choice
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const ShareView = ({choice}) => {

    const userLanguage = useSelector((state) => state.language.default);

    const {
        shareActivity, copyLink: copyLinkText, successCopyLink
    } = useSelector((state) => state.language[state.language.default]);

    const titleValue = choice.translations[userLanguage] ? choice.translations[userLanguage].title : choice.translations.fr.title;

    const mailRef = createRef();

    const copyLink = useCallback(() => {

        const forceCopy = () => {
            const listener = (ev) => {
                ev.preventDefault();
                ev.clipboardData.setData('text/plain', window.location.href);
            };
            document.addEventListener('copy', listener);
            document.execCommand('copy');
            showNotification(successCopyLink, SUCCESS);
            document.removeEventListener('copy', listener);
        }

        if (navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href).then(_ => {
                showNotification(successCopyLink, SUCCESS)
            }).catch(forceCopy);
            return
        }
        forceCopy()

    }, [successCopyLink])

    return <div className={wrapper}>
        {/*<div className={title}>{share}</div>*/}
        <div className={subTitle}>
            {shareActivity}
        </div>
        <div className={content}>
            <div
                onClick={() => openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`)}
                className={contentEl}>
                <span>Facebook</span>
                <Icon globalWrapperClassName={icon} src={FacebookIcon} size={ICON_SIZE} color={ICON_COLOR}/>
            </div>
            <div
                onClick={() => openInNewTab(`https://twitter.com/intent/tweet?text=${titleValue}&url=${encodeURIComponent(window.location.href)}`)}
                className={contentEl}>
                <span>Twitter</span>
                <Icon globalWrapperClassName={icon} src={TwitterIcon} size={ICON_SIZE} color={ICON_COLOR}/>
            </div>
            <div
                onClick={() => openInNewTab(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`)}
                className={contentEl}>
                <span>LinkedIn</span>
                <Icon globalWrapperClassName={icon} src={LinkedInIcon} size={ICON_SIZE} color={ICON_COLOR}/>
            </div>

            <div onClick={() => mailRef.current && mailRef.current.click()} className={contentEl}>
                <span>Email</span>
                <Icon globalWrapperClassName={icon} src={EmailIcon} size={ICON_SIZE} color={ICON_COLOR}/>
                <a style={{
                    display: 'none'
                }} ref={mailRef} href={`mailto:?body=${titleValue + " " + window.location.href}`} target="_blank"
                   rel="noopener noreferrer">Email</a>
            </div>
        </div>
        <div className={contentElFull}>
            <div className={link}>{window.location.href}</div>
            <div onClick={copyLink} className={copy}>{copyLinkText}</div>
        </div>
    </div>
}

export default ShareView