import loadingClasses from "./fallbackLoading.module.scss";
import ActivityIndicator from "../../../components/activityIndicator/ActivityIndicator";

const {fallback, fallback_A_Wrapper, nestedFallback} = loadingClasses;

/**
 * Fallback loading component
 * A fallbackLoading loading component to show when loading page or app
 * @return JSX.Element
 * @author Arnaud LITAABA
 */
const FallBackLoading = () => {
    return <div className={fallback}>
        <div className={fallback_A_Wrapper}>
            <ActivityIndicator/>
        </div>
    </div>
}

/**
 * Nested fallback loading component
 * A fallbackLoading loading component to show when loading nested content
 * @param dTop {number}
 * @return JSX.Element
 * @author Arnaud LITAABA
 */
export const NestedFallbackLoading = ({dTop}) => {
    return <div className={nestedFallback}>
        <div style={{
            marginTop: `${dTop}rem`
        }} className={fallback_A_Wrapper}>
            <ActivityIndicator/>
        </div>
    </div>
}

export default FallBackLoading