import registerToActivityPopUpClasses from "./registerToActivityPopUp.module.scss";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";
import stylesVariables from "../../assets/styles/_variables.scss";
import AgendaLogo from "../../assets/otherPic/agenda_2.svg";
import React, {useCallback, useEffect, useState} from "react";
import {
    APP_ROUTE,
    CANCEL,
    CURRENT_ENDPOINT,
    DANGER,
    FAILED,
    PROCESSING,
    SUCCESS,
    TOGGLE_ACTIVITY_STATE,
    WARNING
} from "../../util/constants";
import {
    API,
    formatData,
    makeIndex,
    multipleClasses,
    showNotification,
    toArray
} from "../../util";
import {closeModal} from "../../redux/modalSlice/modalSlice";
import {useNavigate} from "react-router-dom";
import GuideIcon from "../../assets/icons/guide.svg";
import TransportIcon from "../../assets/icons/transport.svg";
import Input from "../../components/input/Input";

const {ACTIVITIES} = CURRENT_ENDPOINT;

const {
    wrapper,
    header,
    headerLogo,
    title,
    subTitle,
    validateButton,
    inputs,
    customLabel,
    input,
    inputSection,
    switchWrapper,
    label,
    guideInput
} = registerToActivityPopUpClasses;

/**
 * Register to activity Popup component
 * @param choice {any}
 * @param oneDone {function} The action on done
 * @param mode
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const RegisterToActivityPopUp = ({choice: activity, oneDone, mode}) => {

    const {
        inscription,
        confirm,
        confirmInscription,
        confirmCancelInscription,
        bringFriend,
        inscriptionInProgress,
        successInscription,
        successCancelInscription,
        successDisable,
        successDelete,
        serverError,
        confirmDelete, confirmDisable,
        requireShuttle,
        shuttleLocalisationText
    } = useSelector((state) => state.language[state.language.default]);

    const authId = useSelector((state) => state.auth.data["@id"]);

    const p = activity.participants.find(p => p.user["@id"] === authId);

    const userLanguage = useSelector((state) => state.language.default);

    const dispatch = useDispatch();

    const [workingState, setWorkingState] = useState("")
    const shuttlePlacesAvailable = () => {
        if (!activity.shuttleAvailable) {
            return 0;
        }

        let totalPlacesShuttleSeat = 0;
        activity.participants.forEach(p => {
            if (p.wantShuttle) {
                totalPlacesShuttleSeat++;
                if (p.guide) {
                    totalPlacesShuttleSeat++;
                }
            }
        })

        return activity.shuttleNumber - totalPlacesShuttleSeat;
    }

    const [data, setData] = useState(
        {
            withGuide: {
                label: "withGuide",
                name: bringFriend,
                placeholder: bringFriend,
                data: [],
                className: [guideInput],
                icon: {
                    src: GuideIcon,
                    color: stylesVariables.blackColor,
                    size: 20
                },
                type: "switch",
                value: false,
                valid: true,
                validation: {},
                show: true
            },
            wantShuttle: {
                label: "wantShuttle",
                name: requireShuttle,
                placeholder: requireShuttle,
                data: [],
                className: [],
                icon: {
                    src: TransportIcon,
                    color: stylesVariables.blackColor,
                    size: 20
                },
                type: "switch",
                value: false,
                valid: true,
                validation: {},
                show: shuttlePlacesAvailable() > 0
            },
            shuttleLocalisation: {
                label: "shuttleLocalisation",
                noLabel: true,
                name: "",
                iconOnLabel: true,
                data: [],
                className: [],
                placeholder: shuttleLocalisationText,
                type: "text",
                touched: false,
                value: "",
                valid: false,
                validation: {
                    required: true,
                    min: 1
                },
                errorMessage: shuttleLocalisationText,
                show: true
            },
        }
    )

    const navigate = useNavigate();

    useEffect(() => {
        if (shuttlePlacesAvailable() === 0 || (data.withGuide.value && shuttlePlacesAvailable() < 2)) {
            setData(data => {
                return {
                    ...data,
                    wantShuttle: {
                        ...data["wantShuttle"],
                        value: false,
                        valid: true,
                        touched: true,
                        show: false
                    },
                    shuttleLocalisation: {
                        ...data["shuttleLocalisation"],
                        touched: true,
                        valid: true,
                        show: false,
                        value: ""
                    }
                }
            });
        } else {
            setData(data => {
                return {
                    ...data,
                    wantShuttle: {
                        ...data["wantShuttle"],
                        valid: true,
                        touched: true,
                        show: true
                    },
                    shuttleLocalisation: {
                        ...data["shuttleLocalisation"],
                        valid: true,
                        touched: true,
                        show: data.wantShuttle.value,
                    }
                }
            });
        }

    }, [data.withGuide.value, data.wantShuttle.value])

    const next = useCallback((mode) => {

        const nextAction = (data,takenPlace) => {
            setWorkingState(SUCCESS);
            dispatch(closeModal());
            oneDone && takenPlace ? oneDone(data,takenPlace) : oneDone(data)
        }

        if (workingState !== PROCESSING) {
            setWorkingState(PROCESSING);
            if (mode === CANCEL) {
                API.delete(ACTIVITIES.CANCEL_INSCRIPTION.replace(":id", p.id)).then(_ => {
                    showNotification(successCancelInscription, SUCCESS, 4000);
                    nextAction(p,p.guide ? 2 : 1)
                }).catch(_ => {
                    setWorkingState(FAILED)
                    showNotification(serverError, DANGER);
                })
                return;
            }

            if (mode === TOGGLE_ACTIVITY_STATE) {
                if (activity.enabled) {
                    API.put(ACTIVITIES.DISABLE.replace(":id", activity.id), {enabled: false},
                        false, {"Content-Type": "application/ld+json"}).then(_ => {
                        showNotification(successDisable, SUCCESS, 4000);
                        nextAction(!activity.enabled)
                    }).catch(_ => {
                        setWorkingState(FAILED)
                        showNotification(serverError, DANGER);
                    })
                    return;
                }
                API.put(ACTIVITIES.DELETE.replace(":id", activity.id), {visible: false},
                    false, {"Content-Type": "application/ld+json"}).then(_ => {
                    showNotification(successDelete, SUCCESS, 4000);
                    navigate(APP_ROUTE.AGENDA)
                }).catch(_ => {
                    setWorkingState(FAILED)
                    showNotification(serverError, DANGER);
                })
                return;
            }
            if (data.wantShuttle.value && data.shuttleLocalisation.value.length === 0) {
                showNotification(data.shuttleLocalisation.errorMessage, DANGER)
                setWorkingState(FAILED)
                return;
            }

            API.post(ACTIVITIES.INSCRIPTION, {
                activity: activity["@id"],
                guide: data.withGuide.value,
                wantShuttle: data.wantShuttle.value,
                shuttleLocalisation: data.wantShuttle.value ? data.shuttleLocalisation.value : null
            }, false, {"Content-Type": "application/ld+json"}).then(response => {
                showNotification(successInscription, SUCCESS, 4000);
                nextAction(response.data,data.withGuide.value ? 2 : 1)
            }).catch(_ => {
                setWorkingState(FAILED)
                showNotification(serverError, DANGER);
            })
            return
        }
        ![CANCEL, TOGGLE_ACTIVITY_STATE].includes(mode) && showNotification(inscriptionInProgress, WARNING);
    }, [p, serverError, navigate, successDelete, successDisable, successCancelInscription, inscriptionInProgress, successInscription, activity, workingState, dispatch, oneDone, data.withGuide.value, data.wantShuttle.value, data.shuttleLocalisation.value])

    const handleChange = (value, target) => {
        setData(data => {
            return {
                ...data,
                [target]: {
                    ...data[target],
                    value: value,
                    touched: true,
                    valid: true
                },
            }
        });
    }

    const commonInput = (id, inputData) => <Input
        className={[input]}
        switchClassName={[customLabel]}
        icon={inputData.icon}
        selectOptions={{
            data: formatData(inputData.data, userLanguage),
            type: "object"
        }}
        autoComplete="new-password"
        value={inputData.value}
        {...inputData.type === "switch" ? {
            onSwitch: (value) => handleChange(value, id)
        } : {}}
        error={{
            show: inputData.touched && !inputData.valid,
            message: inputData.errorMessage
        }}
        placeholder={inputData.placeholder}
        onChange={({target}) => handleChange(target.value, id)}
        type={inputData.type}
    />

    const getAdditionalInput = (id, inputData) => {
        if (id === "shuttleLocalisation") {
            return data.wantShuttle.value ? commonInput(id, inputData) : null;
        }

        return commonInput(id, inputData);
    }

    const commonContent = (mobile = true) => {
        return toArray(data).map(({id, data: inputData}, index) => {
            if (inputData.show) {
                return (
                        <div
                             key={makeIndex(id, index)}
                             className={multipleClasses(inputSection, switchWrapper, ...inputData.className)}>
                            {(inputData.type !== "switch" && !inputData.noLabel) && <div className={title}>
                                <Icon src={inputData.icon.src} size={inputData.icon.size}
                                      color={stylesVariables.siteBlack}/>
                                <span className={label}>{inputData.name}</span>
                            </div>}
                            {
                                getAdditionalInput(id, inputData)
                            }
                        </div>
                    )
            }
        })
    }

    const getInputs = () => {
        if (![CANCEL ,TOGGLE_ACTIVITY_STATE].includes(mode)) {
            return (
                <div className={inputs}>
                    {commonContent()}
                </div>
            )
        }

        return null;
    }

    return <div className={wrapper}>
        <div className={header}>{inscription}</div>
        <Icon keepColor wrapperClassName={headerLogo} color={stylesVariables.siteColor3} src={AgendaLogo} size={180}/>
        <div className={title}>
            {activity.translations[userLanguage] ? activity.translations[userLanguage].title : activity.translations.fr.title}
        </div>
        <div className={subTitle}>
            {mode === CANCEL ? confirmCancelInscription : mode === TOGGLE_ACTIVITY_STATE ? activity.enabled ? confirmDisable : confirmDelete : confirmInscription}
        </div>
        {getInputs()}
        <Button indicator={workingState === PROCESSING ? {
            color: stylesVariables.neutralColor
        } : false} onClick={() => next(mode)} className={[validateButton]} title={confirm}
                label={confirm}/>
    </div>
}

export default RegisterToActivityPopUp
