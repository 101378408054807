import './App.scss';
import {lazy, Suspense, useEffect} from "react";
import FallBackLoading from "./views/loading/fallbackLoading/FallBackLoading";
import CustomNotifications from "./components/customNotifications/CustomNotifications";
import {API, getNotifications, getToken, globalDispatcher} from "./util";
import {setActivitiesTypeData, setIslandsData, setTowns} from "./redux/globalSlice/globalSlice";
import {CURRENT_ENDPOINT} from "./util/constants";
import Modal from "./components/modal/Modal";
import {update} from "./redux/authSlice/authSlice";

const {ACTIVITIES, ISLANDS, TOWNS, USER} = CURRENT_ENDPOINT;

const Router = lazy(() => import("./router/Router"))

/**
 * App component
 * Contains the router and all one time rendering elements
 * @return {JSX.Element} A router
 * @author Arnaud LITAABA
 */
const App = () => {
    useEffect(() => {

        const token = getToken()
        if (token) {
            API.get(USER.ME).then(response => {
                globalDispatcher(update, {...response.data});
                getNotifications(response.data.readNotificationsAt)
            });
        }

        API.get(ISLANDS.BASE, true).then(response => {

            globalDispatcher(setIslandsData, [
                ...response.data["hydra:member"].sort((a, b) => {
                    const translationA = a.translations.fr;
                    const translationB = b.translations.fr;
                    if (translationA.name < translationB.name) {
                        return -1;
                    }
                    if (translationA.name > translationB.name) {
                        return 1;
                    }

                    return 0;
                }),
            ]);
        })
        API.get(ACTIVITIES.TYPE, true).then(response => {
            globalDispatcher(setActivitiesTypeData, [
                ...response.data["hydra:member"].sort((a, b) => a.id - b.id),
            ]);
        })
        API.get(TOWNS.BASE, true).then(response => {
            globalDispatcher(setTowns, [
                ...response.data["hydra:member"].sort((a, b) => {
                    const translationA = a.translations.fr;
                    const translationB = b.translations.fr;
                    if (translationA.name < translationB.name) {
                        return -1;
                    }
                    if (translationA.name > translationB.name) {
                        return 1;
                    }

                    return 0;
                }),
            ]);
        })

    }, [])

    return <div className="globalContainer">
        <CustomNotifications/>
        <Modal/>
        <Suspense fallback={<FallBackLoading/>}>
            <Router/>
        </Suspense>
    </div>
}

export default App;
