import Button from "../../../components/button/Button";
import React from "react";
import activityImageClasses from "./activityImage.module.scss";
import {useSelector} from "react-redux";

const {
    chooseBanner,
    validateButton,
    containerHandleButton
} = activityImageClasses

export const ActivityImageButtons = ({bannerState, imgSelected, handleFileChange, triggerFile, fileRef, confirmImage}) => {
    const {
        validateText,
        importImageText
    } = useSelector((state) => state.language[state.language.default]);
    return (
        <>
            <div className={containerHandleButton}>
                <Button onClick={triggerFile} className={[chooseBanner]} label={importImageText}/>
                <input ref={fileRef} type="file" accept="image/*" hidden={true} onChange={handleFileChange}/>
            </div>
            <div>
                <Button
                    onClick={confirmImage}
                    className={[validateButton]}
                    label={validateText}
                    disabled={!bannerState.touched && !imgSelected.touched}
                />
            </div>
        </>
    )
}
