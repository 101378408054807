import registerPopUpClasses from "./registerPopUp.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/input/Input";
import { APP_ROUTE, CGU_LINK, WARNING } from "../../util/constants";
import Button from "../../components/button/Button";
import { multipleClasses, openInNewTab, showNotification } from "../../util";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { closeModal } from "../../redux/modalSlice/modalSlice";

const {
    wrapper,
    title,
    header,
    policy,
    bottom,
    section,
    input,
    label,
    details,
    link,
    // subTitle,
    disabled,
    validateButton
} = registerPopUpClasses;

/**
 * Register Popup component
 * @param choice {string}
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const RegisterPopUp = ({ choice }) => {

    const {
        validateConditions,
        validateRegistration,
        validateText,
        understandDev,
        acceptChartOnRegister, cgu
    } = useSelector((state) => state.language[state.language.default]);

    const [accepted, setAccepted] = useState({
        value: [],
        valid: false
    });

    const handleChanges = useCallback((id) => {
        setAccepted(accepted => {
            const changes = accepted.value.includes(id) ? accepted.value.filter(v => v !== id) : [...accepted.value, id];
            return {
                ...accepted,
                value: [...changes],
                valid: changes.length === 2
            }
        })
    }, [])

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const next = useCallback(() => {
        if (!accepted.valid) {
            showNotification(validateConditions, WARNING);
            return
        }
        dispatch(closeModal());
        navigate(APP_ROUTE.REGISTER);
    }, [accepted.valid, navigate, validateConditions, dispatch])

    return <div className={wrapper}>
        <div className={header}>{validateRegistration}</div>
        <div className={title}>{validateConditions}</div>
        <div className={policy}>
            <div className={section}>
                <Input
                    className={[input]}
                    value={""}
                    checked={accepted.value.includes(1)}
                    onChange={() => handleChanges(1)}
                    type={"checkbox"}
                />
                <div className={label}>
                    <div className={details}>{understandDev}</div>
                    {/* <div className={link}>Lien lorem</div>*/}
                </div>
            </div>
            <div className={section}>
                <Input
                    className={[input]}
                    value={""}
                    onChange={() => handleChanges(2)}
                    type={"checkbox"}
                />
                <div className={label}>
                    <div className={details}>{acceptChartOnRegister}</div>
                    <div onClick={() => openInNewTab(CGU_LINK)}
                        className={link}>{cgu}</div>
                </div>
            </div>
        </div>
        {/*<div className={subTitle}>
            Lorem ipsum dolor sit amet, consectetur.
        </div>*/}
        {/*<div className={multipleClasses(policy, bottom)}>
            <div className={section}>
                <Input
                    className={[input]}
                    value={""}
                    onChange={() => handleChanges(3)}
                    type={"checkbox"}
                />
                <div className={label}>
                    <div className={details}>{understandMinistery}
                    </div>
                </div>
            </div>
    </div>*/}
        <Button disabled={!accepted.valid} onClick={next} title={validateText}
            className={[validateButton, !accepted.valid ? disabled : "_"]}
            label={validateText} />
    </div>
}

export default RegisterPopUp