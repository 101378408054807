import React from "react";
import linkClasses from "./button.module.scss";
import {multipleClasses} from "../../util";
import Icon from "../icon/Icon";
import {DEFAULT_ICON_SIZE} from "../../util/constants";
import ActivityIndicator from "../activityIndicator/ActivityIndicator";
import stylesVariables from '../../assets/styles/_variables.scss';

const {
    button,
    disabledClassName,
    iconClassName,
    activityClassName,
} = linkClasses;

/**
 * Button component
 * Show link with or without label
 * @param label {string} A label for the button
 * @param icon {{src, size, color,keepColor}} the icon source and size
 * @param indicator {{size, borderSize, color}} the icon source and size
 * @param className {string[]} the icon source
 * @param children {JSX.Element | HTMLElement | string | any} the Button component children
 * @param disabled {boolean} disabled button
 * @param type {'button' | 'reset' | 'submit'}
 * @param styles {object} additional styles for the button element
 * @param rest {object} all others props
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Button = ({label, icon, indicator, children, className = [], disabled, type = 'button', styles, ...rest}) => {
    return <button type={type} className={multipleClasses(button, disabled ? disabledClassName : "_", ...className)}
                   style={{...styles}} {...rest}>
        {icon && <Icon color={icon.color} keepColor={icon.keepColor} wrapperClassName={iconClassName} src={icon.src}
                       size={icon.size || DEFAULT_ICON_SIZE}/>}
        <span>{label || children}</span>
        {indicator && <ActivityIndicator
            activityClassName={activityClassName}
            size={indicator.size || 20}
            borderSize={indicator.borderSize || 1}
            color={indicator.color || stylesVariables.siteColor2}/>}
    </button>
}

export default React.memo(Button)