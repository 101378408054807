import chatsSuggestionsClasses from "../../messages/messages.module.scss";
import {useSelector} from "react-redux";
import stylesVariables from "../../../assets/styles/_variables.scss";
import SearchIcon from "../../../assets/icons/search.svg"
import Icon from "../../../components/icon/Icon";
import ClassifiedData from "../../../components/classifedData/ClassifiedData";
import {NestedFallbackLoading} from "../../loading/fallbackLoading/FallBackLoading";
import {CURRENT_ENDPOINT, DANGER, DEFAULT_ICON_SIZE, SUCCESS} from "../../../util/constants";
import {API, globalDispatcher, makeIndex, showNotification} from "../../../util";
import {createRef, useCallback, useEffect, useState} from "react";
import AddUserIcon from "../../../assets/icons/addUser.svg";
import Button from "../../../components/button/Button";
import defaultAvatar from "../../../assets/avatar/defaultAvatar.png";
import RemoveIcon from "../../../assets/icons/close.svg";
import {closeModal} from "../../../redux/modalSlice/modalSlice";

const {
    friendsSuggestions,
    headerBlack,
    searchWrapper,
    input,
    memberUsername,
    defaultValueClass,
    defaultWrapper,
    friendsList,
    emptyClass,
    alreadyInvite,
    memberDetails,
    avatar, infos, notInvite, validateButton
} = chatsSuggestionsClasses;

/**
 * Friends suggestions component
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const FriendsSuggestions = ({invitations: data = [], onAction, manager = null, participants = null}) => {
    const [invitations, setInvitations] = useState({
        value: [...data],
        added: {}
    })
    const authId = useSelector((state) => state.auth.data["@id"]);

    useEffect(() => {
        let ad = {}
        data.forEach(u => {
            ad[u.id] = true
        })
        setInvitations(invitations => {
            return {
                ...invitations,
                added: {
                    ...ad
                }
            }
        })
    }, [data])

    const {
        inviteFriends,
        makeSearch,
        remove,
        add,
        suggestion,
        canNotRetrieveMessages,
        noResult,
        confirm,
        willBeInvite,
        noInvitationsWillBeSent,
        willBeInviteAlone, hideProfile
    } = useSelector((state) => state.language[state.language.default]);

    const [friendsState, setFriendsState] = useState({
        loading: true,
        data: []
    })

    useEffect(() => {
        API.get(CURRENT_ENDPOINT.FRIENDS.BASE).then(response => {
            setFriendsState(_ => {
                const responseData = response.data["hydra:member"];
                let friendsFiltered = responseData.filter(f => f.accepted);
                if (manager) {
                    friendsFiltered = friendsFiltered.filter(f => {
                        const userFrom = f.userFrom["@id"] === authId ? null : f.userFrom["@id"];
                        const userTo = f.userTo["@id"] === authId ? null : f.userTo["@id"];

                        return (manager.manager !== userFrom && manager.manager !== userTo) && !participants.includes(userFrom) && !participants.includes(userTo)
                    })
                }
                return {
                    data: friendsFiltered,
                    loading: false
                }
            })
        }).catch(_ => {
            setFriendsState(state => {
                return {
                    ...state,
                    loading: false
                }
            })
            showNotification(canNotRetrieveMessages, DANGER)
        })
    }, [canNotRetrieveMessages])

    const emptyRef = createRef();

    const search = (searchValue) => {
        let elements = document.getElementsByClassName(memberDetails);
        let found = 0;
        Array.from(elements).forEach(element => {
            let txtValue = element.innerHTML;
            if (txtValue.toLowerCase().indexOf(searchValue) > -1 || txtValue.indexOf(searchValue) > -1 || txtValue.toUpperCase().indexOf(searchValue) > -1) {
                element.style.display = "flex";
                found += 1;
            } else {
                element.style.display = "none";
            }
        });
        if (found === 0 && emptyRef.current) {
            emptyRef.current.style.display = "flex"
        } else {
            emptyRef.current.style.display = "none"
        }
    }

    const handleInvitation = useCallback((user) => {
        setInvitations(invitations => {
            let inv = [...invitations.value];
            let ad = {...invitations.added}
            if (ad[user.id]) {
                inv = [...inv.filter(u => u.id !== user.id)];
                delete ad[user.id]
            } else {
                inv.push({
                    ...user
                })
                ad[user.id] = true
            }
            return {
                ...invitations,
                value: [...inv],
                added: {
                    ...ad
                }
            }
        })

    }, [])

    const confirmInvitationsTab = useCallback(() => {
        onAction && onAction(invitations.value);
        if (invitations.value.length > 0) {
            showNotification(`${invitations.value.map(u => u.username).join(", ")} ${invitations.value.length === 1 ? willBeInviteAlone : willBeInvite}`, SUCCESS, 3000)
        } else {
            showNotification(noInvitationsWillBeSent, SUCCESS)
        }

        globalDispatcher(closeModal, null);

    }, [invitations, onAction, willBeInvite, willBeInviteAlone, noInvitationsWillBeSent])

    const auth = useSelector((state) => state.auth.data);

    const target = (f) => f.userTo["@id"] === auth["@id"] ? "userFrom" : "userTo";

    return <div className={friendsSuggestions}>
        <div className={headerBlack}>{inviteFriends}</div>
        <div className={searchWrapper}>
            <input onChange={({target}) => search(target.value)} className={input} type="text"
                   placeholder={makeSearch}/>
            <Icon src={SearchIcon} color={stylesVariables.siteColor3}/>
        </div>
        <ClassifiedData wrapperClasses={[defaultWrapper]} valueClasses={[defaultValueClass]}
                        title={suggestion}/>
        <div className={friendsList}>
            {
                friendsState.loading ? <NestedFallbackLoading/> :
                    friendsState.data.map(f => {
                        return <div key={makeIndex("chats-suggestions-friends", f.id)} className={memberDetails}>
                            <img className={avatar}
                                 src={f[target(f)].profilVisibleActivities ? f[target(f)].filePath ?? defaultAvatar : defaultAvatar}
                                 alt={`avatar-${f.id}`}/>
                            <div className={infos}>
                                <div
                                    className={memberUsername}>{f[target(f)].profilVisibleActivities ? f[target(f)].username : hideProfile}</div>
                                {f[target(f)].profilVisibleActivities ?
                                    invitations.added[f[target(f)].id] ?

                                        <Button onClick={() => handleInvitation(f[target(f)])} icon={{
                                            src: RemoveIcon,
                                            color: stylesVariables.siteColor3,
                                            size: 14
                                        }} className={[alreadyInvite]} title={remove} label={remove}/>
                                        : <Button
                                            title={add}
                                            onClick={() => handleInvitation(f[target(f)])}
                                            icon={{
                                                src: AddUserIcon,
                                                keepColor: true,
                                                size: DEFAULT_ICON_SIZE
                                            }}
                                            className={[notInvite]}
                                            label={add}/> : null
                                }

                            </div>
                        </div>

                    })
            }
        </div>
        <div className={emptyClass} ref={emptyRef}>
            {noResult}
        </div>
        {
            friendsState.data.length > 0 && (
                <Button onClick={confirmInvitationsTab} className={[validateButton]} title={confirm}
                        label={confirm}/>
            )
        }
    </div>
}

export default FriendsSuggestions
