import React from "react";
import tooltipClasses from "./tooltip.module.scss";
import {multipleClasses} from "../../util";

const {
    altClassName,
    tooltipWrapper,
    noEffectClass,
    noHoverClass,
    alwaysActiveClass
} = tooltipClasses;

const tooltipConfig = {
    bottom: {
        bottom: "-40px"
    },
    top: {
        top: "-40px"
    }
}

/**
 * Tooltip component
 * Show tooltip around the component
 * @param alt {string} the icon alt (tooltip)
 * @param position {"top" | "bottom"} the tooltip position
 * @param space {number} the space around the tooltip
 * @param alwaysActive
 * @param customAltClass
 * @param noEffect
 * @param children {JSX.Element} The component to wrap
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Tooltip = ({
                     alt,
                     position = "bottom",
                     space = .5,
                     alwaysActive = false,
                     customAltClass,
                     noEffect = true,
                     noHover = false,
                     children
                 }) => {
    return alwaysActive ? <div style={{
        margin: `0 ${space}rem`,
        ...alwaysActive.backgroundColor ? alwaysActive : {}
    }} className={alwaysActiveClass}>
        {children}
    </div> : <div style={{
        margin: `0 ${space}rem`
    }} className={multipleClasses(noHover ? noHoverClass : noEffect ? noEffectClass : tooltipWrapper)}>
        {children}
        {!noEffect && <div style={{...tooltipConfig[position]}}
                           className={multipleClasses(altClassName, customAltClass)}>{alt}</div>}
    </div>
}

export default React.memo(Tooltip)