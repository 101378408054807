import tah_flag from "../assets/flag/tah_flag.svg";
import fr_flag from "../assets/flag/fr_flag.svg";

// Front route constants
export const APP_ROUTE = {
    BASE: "/",
    ACTIVITY: "/activities/:activityId",
    ACTIVITY_MEMBERS: "/activities/:activityId/members",
    NEW_ACTIVITY: "/activities/new",
    EDIT_ACTIVITY: "/activities/:activityId/edit",
    AGENDA: "/agenda",
    FRIENDS: "/friends",
    MESSAGES: "/messages",
    MESSAGE: "/message/:id",
    PROFILE: "/profile",
    PROFILE_SHOW: "/profile/show",
    PROFILE_EDIT: "/profile/edit",
    PROFILE_INTERESTS: "/profile/interests",
    PROFILE_SETTINGS: "/profile/settings",
    PROFILE_HISTORY: "/profile/history",
    HELP: "/help",
    LNG: "/lng",
    LOGIN: "/login",
    LOGIN_OPTIONS: "/login_options",
    REGISTER_OPTIONS: "/register_options",
    INTERESTS: "/interests",
    FORGOT_PASSWORD: "/forgotPassword",
    REGISTER: "/register",
    FILTER: "/filter",
    NOTIFICATIONS: "/notifications",
    MENTIONS: "/mentions",
}

export const DEFAULT_ICON_SIZE = 24;

// Notification constants
export const TOP_CENTER = "top center";
export const BOTTOM_LEFT = "bottom left";
export const DANGER = "danger";
export const SUCCESS = "success";
export const INFO = "info";
export const WARNING = "warning";
export const DEFAULT = "default";

// Modal constants
export const LOGIN = "login";
export const REGISTER = "register";
export const ACTIVITY = "activity";
export const SHARE = "share";
export const CANCEL = "cancel";
export const TOGGLE_ACTIVITY_STATE = "toggle activity state";
export const ACTIVITY_CANCEL = "activity cancel";
export const PROFILE_DELETE = "profile delete";
export const FRIENDS_OPTIONS = "friends options";
export const FRIENDS_BLOCK = "friends block";
export const OPEN_CHAT_SUGGESTIONS = "open chat suggestions";
export const OPEN_FRIENDS_SUGGESTIONS = "open friends suggestions";
export const FRIENDS_REPORT = "friends report";
export const PARTICIPANTS = "participants";
export const FILTER = "filter";
export const SEARCH = "search";
export const FROM_LEFT = "left";

export const CLEAR = "CLEAR";
export const READ = "READ";
export const ADD_MANAGER_ACTIVITY_TO_FRIEND = "add_manager_activity_to_friend"
export const ACTIVITY_IMAGE = "activity_image"

// USEFUL other constants

export const EXPIRED_JWT_TOKEN = "Expired JWT Token"
export const INVALID_JWT_TOKEN = "Invalid JWT Token"
export const NOT_SET = "Non renseigné"

export const ACTIVITIES = "activities";
export const AGENDA = "agenda";
export const NOTIFICATIONS = "notifications";
export const FRIENDS = "friends";
export const MESSAGES = "messages";

export const NOT_READ = "non lu";
export const ALL = "all";
export const FOR_YOU = "forYou";
export const YOUR_FRIENDS = "yourFriends";
export const INVITATIONS = "invitations";
export const COMING = "coming";
export const CREATED = "created";
export const PASSED = "passed";
export const CHAT = "chat";
export const ABOUT = "about";

// Setup steps constants
export const LANGUAGE = "lng";
export const TUTORIAL = "tutorial";

// Working state constants
export const PROCESSING = "processing";
export const COMPLETED = "completed";
export const FAILED = "failed";

// Type constants
export const STRING = "string";
export const NUMBER = "number";
export const AS_NUMBER = "as number";

// Swiper constants
export const LEFT = "left";
export const RIGHT = "right";
export const TOP = "top";
export const BOTTOM = "bottom";

// Language constants
export const TAH = "th";
export const FR = "fr";
export const DEFAULT_LANGUAGE = FR;

export const lngIcons = {
    [TAH]: fr_flag,
    [FR]: tah_flag
}

// REGISTER STEP constants
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const CHANGE = "CHANGE";
export const VALIDATE = "VALIDATE";
export const RESEND_CODE = "resend code";
export const CREATE_PASSWORD = "CREATE_PASSWORD";
export const CREATE_USERNAME = "CREATE_USERNAME";

// NOTIFICATIONS TYPE constants
export const ACTIVITY_INVITATION = "activity_invitation";
export const ACTIVITY_DISABLED = "activity_disabled";
export const ACTIVITY_DATE_UPDATED = "activity_date_updated";
export const ACTIVITY_TIME_UPDATED = "activity_time_updated";
export const ACTIVITY_PLACE_UPDATED = "activity_place_updated";
export const PARTICIPATION_REGISTERED = "participation_registered";
export const COMMENT_ACTIVITY = "comment_activity";
export const FRIEND_ASK = "friend_ask";
export const FRIEND_ACCEPT = "friend_accept";
export const FRIEND_REFUSE = "friend_refuse";
export const MESSAGE_PRIVATE = "message_private";

export const MONTHS = {
    [FR]: {
        "janv": "janv",
        "févr": "févr",
        "mars": "mars",
        "avr": "avr",
        "mai": "mai",
        "juin": "juin",
        "juil": "juil",
        "août": "août",
        "sept": "sept",
        "oct": "oct",
        "nov": "nov",
        "déc": "déc",
    },
    [TAH]: {
        "janv": "Jēnuare",
        "févr": "Fepuare",
        "mars": "Māti",
        "avr": "‘Ēperēra",
        "mai": "Mē",
        "juin": "Tiunu",
        "juil": "Tiurai",
        "août": "‘Ātete",
        "sept": "Tetepa",
        "oct": "‘Ātopa",
        "nov": "Novema",
        "déc": "Tītema",
    }
}

export const FULL_MONTHS = {
    [FR]: {
        "janv": "janvier",
        "févr": "février",
        "mars": "mars",
        "avr": "avril",
        "mai": "mai",
        "juin": "juin",
        "juil": "juillet",
        "août": "août",
        "sept": "septembre",
        "oct": "octobre",
        "nov": "novembre",
        "déc": "décembre",
    },
    [TAH]: {
        "janv": "Jēnuare",
        "févr": "Fepuare",
        "mars": "Māti",
        "avr": "‘Ēperēra",
        "mai": "Mē",
        "juin": "Tiunu",
        "juil": "Tiurai",
        "août": "‘Ātete",
        "sept": "Tetepa",
        "oct": "‘Ātopa",
        "nov": "Novema",
        "déc": "Tītema",
    }
}

export const CGU_LINK =  "https://api.atamai-polynesie.com/upload/CGU_ATAMAI_20230110.pdf"

export const DAYS = {
    [FR]: {
        "lundi": "lundi",
        "mardi": "mardi",
        "mercredi": "mercredi",
        "jeudi": "jeudi",
        "vendredi": "vendredi",
        "samedi": "samedi",
        "dimanche": "dimanche",
    },
    [TAH]: {
        "lundi": "Monirē",
        "mardi": "Mahana piti",
        "mercredi": "Mahana toru",
        "jeudi": "Mahana maha",
        "vendredi": "Mahana pae",
        "samedi": "Mahana mā’a",
        "dimanche": "Tāpati",
    }
}

// Endpoint constants
export const CURRENT_API_BASE_URL = process.env.REACT_APP_CURRENT_API_BASE_URL;
export const CURRENT_ENDPOINT = {
    AUTH: {
        LOGIN: "/authentication_token",
        REGISTER: {
            [CREATE]: "/api/user/create",
            [VALIDATE]: "/api/user/create/validate",
            [RESEND_CODE]: "/api/user/create/resend-code",
            [CREATE_PASSWORD]: "/api/user/create/password",
            [CREATE_USERNAME]: "/api/user/create/username",
        },
        FORGOT_PASSWORD: {
            [UPDATE]: "/api/user/password/update",
            [VALIDATE]: "/api/user/password/validate",
            [CHANGE]: "/api/user/password/change"
        },
        REFRESH_TOKEN: "/api/token/refresh",
    },
    ACTIVITIES: {
        BASE: "/api/activities?page=:page&itemsPerPage=:ipp",
        SEARCH: "/api/activities?page=:page&itemsPerPage=:ipp&translations.title=:title",
        PAST: "/api/activities/past?page=:page&itemsPerPage=:ipp",
        MINE: "/api/activities/mine?page=:page&itemsPerPage=:ipp",
        CONCERNS_ME: "/api/activities/concerns_me?page=:page&itemsPerPage=:ipp",
        CONCERNS_FRIENDS: "/api/activities/concerns_friends?page=:page&itemsPerPage=:ipp",
        REGISTERED: "/api/activities/registered?page=:page&itemsPerPage=:ipp",
        INSCRIPTION: "/api/activity/inscription",
        CANCEL_INSCRIPTION: "/api/activity/inscription/:id",
        NEW: "/api/activities",
        UPDATE: "/api/activities/:id/update",
        DISABLE: "/api/activities/:id/disable",
        DELETE: "/api/activities/:id/delete",
        INVITATIONS_ADD: "/api/activities/:id/invitations",
        ONE: "/api/activities/:id",
        TYPE: "/api/type_activities",
    },
    ISLANDS: {
        BASE: "/api/islands"
    },
    MESSAGES: {
        BASE: "/api/messages",
        PRIVATE: "/api/messages/private?user=:id&page=:page&itemsPerPage=:ipp",
        PRIVATE_LAST: "/api/messages/private/last?pagination=false",
        ACTIVITY: "/api/messages/activity?activity=:id&page=:page&itemsPerPage=:ipp",
        NEW: "/api/message/add"
    },
    TOWNS: {
        BASE: "/api/towns"
    },
    FRIENDS: {
        BASE: "/api/friends",
        ASK: "/api/friends/ask",
        ONE: "/api/friends/:id",
        ACCEPT: "/api/friends/:id/accept"
    },
    BLOCK: {
        ADD: "/api/block/add",
    },
    NOTIFICATIONS: {
        BASE: "/api/notifications",
        CLEAR: "/api/notifications/clear",
        READ: "/api/notifications/read",
        ONE: "/api/notifications/:id",
        ENABLED: "/api/notifications?enabled=:enabled"
    },
    USER: {
        BASE: "/api/user",
        ME: "/api/user/me",
        ONE: "/api/users/:id",
        RGPD: "/api/user/:id/rgpd",
        DELETE: "/api/user/:id/unsubscribe",
        INTERESTS: "/api/user/:id/interests"
    },
    ACTIVITY_IMAGES: {
        BASE : "/api/activity_images"
    }
}

export const BREAKPOINTS = { mobile: 0, desktop: 968 }
