import activityIndicatorClasses from "./activityIndicator.module.scss";
import stylesVariables from '../../assets/styles/_variables.scss';
import {multipleClasses} from "../../util";

const {wrapper, child} = activityIndicatorClasses;

/**
 * Activity indicator  component
 * A global activity indicator component to show when processing things
 * @param activityClassName {string} Activity class name
 * @param size {number} the size of the indicator
 * @param borderSize {number} the border width
 * @param color {string} Activity indicator color
 * @return JSX.Element
 * @author Arnaud LITAABA
 */
const ActivityIndicator = ({activityClassName, size = 35, borderSize = 3, color = stylesVariables.siteColor2}) => {
    return <span style={{"--ac-color": color, "--ac-size": `${size}px`, "--ac-border-size": `${borderSize}px`}}
                 className={multipleClasses(wrapper, activityClassName)}>
        <span style={{"--delay": "0.2s"}}
              className={child}/>
        <span style={{"--delay": "0.3s"}}
              className={child}/>
        <span style={{"--delay": "0.4s"}}
              className={child}/>
    </span>
}

export default ActivityIndicator