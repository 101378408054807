import {createSlice} from '@reduxjs/toolkit'
import {DEFAULT} from "../../util/constants";

/**
 * Modal slice
 * A reducer for the modal system. So we can easily show modal
 * @author Arnaud LITAABA
 */
const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        show: false,
        choice: null,
        wrapperStyles: {
            minHeight: "20%"
        },
        action: null,
        type: DEFAULT,
        backTo: DEFAULT,
        from: DEFAULT,
        extra: {}
    },
    reducers: {
        show: (state, actions) => {
            state.type = actions.payload.type;
            state.choice = actions.payload.choice || null;
            state.wrapperStyles = {
                ...state.wrapperStyles,
                ...actions.payload.wrapperStyles || {minHeight: "20%"}
            };
            state.action = actions.payload.action || null;
            state.show = true;
            state.backTo = actions.payload.backTo || DEFAULT;
            state.from = actions.payload.from || DEFAULT;
            state.extra = {
                ...state.extra,
                ...actions.payload.extra
            } || {};
        },
        close: (state) => {
            state.type = "default";
            state.choice = null;
            state.wrapperStyles = {
                minHeight: "20%"
            };
            state.action = null;
            state.show = false;
            state.backTo = DEFAULT;
            state.from = DEFAULT;
            state.extra = {};
        }
    }
})

export const {show: showModal, close: closeModal} = modalSlice.actions;


export default modalSlice.reducer