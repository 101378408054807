import membersClasses from "./members.module.scss";
import Members from "./Members";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {globalDispatcher} from "../../../util";
import {closeModal} from "../../../redux/modalSlice/modalSlice";

const {participantsWrapper, participantsTitle} = membersClasses;

/**
 * Members modal
 * @param participants
 * @param onAction
 * @param isPassed
 * @param isActivityOwner
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const MembersModal = ({participants, onAction, isPassed, isActivityOwner}) => {

    const [state, setState] = useState([]);

    useEffect(() => {
        setState(participants)
    }, [participants])

    const onFriendRequest = useCallback((target) => {
        setState(state => {
            const participants = [...state];
            participants[target] = {
                ...participants[target],
                user: {
                    ...participants[target].user,
                    isFriendPending: true
                }
            }
            return participants
        })
    }, [])

    const onCancelParticipation = useCallback((p) => {
        setState(state => {
            return [...state.filter(part => part.id !== p.id)]
        })
        globalDispatcher(closeModal, null);
        onAction && onAction(p, p.guide ? 2 : 1)
    }, [onAction])

    const {
        peopleEngage,
        peopleEngage2,
    } = useSelector((state) => state.language[state.language.default]);
    return <div className={participantsWrapper}>
        <div className={participantsTitle}>{state.length} {state.length > 1 ? peopleEngage  :peopleEngage2}</div>
        <Members
            isPassed={isPassed}
            onFriendRequest={onFriendRequest}
            onCancelParticipation={onCancelParticipation}
            participants={state}
            isActivityOwner={isActivityOwner}
        />
    </div>
}

export default MembersModal;
