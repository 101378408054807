import React, {useCallback, useEffect, useState} from "react";
import customNotificationsClasses from "./customNotifications.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {multipleClasses} from "../../util";
import {closeNotification} from "../../redux/notifySlice/notifySlice";
import Icon from "../icon/Icon";
import CloseIcon from "../../assets/icons/close_save.svg"
import Tooltip from "../tooltip/Tooltip";
import {BOTTOM, BOTTOM_LEFT, DANGER, DEFAULT, INFO, SUCCESS, TOP, TOP_CENTER, WARNING} from "../../util/constants";

const {
    notifications,
    show,
    notificationsWrapper,
    deskNotificationsWrapper,
    text,
    close,
    progress,
    svgWrapper,
    circleWrapper,
    fill,
    value
} = customNotificationsClasses;

let intervalPointer = 0;

const ICON_SIZE = 24;
const FULL_STROKE = 188.16;
const EMPTY_STROKE = 314.16;

const notificationsStyles = {
    [DEFAULT]: {},
    [DANGER]: {
        color: "#f7f7f7",
        backgroundColor: "#B5012B"
    },
    [WARNING]: {
        color: "#242424",
        backgroundColor: "#FF8729"
    },
    [SUCCESS]: {
        color: "#3E000E",
        backgroundColor: "#FFE363"
    },
    [INFO]: {
        color: "#f7f7f7",
        backgroundColor: "#FF295A"
    }

}

const notificationsPositionStyles = {
    [TOP_CENTER]: {
        tooltip: BOTTOM,
        style: {
            top: "1rem",
            right: "1rem",
            left: "1rem",
            transform: "translateY(-200px)",
            width: "90%",
            margin: "auto"
        }
    },
    [BOTTOM_LEFT]: {
        tooltip: TOP,
        style: {
            bottom: "1rem",
            transform: "translateY(200px)"
        }
    }

}

/**
 * Custom Notifications component
 * Notify the user on actions
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const CustomNotifications = () => {

    const {showing, content, duration, type, id, position} = useSelector((state) => state.notify);

    const [timeoutState, setTimeoutState] = useState({stroke: EMPTY_STROKE, value: 0, id});

    /**
     * Timer reset Effect
     * This effect reset the timer state when the id of notification changes
     * @author Arnaud LITAABA
     */
    useEffect(() => {

        setTimeoutState(timeoutState => {
            if (timeoutState.id !== id) {
                return {stroke: EMPTY_STROKE, value: 0, id}
            }
            return timeoutState
        })

    }, [id])

    const dispatch = useDispatch();

    /**
     * Close function
     * This function close the notification whenever triggered
     * @author Arnaud LITAABA
     */
    const closeFunc = useCallback(() => {
        clearInterval(intervalPointer);
        dispatch(closeNotification());
    }, [dispatch])

    /**
     * Start counter function
     * This function start the notification timer
     * @author Arnaud LITAABA
     */
    const startCounter = useCallback(() => {
        setTimeoutState((timeoutState) => {
            /**
             * We check if the current value of stroke is greater than FULL_STROKE then we keep decreasing
             * until reach FULL_STROKE
             */
            if (timeoutState.stroke > FULL_STROKE) {
                /**
                 * We calculate the stroke value
                 * The stroke goes from EMPTY_STROKE = 314.16 to FULL_STROKE = 188.16. Its decrease
                 * The duration = FULL_STROKE : EX: 188.16 can be equal to 5, 10 second etc...
                 * ONE_SECOND_MATCH = ((FULL_STROKE * actual second 1, 2, 3 etc...) / duration) and stroke
                 * value changes at every second until reach the last second of duration.
                 * Since we decrease. The stroke value = EMPTY_STROKE - ONE_SECOND_MATCH
                 * And everything is fixed at 2 decimal point.
                 * We make sure to save the actual second 1, 2, 3 etc... in 'timeoutState.value' for next ROUND
                 * in the interval
                 *
                 */
                const stroke = (EMPTY_STROKE - ((FULL_STROKE * timeoutState.value) / (duration / 1000))).toFixed(2);
                return {
                    ...timeoutState,
                    value: timeoutState.value + 1,
                    stroke: stroke < 188.16 ? 188.16 : stroke
                }
            }
            /**
             * We close the notification because showing time is over and stroke is full
             */
            closeFunc();
            return timeoutState

        })

    }, [closeFunc, duration])

    const pauseTimer = useCallback(() => {
        clearInterval(intervalPointer)
    }, [])

    const resumeTimer = useCallback(() => {
        intervalPointer = setInterval(startCounter, 1000)
    }, [startCounter])

    useEffect(() => {

            /**
             * System want to show notification
             */
            if (showing) {
                /**
                 * We manually clear the interval (Older task pointer before start a new one)
                 */
                clearInterval(intervalPointer);
                setTimeoutState((timeoutState) => {
                    /**
                     * We initiate the stroke value
                     * The stroke goes from EMPTY_STROKE = 314.16 to FULL_STROKE = 188.16. Its decrease
                     * The duration = FULL_STROKE : EX: 188.16 can be equal to 5, 10 second etc...
                     * ONE_SECOND_MATCH = ((FULL_STROKE * initial second '1') / duration) and stroke
                     * value changes at every second until reach the last second of duration.
                     * Since we decrease. The stroke value = EMPTY_STROKE - ONE_SECOND_MATCH
                     * And everything is fixed at 2 decimal point.
                     * We make sure to save the initial second '1' in 'timeoutState.value' for next ROUND
                     * when launching the interval
                     *
                     */
                    const stroke = (EMPTY_STROKE - (FULL_STROKE / (duration / 1000))).toFixed(2);
                    return {
                        ...timeoutState,
                        value: timeoutState.value + 1,
                        stroke
                    }
                })
                /**
                 * We save the task pointer for easy cleanup later
                 * @type {number}
                 */
                intervalPointer = setInterval(startCounter, 1000)

                /**
                 * System want to close notification.
                 * We reset everything in timeoutState because nothing is showing
                 */
            } else {
                setTimeoutState(timeoutState => {
                    if (timeoutState.value !== 0) {
                        return {stroke: EMPTY_STROKE, value: 0, id: ""}
                    }
                    return timeoutState
                })
            }
        }, [duration, showing, dispatch, closeFunc, startCounter]
    )

    const {close: closeText} = useSelector((state) => state.language[state.language.default]);

    const chosenStyles = notificationsStyles[type];

    const commonContent = <div style={{...chosenStyles}}
                               className={multipleClasses(notifications)}>
        <div className={text}>{content + " !"}</div>
        <div onClick={closeFunc} className={close}>
            <Tooltip noHover={true} position={notificationsPositionStyles[position].tooltip} alt={closeText} space={0}>
                <Icon color={chosenStyles.color} src={CloseIcon} size={ICON_SIZE} sizeToWrapper/>
            </Tooltip>
            <div className={progress}>
                <svg className={svgWrapper}>
                    <circle className={multipleClasses(circleWrapper, fill)}></circle>
                    <circle style={{"--strokeValue": timeoutState.stroke, "--strokeColor": chosenStyles.color}}
                            className={multipleClasses(circleWrapper, value)}></circle>
                </svg>
            </div>
        </div>
    </div>

    return <>
        <div style={{
            ...notificationsPositionStyles[position].style
        }} onMouseEnter={pauseTimer} onMouseLeave={resumeTimer}
             className={multipleClasses(notificationsWrapper, showing ? show : "_")}>
            {commonContent}
        </div>
        <div style={{
            ...notificationsPositionStyles[position].style, width: "40%"
        }} onMouseEnter={pauseTimer} onMouseLeave={resumeTimer}
             className={multipleClasses(deskNotificationsWrapper, showing ? show : "_")}>
            {commonContent}
        </div>
    </>
}

export default React.memo(CustomNotifications)
