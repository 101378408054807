import {memo, useCallback} from "react";
import searchCardClasses from "./searchCard.module.scss";
import Icon from "../icon/Icon";
import stylesVariables from "../../assets/styles/_variables.scss";
import SearchIcon from "../../assets/icons/search.svg";
import Tooltip from "../tooltip/Tooltip";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {globalDispatcher, SAFE} from "../../util";
import {APP_ROUTE} from "../../util/constants";
import {closeModal} from "../../redux/modalSlice/modalSlice";

const {searchCardWrapper, searchCardWrapperLaunch, icon, label, launchLabel, bold} = searchCardClasses;

/**
 * Search card component
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const SearchCard = ({item, launch}) => {

    const userLanguage = useSelector((state) => state.language.default);

    const navigate = useNavigate()

    const name = item ? item.translations[userLanguage] ? item.translations[userLanguage].title : item.translations.fr.title : "";

    const next = useCallback(() => {
        navigate(APP_ROUTE.ACTIVITY.replace(":activityId", SAFE.encode(item.id)));
        globalDispatcher(closeModal, null)
    }, [item, navigate])

    return <div onClick={next}
                className={launch ? searchCardWrapperLaunch : searchCardWrapper}>
        <Tooltip alwaysActive={{
            backgroundColor: launch ? stylesVariables.siteColor3 : undefined
        }} space={0}>
            <Icon color={launch ? stylesVariables.neutralColor : stylesVariables.siteBlack}
                  globalWrapperClassName={icon}
                  src={SearchIcon}/>
        </Tooltip>
        {
            !launch ? <div className={label}>{name}</div> :
                <div className={launch ? launchLabel : label}>{launch.label} <span
                    className={bold}>{launch.value}</span></div>
        }
    </div>
}

export default memo(SearchCard)