import React, {useCallback, useEffect} from "react";
import modalClasses from "./modal.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {closeModal, showModal} from "../../redux/modalSlice/modalSlice";
import Icon from "../icon/Icon";
import CloseIcon from "../../assets/icons/close.svg";
import Tooltip from "../tooltip/Tooltip";
import stylesVariables from "../../assets/styles/_variables.scss";
import {globalDispatcher, multipleClasses} from "../../util";
import LoginPopUp from "../../views/loginPopUp/LoginPopUp";
import {
    ACTIVITY,
    ACTIVITY_CANCEL, ACTIVITY_IMAGE, ADD_MANAGER_ACTIVITY_TO_FRIEND,
    DEFAULT,
    FILTER,
    FRIENDS_BLOCK,
    FRIENDS_OPTIONS,
    FRIENDS_REPORT,
    FROM_LEFT,
    LOGIN,
    OPEN_CHAT_SUGGESTIONS,
    OPEN_FRIENDS_SUGGESTIONS,
    PARTICIPANTS,
    PROFILE_DELETE,
    REGISTER,
    SEARCH,
    SHARE
} from "../../util/constants";
import RegisterPopUp from "../../views/registerPopUp/RegisterPopUp";
import RegisterToActivityPopUp from "../../views/registerToActivityPopPup/RegisterToActivityPopUp";
import MembersModal from "../../views/activity/members/MembersModal";
import Filter from "../../views/filter/Filter";
import FriendsOptions from "../../views/friends/friendsOptions/FriendsOptions";
import FriendsBlock from "../../views/friends/friendsOptions/friendsBlock/FriendsBlock";
import FriendsReport from "../../views/friends/friendsOptions/friendsReport/FriendsReport";
import ChatSuggestions from "../../views/messages/chatSuggestions/ChatSuggestions";
import ShareView from "../../views/share/ShareView";
import DeleteProfile from "../../views/profile/deleteProfile/DeleteProfile";
import Search from "../../views/search/Search";
import FriendsSuggestions from "../../views/newActivity/friendsSuggestions/FriendsSuggestions";
import {AddManagerToFriend} from "../../views/friends/friendsOptions/addManagerFriend/AddManagerToFriend";
import {ActivityImage} from "../../views/newActivity/imageActivity/ActivityImage";

const {
    modalOverlay,
    modalWrapper,
    modalFilterWrapper,
    modalSearchWrapper,
    desktopModal,
    mobileModal,
    container,
    searchContainer,
    close,
    showClassName,
    showFilterClassName,
    showSearchClassName,
    showOverlayClassName,
    customAltClass
} = modalClasses;

const typesData = {
    [LOGIN]: () => <LoginPopUp/>,
    [REGISTER]: (choice) => <RegisterPopUp choice={choice}/>,
    [ACTIVITY]: (choice, action, extra) => <RegisterToActivityPopUp oneDone={action} choice={choice} shuttleAvailable={extra.shuttleAvailable}/>,
    [SHARE]: (choice) => <ShareView choice={choice}/>,
    [ACTIVITY_CANCEL]: (choice, action, extra) => <RegisterToActivityPopUp oneDone={action} choice={choice}
                                                                           mode={extra.mode}/>,
    [PROFILE_DELETE]: () => <DeleteProfile/>,
    [FRIENDS_OPTIONS]: (choice, action, extra) => <FriendsOptions choice={choice} onAction={action}
                                                                  mobile={extra.mobile}/>,
    [FRIENDS_BLOCK]: (choice, action) => <FriendsBlock onAction={action} choice={choice}/>,
    [FRIENDS_REPORT]: (choice, action, extra) => <FriendsReport onAction={action} choice={choice} mode={extra.mode}/>,
    [OPEN_CHAT_SUGGESTIONS]: () => <ChatSuggestions/>,
    [OPEN_FRIENDS_SUGGESTIONS]: (choice,action, extra) => <FriendsSuggestions invitations={choice} onAction={action} manager={extra?.manager} participants={extra?.participants}/>,
    [PARTICIPANTS]: (choice, action, extra) => <MembersModal participants={choice} onAction={action}
                                                             isPassed={extra.isPassed} isActivityOwner={extra.isActivityOwner}/>,
    [FILTER]: (_) => <Filter modal/>,
    [SEARCH]: (_) => <Search/>,
    [DEFAULT]: () => null,
    [ADD_MANAGER_ACTIVITY_TO_FRIEND]: (choice) => <AddManagerToFriend manager={choice} />,
    [ACTIVITY_IMAGE]: (choice, action, extra) => <ActivityImage onAction={action} extra={extra}/>
}

/**
 * Custom Modal component
 * Show modal
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Modal = () => {

    const {show, type, choice, wrapperStyles, action, from, backTo, extra} = useSelector((state) => state.modal);

    const dispatch = useDispatch();

    const closeFunc = useCallback(() => {
        if (![FRIENDS_BLOCK, FRIENDS_REPORT].includes(type) || backTo === DEFAULT) {
            dispatch(closeModal());
            return
        }
        globalDispatcher(showModal, {
            type: backTo,
            wrapperStyles: {
                height: extra.mobile ? "50%" : "330px",
                top: extra.mobile ? "75%" : "50%",
                width: "100%",
                borderBottomLeftRadius: extra.mobile ? 0 : "12px",
                borderBottomRightRadius: extra.mobile ? 0 : "12px"
            },
            choice,
            action
        });
    }, [dispatch, type, action, choice, backTo, extra])

    useEffect(() => {
        document.body.style.overflowY = show ? "hidden" : "auto"
    }, [show])


    const {close: closeText} = useSelector((state) => state.language[state.language.default]);

    const content = <div className={container}>
        {from !== SEARCH && <div onClick={closeFunc} className={close}>
            <Tooltip customAltClass={customAltClass} alt={closeText} space={0}>
                <Icon noStroke={true} color={stylesVariables.modalClose} src={CloseIcon} size={18} sizeToWrapper/>
            </Tooltip>
        </div>}
        {typesData[type](choice, action, extra)}
    </div>

    return <>
        <div onClick={closeFunc} className={multipleClasses(modalOverlay, show ? showOverlayClassName : "_")}/>
        <div style={{...wrapperStyles}}
             className={multipleClasses(modalWrapper, mobileModal, (show && from === DEFAULT) ? showClassName : "_")}>
            {from === DEFAULT && content}
        </div>
        <div style={{...wrapperStyles, width: extra.width ?? "600px"}}
             className={multipleClasses(modalWrapper, desktopModal, (show && from === DEFAULT) ? showClassName : "_")}>
            {from === DEFAULT && content}
        </div>
        <div
            className={multipleClasses(modalFilterWrapper, desktopModal, (show && from === FROM_LEFT) ? showFilterClassName : "_")}>
            {from === FROM_LEFT && content}
        </div>
        <div onClick={closeFunc}
             className={multipleClasses(modalSearchWrapper, desktopModal, (show && from === SEARCH) ? showSearchClassName : "_")}>
            <div onClick={(e) => e.stopPropagation()} className={searchContainer}>
                {from === SEARCH && content}
            </div>
        </div>
        <div onClick={closeFunc}
             className={multipleClasses(modalSearchWrapper, mobileModal, (show && from === SEARCH) ? showSearchClassName : "_")}>
            <div onClick={(e) => e.stopPropagation()} className={searchContainer}>
                {from === SEARCH && content}
            </div>
        </div>

    </>
}

export default React.memo(Modal)
