import {useActivityImage} from "../../../hooks/useActivityImage";
import activityImageClasses from "./activityImage.module.scss"
import React, {useCallback, useState} from "react";
import {getBase64, globalDispatcher, showNotification} from "../../../util";
import {closeModal} from "../../../redux/modalSlice/modalSlice";
import {ActivityImageSelector} from "./ActivityImageSelector";
import {DANGER} from "../../../util/constants";
import ActivityIndicator from "../../../components/activityIndicator/ActivityIndicator";
import {ActivityImageButtons} from "./ActivityImageButtons";
import {useSelector} from "react-redux";

const {
    informationApiMessageContainer,
    chooseImage,
    containerActivityImage,
    containerText,
    message
} = activityImageClasses

/**
 *
 * @param onAction
 * @param extra
 * @returns {JSX.Element}
 * @constructor
 */
export const ActivityImage = ({onAction, extra}) => {
    const {isLoading, images, error} = useActivityImage();
    const {
        chooseImageError,
        chooseImageText
    } = useSelector((state) => state.language[state.language.default]);
    const [imgSelected, setImgSelected] = useState({
        path: extra.fileActivity ? extra.fileActivity.path : '',
        touched: !!extra.fileActivity,
        id: extra.fileActivity ? extra.fileActivity.id : null
    });
    const [bannerState, setBannerState] = useState({
        base64: "",
        blob: null,
        touched: false
    });
    const fileRef = React.createRef();

    const handleSelectedImage = useCallback((image) => {
        setImgSelected({
                path: image.filePath,
                id: image["@id"],
                touched: true
            }
        );
        setBannerState({base64: "", blob: null, touched: false})
    }, [])

    const triggerFile = useCallback(() => {
        fileRef.current.click();
    }, [fileRef]);

    const prepareBanner = useCallback((files) => {
        getBase64(files[0], (base64) => {
            setBannerState({
                base64,
                blob: files[0],
                touched: true
            })
        })
    }, [])

    const handleFileChange = useCallback(() => {
        setImgSelected({
                path: "",
                id: null,
                touched: false
            }
        );
        prepareBanner(fileRef.current.files);
    }, [fileRef, prepareBanner]);

    const confirmImage = () => {
        if (!bannerState.touched && !imgSelected.touched) {
            showNotification(chooseImageError, DANGER);
            return;
        }
        onAction && onAction(bannerState, imgSelected);
        globalDispatcher(closeModal, null);
    }


    return (
        <div className={containerActivityImage}>
            <div className={containerText}>
                <span className={chooseImage}>{chooseImageText}</span>
            </div>
            {(!isLoading && !error) && (
                <ActivityImageSelector
                    handleSelectedImage={handleSelectedImage}
                    imgSelected={imgSelected}
                    images={images}
                />
            )}
            {isLoading && (
                <div className={informationApiMessageContainer}>
                    <ActivityIndicator />
                </div>
            )}
            {error && (
                <div className={informationApiMessageContainer}>
                    <span className={message}>
                        Une erreur a été rencontré lors de la récupération des images<br/>
                        Nous vous invitons à réessayer ou d'uploader une image.
                    </span>
                </div>
            )}
            <ActivityImageButtons
                fileRef={fileRef}
                imgSelected={imgSelected}
                bannerState={bannerState}
                confirmImage={confirmImage}
                triggerFile={triggerFile}
                handleFileChange={handleFileChange}
            />
        </div>
    )
}
