import friendsClasses from "../../friends.module.scss";
import {useSelector} from "react-redux";
import Button from "../../../../components/button/Button";
import {globalDispatcher} from "../../../../util";
import Link from "../../../../components/link/Link";
import {closeModal} from "../../../../redux/modalSlice/modalSlice";
import Icon from "../../../../components/icon/Icon";
import ReportIcon from "../../../../assets/icons/report.svg"
import stylesVariables from "../../../../assets/styles/_variables.scss"

const {
    friendsOptions,
    headerBlack,
    blockCard,
    validateButton,
    title,
    subTitle,
    wrapper,
    reportWrapper,
    reportEl,
    icon
} = friendsClasses;

/**
 * Friends report component
 * @param choice
 * @param onAction {function}
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const FriendsReport = ({choice, _, mode}) => {

    const {
        report,
        reportProfileOf,
        reportActivityText,
        reportTextSubTitle,
        finish,
    } = useSelector((state) => state.language[state.language.default]);

    const auth = useSelector((state) => state.auth.data);

    // const userLanguage = useSelector((state) => state.language.default);

    const titleText = () => {
        if (mode === "ACTIVITY") {
            return null // choice.translations[userLanguage] ? choice.translations[userLanguage].title : choice.translations.fr.title
        }
        const target = choice.userTo["@id"] === auth["@id"] ? "userFrom" : "userTo";
        return choice[target].username + " ?"
    }

    return <div className={friendsOptions}>
        <div className={headerBlack}>{report}</div>
        <div className={blockCard}>
            <div className={wrapper}>
                {mode === "ACTIVITY" &&
                    <Icon globalWrapperClassName={icon} size={30} src={ReportIcon} color={stylesVariables.siteColor3}/>}
                <div className={title}>{mode === "ACTIVITY" ? reportActivityText : reportProfileOf}</div>
                <div className={title}>{titleText()} </div>
            </div>
            <div className={subTitle}>{reportTextSubTitle}</div>

            <div className={reportWrapper}>
                <Link external className={reportEl} target={"mailto:moderateur@atamai-polynesie.com"}
                      label={"moderateur@atamai-polynesie.com"}/>
            </div>
        </div>
        <Button onClick={() => globalDispatcher(closeModal, null)} className={[validateButton]} title={finish}
                label={finish}/>
    </div>
}

export default FriendsReport