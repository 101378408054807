import React, {useEffect, useState} from "react";
import Icon from "../icon/Icon";
import errorClasses from "./error.module.scss"
import InfoIcon from "../../assets/icons/information.svg";
import {multipleClasses} from "../../util";
import stylesVariables from '../../assets/styles/_variables.scss';

const {animationDown, animationUp, error,messageClassName} = errorClasses;

/**
 * Error component
 * Show error
 * @param show {boolean} Show the error or not
 * @param message {string} the error message
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Error = ({show, message}) => {

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (show === false) {
            setTimeout(() => {
                setShowError(show);
            }, 200)
        } else {
            setShowError(show)
        }
    }, [show])

    return showError ? <span className={multipleClasses(error, show ? animationDown : animationUp)}>
                        <Icon color={stylesVariables.siteColor2} src={InfoIcon} size={14}/>
                        <span className={messageClassName}>{message}</span>
                    </span> : null
}

export default Error
