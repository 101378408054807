import authReducer from "./authSlice/authSlice";
import languageReducer from "./languageSlice/languageSlice";
import notifyReducer from "./notifySlice/notifySlice";
import {configureStore} from "@reduxjs/toolkit";
import globalReducer from "./globalSlice/globalSlice";
import filterReducer from "./filterSlice/filterSlice";
import modalReducer from "./modalSlice/modalSlice";

/**
 * App global store
 * @return A configured global store for the app. (Contains multiple reducers) !
 * @author Arnaud LITAABA
 */
export const store = configureStore({
    reducer: {
        auth: authReducer,
        language: languageReducer,
        notify: notifyReducer,
        global: globalReducer,
        filter: filterReducer,
        modal: modalReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['modal/show'],
                ignoredPaths: ['modal.action']
            },
        }),
})