import {createSlice} from '@reduxjs/toolkit'
import {ALL, FOR_YOU, YOUR_FRIENDS} from "../../util/constants";


/**
 * Filter slice
 * A reducer for the authenticated user
 * @author Arnaud LITAABA
 */
const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        data: {
            [ALL]: "",
            [FOR_YOU]: "",
            [YOUR_FRIENDS]: ""
        },
        from: ALL
    },
    reducers: {
        update: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload
            }
        },
        setTab: (state, action) => {
            state.from = action.payload
        },
    }
})

export const {update, setTab} = filterSlice.actions;

export default filterSlice.reducer