import React, {useCallback, useState} from "react";
import dropDownClasses from "./dropdown.module.scss"
import ArrowDownIcon from "../../assets/icons/arrowDown.svg"
import Icon from "../icon/Icon";
import SearchIcon from "../../assets/icons/search.svg";
import {makeIndex, multipleClasses, showNotification} from "../../util";
import stylesVariables from '../../assets/styles/_variables.scss';
import {useSelector} from "react-redux";
import {WARNING} from "../../util/constants";

const {
    dropdownOverlay,
    dropdownWrapper,
    dropdownSelection,
    dropdownValue,
    dropdownOptions,
    dropdownOptionsItem,
    dropdownOptionsWrapper,
    trigger,
    icon,
    isOpen,
    selected,
    searchTools,
    emptyClass,
    noDataClass,
    searchToolsInput
} = dropDownClasses;

/**
 * Dropdown component
 * @param options
 * @param placeholder
 * @param value
 * @param onChange
 * @param onOpen
 * @param type
 * @param arrowSize
 * @param color
 * @param lock {any} lock the items view
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Dropdown = ({
                      options = [],
                      placeholder = "Faîtes une sélection",
                      value,
                      onChange,
                      onOpen,
                      type = "string",
                      arrowSize,
                      color = stylesVariables.neutralColor,
                      lock
                  }) => {

    const [open, setOpen] = useState(false);

    const {
        makeSearch,
        noResult,
        noData
    } = useSelector((state) => state.language[state.language.default])

    const toggleDropdown = useCallback(() => {
        if (!open) {
            onOpen && onOpen();
        }
        if (lock && !open) {
            showNotification(lock, WARNING)
        }
        setOpen(!open)
    }, [open, onOpen, lock])

    const emptyRef = React.createRef();

    const search = (searchValue) => {
        let elements = document.getElementsByClassName(dropdownOptionsItem);
        let found = 0;
        Array.from(elements).forEach(element => {
            let txtValue = element.innerHTML;
            if (txtValue.toLowerCase().indexOf(searchValue) > -1 || txtValue.indexOf(searchValue) > -1 || txtValue.toUpperCase().indexOf(searchValue) > -1) {
                element.style.display = "flex";
                found += 1;
            } else {
                element.style.display = "none";
            }
        });
        if (found === 0 && emptyRef.current) {
            emptyRef.current.style.display = "flex"
        } else {
            emptyRef.current.style.display = "none"
        }
    }

    const onLocalChange = useCallback((v) => {
        onChange && onChange(v);
        toggleDropdown();
    }, [onChange, toggleDropdown])

    return <>
        {open && <div onClick={toggleDropdown} className={dropdownOverlay}/>}
        <div style={{
            "--drop-color": color
        }} className={dropdownWrapper}>
            <div style={{
                zIndex: open ? 2001 : 1003
            }} className={dropdownSelection}>
                <div data-placeholder={!value} className={dropdownValue}>{value || placeholder}</div>
                <div className={trigger} onClick={toggleDropdown}>
                    <div className={multipleClasses(icon, open ? isOpen : "_")}>
                        <Icon color={stylesVariables.siteColor3} src={ArrowDownIcon} size={arrowSize || 14}/>
                    </div>
                </div>
            </div>
            {open &&
                <div className={dropdownOptionsWrapper}>
                    <div style={{
                        zIndex: open ? 2000 : 1000
                    }} className={dropdownOptions}>
                        <div className={searchTools}>
                            <Icon color={stylesVariables.siteColor3} src={SearchIcon} size={16}/>
                            <input onChange={({target}) => search(target.value)}
                                   type="text" className={searchToolsInput}
                                   placeholder={makeSearch}/>
                        </div>
                        {lock ? <div
                            className={multipleClasses(noDataClass)}>
                            {lock}
                        </div> : options.length === 0 ?
                            <div
                                className={multipleClasses(noDataClass)}>
                                {noData}
                            </div>
                            : options.map((v, i) => {
                                return v && <div onClick={() => onLocalChange(v)} key={makeIndex(v, i)}
                                                 className={multipleClasses(dropdownOptionsItem, v === value ? selected : "_")}>
                                    {type === "object" ? v.render : v}
                                </div>
                            })}
                        <div className={emptyClass} ref={emptyRef}>
                            {noResult}
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
}


export default Dropdown
