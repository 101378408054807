import React from "react";
import linkClasses from "./link.module.scss";
import Icon from "../icon/Icon";
import {NavLink} from "react-router-dom";
import {multipleClasses} from "../../util";

const {
    link,
    deskLink,
    labelClassName,
    activeLink,
} = linkClasses;

/**
 * Link component
 * Show link with or without label
 * @param label {string} A label for the icon
 * @param linkClassName {string} A label for the icon
 * @param labelClasses {string[]} A label for the icon
 * @param iconSrc {string} the icon source
 * @param target {string} the link to navigate to
 * @param activeLinkClassName {string}
 * @param active {boolean}
 * @param inline {boolean} show label inline
 * @param customIcon {JSX.Element} Custom icon
 * @param children {JSX.Element} link children
 * @param external
 * @param rest {Object} useful other props
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Link = ({
                  target,
                  active = false,
                  activeLinkClassName = "",
                  inline = false,
                  label,
                  labelClasses = [],
                  iconSrc,
                  customIcon,
                  children,
                  linkClassName,
                  external = false,
                  ...rest
              }) => {

    if (external) {
        return <a className={linkClassName} href={target} target="_blank"
                  rel="noopener noreferrer" {...rest}>{label ?? target}</a>
    }

    return children ? <NavLink onClick={rest.onClick} className={multipleClasses(linkClassName, active ? activeLinkClassName : "_")}
                               title={rest.title || label} to={target}>{children}</NavLink> :
        <NavLink className={multipleClasses(linkClassName, active ? activeLinkClassName : "_")}
                 title={rest.title || label} onClick={rest.onClick} to={target}>
            <div className={inline ? deskLink : link}>
                {customIcon ? customIcon : iconSrc ? <Icon src={iconSrc} size={25}/> : null}
                {label && <span
                    className={multipleClasses(labelClassName, active ? activeLink : "_", ...labelClasses)}>{label}</span>}
            </div>
        </NavLink>
}

export default React.memo(Link)