export const TAH_WORDS = {
    loginText: "A tomo mai",
    myFriends: "Tō ‘u mau hoa",
    notifications: "Poro’i",
    account: "Tā ‘u piha mero",
    close: "‘Ōpani",
    skip: "Vaiiho",
    next: "I muri mai",
    chooseLng: "A ma'iti i te reo e faaohipahia",
    createAccountAndHaveAccessToApp: "A fa’atupu i tō ‘oe ti’ara’a mero no te fāna’o i te tā’ato’ara’a o teie rāve’a !",
    otherActivitiesLookUp: "A hi’o haere i te mau ‘ohipa ato’a i fa’atupuhia e te mau mero",
    canBeLikedActivities: "Te mau ‘ohipa ato’a e auhia ra e ‘oe !",
    canOrderActivitiesList: "A ‘āpapa i te tāpura nei ia au i tō ‘oe hina’aro.",
    newExp: "A tāpa’o mai ia ‘oe e a haere mai !",
    findTheOneAndSubs: "Ua ‘ite ‘oe i te ‘ohipa e au ia ‘oe ? A “Tāpa’o” ia ‘oe no te fa’aō atu i roto !",
    welcomeToApp: "Mānava i ni’a ia ATAMAI",
    phoneNumber: "Nūmera niuniu parauparau",
    password: "Ta’o tomo huna",
    forgotPassword: "Ua mo’e te ta’o tomo huna?",
    areYouNew: "Mea ‘āpī ‘oe i ‘o nei?",
    createAccount: "A fa’atupu i tā ‘oe piha mero",
    continueWithoutAccount: "Haere rātere noa",
    enterAValidPhoneNumber: "A patapata mai i te nūmera niuniu parauparau tano",
    enterAValidCode: "A patapata mai i te nūmera ha’apāpūra’a i fa’ataehia atu",
    enterAtLeast8Char: "A patapata mai e 6 a’e nūmera",
    invalidAuthInfo: "‘Aita i tano te mau ta’o tomo",
    loginInProgress: "Te fa’aineinehia ra te tomora’a. Tīa’i ri’i noa",
    codeConfirmationInProgress: "Te ha’apāpūhia ra ‘oe. Tīa’i ri’i noa",
    registerInProgress: "Te fa’aōhia ra ‘oe. Tīa’i ri’i noa",
    willReceiveSmsCode: "E tae atu te nūmera ha’apāpūra’a i ni’a i tā ‘oe niuniu parauparau SMS",
    registerWithPhone: "A fa’aō mai mā te fa’a’ohipa i tā ‘oe nūmera niuniu parauparau ‘āfa’ifa’i",
    codeSent: "Ua ha’aponohia atu te nūmera ha’apāpūra’a",
    phoneExist: "Te fa’a’ohipahia ra tenā nūmera niuniu",
    phoneNotFound: "‘Aita i ‘itehia mai tenā nūmera",
    serverError: "‘Aita i manuiā. Tīa’i ma’a taime e a tāmata fa’ahou ",
    codeText: "Nūmera ha’apāpūra’a",
    codeNotReceived: "‘‘Aita te poro’i SMS i tae atu ?",
    resendCode: "Fa’atae fa’ahou atu te nūmera ha’apāpūra’a",
    codeConfirmed: "Ua manuia te nūmera ha’apāpūra’a",
    mustMatchPassword: "Tītauhia ia tano te reira i te ta’o tomo huna",
    mustMatchConfirmation: "Tītauhia ia tano te reira i te ha’apāpūra’ahia",
    confirmPasswordText: "A ha’apāpū i te ta’o tomo huna",
    create8CharPassword: "A hāmani mai i tā ‘oe nūmera huna e 6 nūmera i te roa",
    checkPassword: "A hi’o maita’i na i tā ‘oe nūmera huna",
    passwordConfigured: "Ua oti te nūmera huna i te fa’aauhia",
    passwordConfirmationInProgress: "Te fa’aauhia nei tā ‘oe nūmera huna. Tīa’i ri’i noa",
    codeNotFound: "‘Aita te nūmera ha’apāpūra’a i hōro’ahia mai",
    userAlreadyValidate: "Ua oti tenā nūmera niuniu i te fa’ati’ahia, a tomo mai",
    passwordNotFound: "‘Aita te ta’o tomo huna i hōro’ahia mai",
    username: "I’oa",
    livingIsland: "Motu nohora’a",
    chooseLivingIsland: "A mā’iti i tō ‘oe motu nohora’a",
    usernameError: "A patapata mai e 3 rētera. E fa’ari’i hia te  rētera iti, rahi, te ārea, te '-' .",
    customizeProfile: "A fa’anaho mai i tō ‘oe ti’ara’a mero",
    noResult: "‘Aita e hope’ara’a !",
    makeSearch: "Mā’imi",
    checkInfos: "A hi’o maita’i i tā ’oe mau parau i patapata mai",
    updateInfoInProgress: "Te fa’a’āpīhia ra tā ‘oe mau parau i patapata mai",
    infosConfigured: "Ua oti tā ‘oe mau parau i te fa’aauhia",
    usernameAlreadyExist: "Te fa’a’ohipahia ra tenā I’oa mero",
    setYourInterests: "A fa’a’ite mai i te ‘ohipa e au-rahi-hia ra e ‘oe",
    letSGo: "Ua reva !",
    interestDetails: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aspernatur atque autem cumque eius eligendi impedit in, labore modi nostrum porro possimus quasi recusandae sequi tempore ullam unde ut velit!",
    setYourself: "A fa’a’ite mai tei auhia e ‘oe",
    finish: "Fa’aoti",
    forgotPasswordText: "Ua mo’e te ta’o tomo huna",
    forgotPasswordIntro: "A tu’u mai i tā ‘oe nūmera niuniu ‘āfa’ifa’i no te fa’a’āpīra’a i tā ‘oe ta’o tomo huna",
    enterReceivedCode: "A patapata mai na i te nūmera ha’apāpūra’a i fa’ataehia atu i ni’a i tā ‘oe niuniu SMS",
    createNewPassword: "A hāmani mai i tā ‘oe ta’o tomo huna ‘āpī e 6 nūmera",
    activitiesText: "‘Ohipa rau",
    accountNotFound: "‘Aita tenā piha mero",
    interestSet: "Ua fa’a’āpīhia te mau ‘ohipa e au-rahi-hia ra e ‘oe",
    updateInterestsInProgress: "Te fa’a’āpīhia ra te mau ‘ohipa e au-rahi-hia ra e ‘oe",
    agendaText: "Tārena",
    friendsText: "Hoa",
    messagesText: "Poro’i",
    profileText: "Iho Mero",
    all: "Pauroa",
    notRead: "‘Aita i tai’ohia",
    forYou: "NĀ ‘OE",
    yourFriends: "TŌ ‘OE MAU HOA",
    invitations: "Tītaura’a manihini",
    friends: "hoa",
    invitationsText: "tītaura’a manihini",
    noFriends: "‘Aita e hoa",
    noFriendsSubtitle: "Ia fa’ari’i noa atu te hoa i tā ‘oe tītaura’a manihini aore ra ia fa’ari’i ‘oe i te tahi tītaura’a manihini, e ‘ite ‘oe ia rātou i ‘o nei.",
    noInvitations: "‘Aita e tītaura’a manihini ‘āpī",
    noInvitationsSubtitle: "Ia tītau manihini mai te tahi ta’ata ia ‘oe, e ‘ite ‘oe i te reira i ‘ō nei .",
    iAmIn: "E tāpa’o vau ia ‘u",
    iWasIn: "Ua tāpa’o vau ia ‘u",
    alreadyPassed: "Déja passée",
    activityAlreadyPassed: "Ua oti a’e na teie ‘ohipa",
    cgu: "Fa’anahora’a rahi no teie tahuauira",
    noData: "‘Aita e parau ha’amāramaramara’a !",
    filter: "TĪTI’A",
    createActivity: "Fa’atupu i te ‘ohipa",
    applyText: "Fa’aoti",
    chooseDate: "Mā’iti mai i te tai’o mahana",
    choosePeriod: "Mā’iti i te tau",
    chooseTime: "E aha te hora ?",
    start: "Ha’amatara’a",
    ago: "Fātata e",
    daysText: "mahana i teie nei",
    hoursText: "hora i teie nei",
    ago2: "e ",
    startTime: "Hora ha’amatara’a",
    endTime: "Hora hopera’a",
    end: "Hope roa",
    chooseIsland: "‘Ō vai te motu ?",
    chooseCommune: "‘Ō vai te ‘oire ?",
    chooseActivityType: "Mā’iti i te hō’ē huru ‘ohipa",
    chooseValidDate: "Mā’iti mai i te tai’o mahana tano",
    chooseValidTime: "Mā’iti mai i te hora tano",
    dateText: "Tai’o mahana",
    hourText: "Hora",
    islandText: "Motu",
    communeText: "‘Oire",
    activityTypeText: "‘Āna’ira’a ‘ohipa",
    pmrText: "Nā te huma",
    paying: "Tāmoni",
    free: "Tāmoni ‘ore",
    setPrice: "Ha’apāpū mai i te fāito moni",
    insideText: "Roto",
    outsideText: "Rāpae",
    availableText: "Vatara’a",
    authRequired: "‘Ei mero ‘oe no te tomo mai",
    mustLogin: "Tītauhia ‘oe ia tomo no te fa’a’ohipa i teie fa’anahora’a",
    createAnAccount: "A fa’atupu i tā ‘oe piha mero",
    fullText: "Ua ‘ī roa",
    haveAnAccount: "E mero ‘oe ? A tomo mai !",
    newCreateAccount: "Mea ‘āpī ‘oe i ‘o nei ? A fa’atupu i tā ‘oe piha mero !",
    phoneConnect: "Niuniu parauparau",
    phoneConnectplaceholder: "Niuniu parauparau 87,88,89 XX XX XX",
    facebookConnect: "Facebook",
    cpsConnect: "Cps connect",
    chooseRegisterMode: "A mā’iti mai te rāve’a nō te ha’amanira’a i te iho",
    chooseLoginMode: "A mā’iti mai i te hō’ē rāve’a nō te tīhonora’a",
    validateRegistration: "A fa’ati’a i tō ‘u fa’aōra’a ia ‘u",
    validateConditions: "A fa’ati’a i teie mau fa’anahora’a hou a nu’u ai i mua",
    validateText: "FA’ATI’A",
    understandDev: "Ua māramarama maita’i au i te mea e ‘ore roa te ti’a’au o teie tahuauira e ti’a i te fa’ahapehia no te mau parau ato’a i piahia i ni’a i teie tahuauira e te feiā e fa’a’ohipa ra i teie rāve’a.",
    understandMinistery: "Ua māramarama maita’i au i te mea e ‘ore roa te Fa’aterera’a hau no te autaea’era’a no Pōrīnetia farāni e ti’a i te fa’ahapehia no te mau parau ato’a i piahia mai i ni’a i teie tahuauira e te feiā e fa’a’ohipa nei i teie rāve’a",
    acceptChartOnRegister: "Na roto i te ha’apāpūra’a i tā ‘u tāpa’ora’a i’oa, te fa’ari’i nei au i te mau fa’anahora’a e te mau fa’aturera’a ato’a no teie tahuauira.",
    atLeastOneInterest: "A mā’iti hō’ē a’e ‘ohipa e au-rahi-hia ra e ‘oe",
    register: "Tāpa’o mai",
    localisation: "Vaira’a",
    enterLocalisation: "Tei hea te vaira’a ?",
    manageBy: "Fa’anahohia e",
    price: "Moni",
    time: "Hora",
    availableSeat: "Pārahira’a vata",
    seats: "Pārahira’a",
    reset: "Fa’a’āpī",
    back: "Ho’i i muri",
    setAvailableSeat: "A fa’a’ite mai i te rahira’a pārahira’a vata",
    fromText: "Mai te",
    toText: "i te ",
    about: "Fa’ata’a mai na",
    chat: "E aha te mana’o ?",
    writeMessage: "Pāpa’i mai i te hō’ē poro’i",
    noMessage: "‘Aita e poro’i",
    createMessage: "Tāu’aparau ana’e",
    newMessage: "Poro’i ‘āpī",
    to: "Ia : ",
    suggestion: "Penei a’e ia",
    mentionsText: "Fa’aturera’a no teie tahuauira",
    disable: "Tūpohe",
    enable: "Fa’atere",
    confirmDelete: "A ha’apāpū e hina’aro ‘oe e fa’ahemo i teie ha’a ?",
    confirmDeleteProfile: "E pāpū anei ‘oe e hina’aro ‘oe e fa’a’ore i tā’oe iho ? ",
    confirmDisable: "A ha’apāpū e hina’aro ‘oe e fa’a’ore i teie ha’a ?",
    successDisable: "Ha’a fa’a’ore hia",
    successDelete: "Ha’a fa’ahemo hia",
    successDeleteProfile: "Iho fa’ahemo hia",
    deletingProfile: "Iho tei fa’ahemo hia nei",
    canNotRetrieveMessages: "Ua tupu te tahi fifi i te taime a ti’ihia ai tā ‘oe mau poro’i.",
    noMessageSubTitle: "Ia tae mai aore ra ia ha’apono atu ‘oe i te poro’i, e ‘ite ‘oe i te reira i ‘o nei.\n",
    messageInProgress: "Te ha’aponohia ra tā ‘oe poro’i",
    peopleEngage: "Ta’ata i fa’aō mai",
    peopleEngage2: "Ta’ata i fa’aō mai",
    seePeopleEngagedList: "Hi’o i te tāpura",
    confirm: "Ha’apāpū mai",
    cancelRegister: "Fa’a’ore i te tāpa’ora’a i’oa",
    inscription: "Tāpa’ora’a i’oa",
    confirmInscription: "Ha’apāpū i tō ‘oe tāpa’ora’a mai",
    confirmCancelInscription: "A ha’apāpū mai ‘aita ‘oe e hina’aro fa’ahou e tauto’o mai i teie ‘ohipa",
    bringFriend: "Fa’aō i te hō’ē ta’ata arata’i",
    notBringFriend: "A tātara i te ta’ata ‘āpe’e",
    deleteProfile: "A fa’ahemo i tā’u iho ",
    successGuide: "E ‘āpe’e hia anei ‘oe ? Māuruuru",
    successAlone: "E vai hō’ē anei ‘oe ? Māuruuru ",
    inscriptionInProgress: "Te tāpa’ohia ra ‘oe",
    successInscription: "Ua manuiā tō ‘oe tāpa’ora’ahia. Māuruuru",
    successCancelInscription: "Ua fa’a’orehia tō ‘oe tāpa’ora’ahia. Māuruuru",
    emptyActivitiesComingInfo: "A tāpa’o ia ‘oe i te tahi ‘ohipa ia ‘itehia te reira i ni’a i tā ‘oe tārena",
    emptyActivitiesCreatedInfo: "A fa’atupu i te ‘ohipa ia ‘itehia te reira i roto i tā ‘oe tārena",
    emptyActivitiesMineInfo: "A fa’atupu i te ‘ohipa ia ‘itehia te reira",
    emptyActivitiesAllInfo: "A fa’atupu i te ‘ohipa ia ‘itehia te reira",
    emptyActivitiesFriendsInfo: "A fa’atupu i te ‘ohipa aore ra a ani i tō ‘oe mau hoa ia fa’atupu mai rātou i te ‘ohipa ia ‘itehia te reira",
    noActivity: "‘Aita e ‘ohipa",
    activityTitle: "I’oa o te ‘ohipa",
    enterActivityTitle: "E aha te i’oa o te ‘ohipa ?",
    inviteFriends: "Tītau manihini i te hoa",
    activityDescription: "Fa’ata’ara’a i te ‘ohipa",
    enterActivityDescription: "Fa’ata’a mai na e aha teie ‘ohipa ?",
    privateText: "Unuma",
    chooseIslandBefore: "Mā’iti nā mua i te motu",
    publish: "Fa’atupu",
    chooseBannerText: "Mā’iti i te hō’ē hōho’a",
    activityAdded: "Ua manuiā te fa’atupura’a ‘ohipa",
    addActivityInProgress: "Te fa’atupuhia ra te ‘ohipa",
    comingActivities: "Te mau ‘ohipa a muri a’e",
    created: "Ua fa’atupuhia",
    passed: "Ua oti",
    filterResetText: "Ua fa’a’āpīhia te tīti’a",
    editFilter: "A fa’ahuru’ē i te tīti’a",
    isAuthorText: "Vous êtes l'organisateur",
    todayText: "Teie mahana",
    thisWeekText: "Teie hepetoma",
    thisMonthText: "Teie ‘āva’e",
    thisYearText: "Teie matahiti",
    othersText: "A muri a’e",
    editProfile: "Fa’ahuru’ē i tō ‘u ti’ara’a mero",
    edit: "Fa’ahuru’ē",
    editAvatar: "Taui i te hōho’a",
    myInterests: "Te ‘ohipa e au-rahi-hia ra e au",
    myActivitiesHistory: "Tua’ā’ai o tā ‘u mau ‘ohipa",
    accountSettings: "Fa’anahora’a o te piha mero",
    logout: "Fa’aru’e i te piha mero",
    login: "Tīhonora’a",
    name: "Pa’era’a",
    newPassword: "Ta’o tomo huna ‘āpī",
    save: "Haruharu",
    interests: "‘Ohipa au-rahi-hia",
    successLogoutText: "Ua manuiā te fa’aru’era’a piha mero",
    privateNavigation: "Fa’ahe’era’a huna",
    privateNavigationDesc: "A fa’ata’a mai na i te huru fa’a’ohipara’a o teie tahuauira e au ia ‘oe.",
    updateSettingsInProgress: "Te fa’a’āpīhia ra tā ‘oe mau fa’anahora’a",
    settingsUpdated: "Ua oti te mau fa’anahora’a i te fa’a’āpīhia",
    showMyProfileInEvent: "Fa’a’ite ‘o vai ‘oe i ni’a i te tārena ‘ohipa",
    showMyProfileInSearch: "Fa’a’ite ‘o vai ‘oe i ni’a i te ‘api mā’imira’a",
    changeSomethingBeforeSave: "Fa’ahuru’ē hou a nu’u ai i mua",
    activityUpdated: "Ua manuiā te fa’a’āpīra’a ‘ohipa",
    updateActivityInProgress: "Te fa’a’āpīhia ra te ‘ohipa",
    add: "Fa’aō",
    remove: "Fa’a’ore roa",
    refuse: "Pāto’i",
    cancel: "Fa’a’ore",
    acceptInProgress: "Te fa’atupuhia ra te auhoara’a",
    declineInProgress: "Te fa’a’orehia ra te auhoara’a",
    friendRequestAccepted: "Ua fa’ari’ihia te anira’a",
    friendRequestDeclined: "Ua fa’a’orehia te anira’a",
    blockContact: "Tāpe’a i teie mero",
    removeFromFriends: "Ti’avaru i teie mero",
    reportProfile: "Fa’aara ‘ē i teie mero",
    reportProfileOf: "Fa’aara ‘ē ia",
    sent: "Ha’aponohia",
    sendMessage: "Ha’apono i te poro’i",
    sendingMessage: "Te ha’aponohia ra te poro’i",
    removingFriendInProgress: "Te fa’a’orehia ra te auhoara’a",
    blockingFriendInProgress: "Te tāpe’ahia ra",
    report: "Fa’aara ‘ē",
    friendRemoved: "Ua fa’a’orehia te hoa",
    friendBlocked: "Ua tāpe’ahia te hoa",
    blockText: "Tāpe’a",
    reportTextSubTitle: "Ha’apono i te hō’ē parau fa’aarara’a i te ratauira nei :",
    blockTextSubTitle: "Ia tāpe’a ‘oe i te tahi ta’ata, e’ita e ti’a fa’ahou ia na i te fa’aō ia ‘oe i te tahi ‘ohipa, i te tāu’aparau mai ia ‘oe, i te ha’afārerei ia ‘oe i tō na mau hoa aore ra i te ‘ite i tā ‘oe mau ‘ohipa e rave ra.",
    blockTextSubTitle2: "Mai te mea e hoa ‘ōrua, ia tāpe’a ‘oe ia na ‘aita ‘ōrua e hoa fa’ahou.",
    send: "Ha’apono",
    askingFriend: "Te ha’apa’ohia ra tā ‘oe anira’a hoa",
    friendRequestSent: "Ua ha’aponohia tā ‘oe anira’a hoa",
    help: "Tauturu",
    language: "Reo",
    generalConditions: "Fa’anahora’a rahi no teie tahuauira",
    legalsMentions: "Fa’aturera’a no teie tahuauira",
    mts: "Fa’aterera’a hau nō te tōro’a , te mau auturuturura’a ‘e te ha’api’ira’a tōro’a",
    mtsSub: "Tei ha’apa’o nei i te orara’a ‘o te vahine, ‘o te ‘utuāfare ‘e te mau ta’ata ‘aimama",
    shareAndCreate: "A tapiho’o i tā ‘oe mau ‘ohipa i fa’atupu ia tupu māite te auhoara’a !",
    noNotifications: "‘Aita e poro’i",
    notificationsInfo: "Ia tae mai te poro’i e ‘ite ‘oe i te reira i ‘ō nei,",
    newText: "‘Āpī",
    newNotifications: "poro’i ‘āpī",
    seeAllNotifications: "Hi’o i te mau poro’i ato’a",
    markAllAsRead: "Tāpa’o e ua tai’o-pauroa-hia",
    oldText: "Tahito",
    hasCommentActivity: "Ua pia o ",
    hasCommentActivity2: "i tō na mana’o i ni’a i te ‘ohipa",
    haveCommentActivity: "Ua fa’ahiti ‘oe i tō ‘oe mana’o i ni’a i te ‘ohipa",
    hasAskFriend: "Ua ani mai o ::: ia hoa ‘ōrua",
    hasAcceptFriend: "Ua fa’aō o ::: ia ‘oe, ia hoa ‘ōrua",
    hasDeclineFriend: "‘Aita o ::: i fa’ari’i ia hoa ‘ōrua",
    manageContact: "Fa’anaho i te parau no teie ta’ata",
    yourConversations: "Tāu’aparaura’a",
    youText: "‘Oe",
    theActivity: "Ua fa’a’orehia te ‘ohipa",
    which_I_Engaged: "o tā ‘oe i tāpa’o atu ia ‘oe",
    canceled: "Fa’a’orehia",
    hasEngaged: "Tei tāpa’o i tā ‘oe ‘ohipa",
    haveEngaged: "Ua tāpa’ohia ‘oe i teie ‘ohipa",
    theLocalisation: "La localisation de l'activité",
    hasBeenUpdated: "Vaira’a o te ‘ohipa ",
    theDate: "Ua fa’a’āpīhia te tai’o mahana o te ‘ohipa ",
    theTime: "Ua fa’a’āpīhia te hora o te ‘ohipa ",
    reportActivityText: "Fa’aara ‘ē i te hō’ē ‘ohipa",
    share: "Hāpono",
    shareActivity: "Hāpono i te ha’a",
    copyLink: "Copier",
    successCopyLink: "Ua tāhōho’a hia te hono tahua natirara",
    invalidPhoneNumber: "Ua hape te nūmera",
    noResultFor: "Aita i manuia te imira’a nō ",
    inviteYou: "Tei titau manihini ia’oe nō te ha’a ",
    senTMessageToYou: "Tei hāpono atu i te tahi poro’iuira ",
    willBeInvite: "Tei titau manihini hia",
    willBeInviteAlone: "Tei titau manihini hia",
    noInvitationsWillBeSent: "Aore ta’ata tei titau manihini hia",
    allNotifAreRead: "Ua tai’o hia te ta’ato’ara’a ‘o te mau parau fa’a’ite",
    deleteMyAccount: "A fa’a’ore i tā’u iho",
    deleteMyAccountInfo: "Mai te peu e fa’a’ore outou i tā outou iho, e mo’e tā outou mau ‘itepiri",
    accountDeleted: "Iho fa’a’ore hia",
    accountDeleting: "Iho tei fa’aore hia nei, a ti’ai nā.",
    noPasswordChange: "A fa’a’ore i te tauira’a ‘o te ta’o huna",
    updatingProfile: "Profil en cours de mise à jour, veuillez patienter",
    profileUpdated: "Hanaira’a fa’a’āpī hia",
    passwordChanged: "Ta’o huna fa’a’āpī hia",
    changingPassword: "Ta’o huna tei fa’a’āpī hia nei ra, A ti’ai nā",
    updatedPhoto: "Hōho’a ‘o te hanaira’a fa’a’āpī hia",
    updatingPhoto: "Hōho’a ‘o te hanaira’a tei fa’a’āpī hia nei, A ti’ai nā",
    hideProfile: "Hanaira’a huna hia",
    invalidSeats: "E ti’a ia hou te parahira’a aore ra ia aifaito i te faito ta’ata i teie nei.",
    canceledMale: "Fa’a’orehia",
    //TODO Traduire clés suivantes en TAH
    addFriend: "Ajouter en amis",
    shuttleText: "Navette",
    setShuttle: "Préciser le nombre de places disponible",
    possibleText: "Possible",
    pmrShortText: "PMR",
    accessText: "Accès",
    requireShuttle: "Demander une navette",
    shuttleLocalisationText: "Indiquer le point de ramassage",
    guide: "Accompagnateur",
    chooseImageError: "Vous devez choisir une image pour valider.",
    chooseImageText: "Choisissez une image",
    noImageText: "Aucune image d'activité n'est disponible.",
    importImageText: "Importer votre image"
};
export const FR_WORDS = {
    loginText: "Se connecter",
    myFriends: "Mes amis",
    notifications: "Notifications",
    account: "Compte",
    close: "Fermer",
    skip: "Passer",
    next: "Suivant",
    chooseLng: "Choississez la langue qui sera utilisée",
    createAccountAndHaveAccessToApp: "Créer un compte pour avoir accès à toute l'appli !",
    otherActivitiesLookUp: "Parcourez les activités proposées par d'autres utilisateurs.",
    canBeLikedActivities: "Toutes les activités qui vous plaisent !",
    canOrderActivitiesList: "Triez la liste selon vos centres d’intérêt, vos envies, vos attentes et vos besoins.",
    newExp: "Inscrivez-vous et vivez de nouvelles expériences !",
    findTheOneAndSubs: "Vous avez trouvé l'activité qui vous plaît ? Participez en cliquant sur ”s'inscrire”",
    welcomeToApp: "Bienvenue sur l'application  ATAMAI",
    phoneNumber: "Numéro de téléphone",
    password: "Mot de passe",
    forgotPassword: "Mot de passe oublié?",
    areYouNew: "Vous êtes nouveau?",
    createAccount: "Créer votre compte",
    continueWithoutAccount: "Continuer sans compte",
    enterAValidPhoneNumber: "Entrez un numéro valide",
    enterAValidCode: "Entrez le code reçu",
    enterAtLeast8Char: "Entrez au minimum 6 caractères",
    invalidAuthInfo: "Identifiants invalides",
    loginInProgress: "Connexion en cours . Svp patientez",
    codeConfirmationInProgress: "Confirmation en cours . Svp patientez",
    registerInProgress: "Inscription en cours . Svp patientez",
    willReceiveSmsCode: "Vous allez recevoir un code de vérification par SMS",
    registerWithPhone: "Inscrivez-vous grâce à votre numero de téléphone",
    codeSent: "Code envoyé",
    phoneExist: "Numéro déja lié à un compte",
    phoneNotFound: "Numéro non retrouvé",
    serverError: "Une erreur est survenue. Svp réessayez plus tard. Merci",
    codeText: "Code de vérification",
    codeNotReceived: "Vous n'avez pas reçu le SMS ?",
    resendCode: "Renvoyez le code",
    codeConfirmed: "Code confirmé",
    mustMatchPassword: "Doit correspondre au mot de passe",
    mustMatchConfirmation: "Doit correspondre à la confirmation",
    confirmPasswordText: "Confirmer le mot de passe",
    create8CharPassword: "Créer votre mot de passe à 6 chiffres",
    checkPassword: "SVP Vérifiez votre mot de passe",
    passwordConfigured: "Mot de passe configuré",
    passwordConfirmationInProgress: "Configuration du mot de passe en cours . Svp patientez",
    codeNotFound: "Code non fourni",
    userAlreadyValidate: "Numero déjà validé éssayez de vous connecter",
    passwordNotFound: "Mot de passe non fourni",
    username: "Prénom",
    livingIsland: "île de résidence",
    chooseLivingIsland: "Choississez votre île de résidence",
    usernameError: "Entrez au moins 3 caractères. Uniquement les miniscules, majuscules, espaces, '-' sont autorisés",
    customizeProfile: "Personnalisez votre profil",
    noResult: "Pas de résultat !",
    makeSearch: "Rechercher",
    checkInfos: "Vérifiez vos informations",
    updateInfoInProgress: "Vos informations sont en cours de mise à jour",
    infosConfigured: "Informations configurées",
    usernameAlreadyExist: "Pseudo déja utilisé",
    setYourInterests: "Renseignez vos centres d'intérêt",
    letSGo: "C'est parti !",
    interestDetails: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam aspernatur atque autem cumque eius eligendi impedit in, labore modi nostrum porro possimus quasi recusandae sequi tempore ullam unde ut velit!",
    setYourself: "Définis-toi",
    finish: "Terminer",
    forgotPasswordText: "Mot de passe oublié",
    forgotPasswordIntro: "Entrez votre numéro de téléphone pour réinitialiser votre mot de passe",
    enterReceivedCode: "Entrez le code de vérification que vous avez reçu par SMS",
    createNewPassword: "Créer votre nouveau mot de passe à 6 chiffres",
    activitiesText: "Activités",
    accountNotFound: "Compte inexistant",
    interestSet: "Centres d'intêrets mis à jour",
    updateInterestsInProgress: "Mis à jour de vos centres d'intêrets en cours",
    agendaText: "Agenda",
    friendsText: "Amis",
    messagesText: "Messages",
    profileText: "Profil",
    all: "Tout",
    notRead: "Non lu",
    forYou: "Pour vous",
    yourFriends: "Vos amis",
    invitations: "Invitations",
    friends: "amis",
    invitationsText: "invitations",
    noFriends: "Aucun ami",
    noFriendsSubtitle: "Lorsque des amis acceptent votre invitation ou que vous acceptez des invitations d'amis, ils apparaissent ici.",
    noInvitations: "Aucune nouvelle invitation",
    noInvitationsSubtitle: "Lorsque des personnes vous envoient des invitations, elles apparaissent ici.",
    iAmIn: "Je participe",
    iWasIn: "J'ai participé",
    alreadyPassed: "Déja passée",
    activityAlreadyPassed: "Activité déja passée",
    cgu: "Conditions générales",
    noData: "Aucune donnée !",
    filter: "Filtrer",
    createActivity: "Créer une activité",
    applyText: "Appliquer",
    chooseDate: "Choisissez une date",
    choosePeriod: "Choisissez une période",
    chooseTime: "Choisissez une heure",
    start: "Début",
    ago: "Il y a environ",
    daysText: "jours",
    hoursText: "heures",
    ago2: "Il y a ",
    startTime: "Heure de début",
    endTime: "Heure de fin",
    end: "Fin",
    chooseIsland: "Choisissez une île",
    chooseCommune: "Choisissez une commune",
    chooseActivityType: "Choisissez un type d'activité",
    chooseValidDate: "Choisissez une date valide",
    chooseValidTime: "Choisissez une heure valide",
    dateText: "Date",
    hourText: "Heure",
    islandText: "île",
    communeText: "Commune",
    activityTypeText: "Type d'activité",
    pmrText: "Accès PMR",
    paying: "Payant",
    free: "Gratuit",
    setPrice: "Précisez le prix",
    insideText: "Intérieur",
    outsideText: "Extérieur",
    availableText: "Disponibilité",
    authRequired: "Authentification requise",
    mustLogin: "Vous devez être connecté pour accéder à cette fonctionnalité",
    createAnAccount: "Créer un compte",
    fullText: "Complet",
    haveAnAccount: "Vous avez déjà un compte, connectez-vous !",
    newCreateAccount: "Vous êtes nouveau, créer un compte !",
    phoneConnect: "Téléphone",
    phoneConnectplaceholder: "Téléphone 87,88,89 XX XX XX",
    facebookConnect: "Facebook",
    cpsConnect: "Cps connect",
    chooseRegisterMode: "Choississez le moyen de creation compte",
    chooseLoginMode: "Choississez un moyen  de connexion",
    validateRegistration: "Valider mon inscription",
    validateConditions: "Valider ces conditions pour continuer",
    validateText: "Valider",
    understandDev: "Je comprends que l'opérateur de la plateforme n’est pas responsable du contenu publié par les utilisateurs sur la plateforme",
    understandMinistery: "Je comprends que le ministère n’est pas responsable du contenu publié par les utilisateurs sur la plateforme",
    acceptChartOnRegister: "En validant mon inscription, j’accepte les conditions générales d’utilisation ainsi que la charte d’engagement.",
    atLeastOneInterest: "Choisissez au moins un centre d'intérêt",
    register: "S'inscrire",
    localisation: "Localisation",
    enterLocalisation: "Entrez la localisation",
    manageBy: "Organisé par",
    price: "Prix",
    time: "Heure",
    availableSeat: "Places disponibles",
    seats: "Places",
    reset: "Reinitialiser",
    back: "Retour",
    setAvailableSeat: "Indiquez le nombre de places disponibles",
    fromText: "De",
    toText: "à ",
    about: "à propos",
    chat: "Discussion",
    writeMessage: "Ecrire un message",
    noMessage: "Aucun message",
    createMessage: "Lancer une discussion",
    newMessage: "Nouveau message",
    to: "À : ",
    suggestion: "Suggestion",
    mentionsText: "Mentions légales",
    disable: "Désactiver",
    enable: "Activer",
    confirmDelete: "Confirmer vouloir supprimer cette activité ?",
    confirmDeleteProfile: "Êtes-vous sûr de vouloir supprimer votre profil ?",
    confirmDisable: "Confirmer vouloir annuler cette activité ?",
    successDisable: "Activité annulée",
    successDelete: "Activité supprimée",
    successDeleteProfile: "Profil supprimé",
    deletingProfile: "Profil en cours de suppression",
    canNotRetrieveMessages: "Une erreur est survenue lors de la récupération de vos messages.",
    noMessageSubTitle: "Lorsque que vous recevez ou envoyer des messages, ils apparaissent ici.\n",
    messageInProgress: "Envoi du message en cours",
    peopleEngage: "Participants",
    peopleEngage2: "Participant",
    seePeopleEngagedList: "Voir la liste",
    confirm: "Confirmer",
    cancelRegister: "Annuler l'inscription",
    inscription: "Inscription",
    confirmInscription: "Confirmer votre inscription à l'activité",
    confirmCancelInscription: "Confirmer ne plus vouloir participer à l'activité",
    bringFriend: "Ajouter un accompagnateur",
    notBringFriend: "Retirer l'accompagnateur",
    deleteProfile: "Supprimer mon profil",
    successGuide: "Vous viendrez accompagné. Merci",
    successAlone: "Vous viendrez seul. Merci",
    inscriptionInProgress: "Inscription en cours",
    successInscription: "Inscription réussie. Merci",
    successCancelInscription: "Inscription annulée. Merci",
    emptyActivitiesComingInfo: "Inscrivez-vous à une activité pour qu’elle s’affiche dans votre agenda",
    emptyActivitiesCreatedInfo: "Créez une activité pour qu’elle s’affiche dans votre agenda",
    emptyActivitiesMineInfo: "Créez une activité pour qu’elle s’affiche",
    emptyActivitiesAllInfo: "Créez une activité pour qu’elle s’affiche",
    emptyActivitiesFriendsInfo: "Créez une activité ou demandez à vos amis d'en créer une pour qu’elle s’affiche",
    noActivity: "Aucune activité",
    activityTitle: "Intitulé de l'activité",
    enterActivityTitle: "Entrez l'intitulé de l'activité",
    inviteFriends: "Invitez des amis",
    activityDescription: "Description de l'activité",
    enterActivityDescription: "Décrivez l'activité",
    privateText: "Privé",
    chooseIslandBefore: "Choisissez d'abord une île",
    publish: "Publier",
    chooseBannerText: "Choisir une image",
    activityAdded: "Activité créée avec succès",
    addActivityInProgress: "Création de l'activité en cours",
    comingActivities: "Activités à venir",
    created: "Créées",
    passed: "Passées",
    filterResetText: "Filtre réinitialisé",
    editFilter: "Modifier le filtre",
    isAuthorText: "Vous êtes l'organisateur",
    todayText: "Aujourd'hui",
    thisWeekText: "Cette semaine",
    thisMonthText: "Ce mois",
    thisYearText: "Cette année",
    othersText: "À venir",
    editProfile: "Modifier mon profil",
    edit: "Modifier",
    editAvatar: "Modifier la photo de profil",
    myInterests: "Mes centres d'intérêt",
    myActivitiesHistory: "Historique de mes activités",
    accountSettings: "Paramètres du compte",
    logout: "Déconnexion",
    login: "Connexion",
    name: "Nom",
    newPassword: "Nouveau mot de passe",
    save: "Enregistrer",
    interests: "Centre d'intérêts",
    successLogoutText: "Déconnexion réussie",
    privateNavigation: "Navigation privée",
    privateNavigationDesc: "Personnalisez votre expérience sur la plateforme en choisissant la manière dont vous souhaitez l’utiliser.",
    updateSettingsInProgress: "Vos paramètres sont en cours de mise à jour",
    settingsUpdated: "Paramètres mis à jour",
    showMyProfileInEvent: "Afficher votre profil dans les évènements",
    showMyProfileInSearch: "Afficher votre profil dans la recherche",
    changeSomethingBeforeSave: "Effectuez un changement avant de continuer",
    activityUpdated: "Activité mise à jour avec succès",
    updateActivityInProgress: "Mise à jour de l'activité en cours",
    add: "Ajouter",
    remove: "Supprimer",
    refuse: "Refuser",
    cancel: "Annuler",
    acceptInProgress: "Lien d'amitié en cours de création",
    declineInProgress: "Lien d'amitié en cours de suppression",
    friendRequestAccepted: "Demande acceptée",
    friendRequestDeclined: "Demande supprimée",
    blockContact: "Bloquer le contact",
    removeFromFriends: "Retirer de mes amis",
    reportProfile: "Signaler le profil",
    reportProfileOf: "Signaler le profil de",
    sent: "Envoyé",
    sendMessage: "Envoyer un message",
    sendingMessage: "Message en cours d'envoi",
    removingFriendInProgress: "Lien d'amitié en cours de suppression",
    blockingFriendInProgress: "Bloquage en cours",
    report: "Signaler",
    friendRemoved: "Ami retiré",
    friendBlocked: "Ami bloqué",
    blockText: "Bloquer",
    reportTextSubTitle: "Envoyez votre réclamation à l’adresse suivante :",
    blockTextSubTitle: "Les personnes que vous bloquez ne peuvent plus vous inviter à des activités, discuter avec vous, vous ajouter à leurs amis ou voir les activités que vous publiez.",
    blockTextSubTitle2: "Si vous êtes amis, ce blocage les supprime aussi de votre liste d’amis",
    send: "Envoyer",
    askingFriend: "Demande d'ami en cours",
    friendRequestSent: "Demande d'ami envoyée",
    help: "Aide",
    language: "Langue",
    generalConditions: "Conditions générales",
    legalsMentions: "Mentions légales",
    mts: "Ministère du travail et des solidarités et de la formation",
    mtsSub: "En charge de la Condition féminine, de la Famille et des Personnes non autonomes",
    shareAndCreate: "Partagez vos activités et créez du lien autour de vous !",
    noNotifications: "Aucune notification",
    notificationsInfo: "Lorsque que vous recevez des notifications, elles apparaissent ici.",
    newText: "Nouveau",
    newNotifications: "nouvelles notifications",
    seeAllNotifications: "Voir toutes les notifications",
    markAllAsRead: "Tout marquer comme lu",
    oldText: "Ancien",
    hasCommentActivity: "a commenté l'activité",
    haveCommentActivity: "avez commenté l'activité",
    hasAskFriend: "vous a demandé en ami",
    hasAcceptFriend: "vous a ajouté en ami",
    hasDeclineFriend: "vous a refusé comme ami",
    manageContact: "Gérer le contact",
    yourConversations: "Vos conversations",
    youText: "Vous",
    theActivity: "L'activité",
    which_I_Engaged: "à laquelle vous êtes inscrit a été",
    canceled: "annulée",
    hasEngaged: "vient de s'inscrire à votre activité",
    haveEngaged: "vous êtes inscrit à l'activité",
    theLocalisation: "La localisation de l'activité",
    hasBeenUpdated: "a été mise à jour",
    theDate: "La date de l'activité",
    theTime: "L'heure de l'activité",
    reportActivityText: "Signalé une activité",
    share: "Partager",
    shareActivity: "Partager l'activité",
    copyLink: "Copier",
    successCopyLink: "Lien copié avec succès",
    invalidPhoneNumber: "Numero invalide",
    noResultFor: "Pas de résulat pour ",
    inviteYou: "vous a invité à l'activité ",
    senTMessageToYou: "vous a envoyé un message ",
    willBeInvite: "seront invité",
    willBeInviteAlone: "sera invité",
    noInvitationsWillBeSent: "Personne ne sera invité",
    allNotifAreRead: "Toutes les notifications sont marqués comme lu",
    deleteMyAccount: "Supprimer mon compte",
    deleteMyAccountInfo: "La suppression de votre compte entraînera la perte de toutes vos données.",
    accountDeleted: "Compte supprimé",
    accountDeleting: "Compte en cours de suppression, SVP patientez",
    noPasswordChange: "Annuler le changement du mot de passe",
    updatingProfile: "Profil en cours de mise à jour, SVP patientez",
    profileUpdated: "Profil mis à jour",
    passwordChanged: "Mot de passe mis à jour",
    changingPassword: "Mot de passe en cours de mise à jour, SVP patientez",
    updatedPhoto: "Photo de profil mis à jour",
    updatingPhoto: "Photo de profil en cours de mise à jour, SVP patientez",
    hideProfile: "Profil masqué",
    invalidSeats: "Le nombre de places doit être supérieur ou égal au nombre actuel de participants",
    emptyActivitiesHistory: "Inscrivez-vous à une activité pour qu’elle s’affiche dans votre historique",
    canceledMale: "Annulé",
    addFriend: "Ajouter en amis",
    shuttleText: "Navette",
    setShuttle: "Préciser le nombre de places disponible",
    possibleText: "Possible",
    pmrShortText: "PMR",
    accessText: "Accès",
    requireShuttle: "Demander une navette",
    shuttleLocalisationText: "Indiquer le point de ramassage",
    guide: "Accompagnateur",
    chooseImageError: "Vous devez choisir une image pour valider.",
    chooseImageText: "Choisissez une image",
    noImageText: "Aucune image d'activité n'est disponible.",
    importImageText: "Importer votre image"
}
