import friendsClasses from "../../friends.module.scss";
import {useSelector} from "react-redux";
import stylesVariables from "../../../../assets/styles/_variables.scss";
import {useCallback, useState} from "react";
import {API, globalDispatcher, showNotification} from "../../../../util";
import {CURRENT_ENDPOINT, DANGER, FAILED, PROCESSING, SUCCESS, WARNING} from "../../../../util/constants";
import {closeModal} from "../../../../redux/modalSlice/modalSlice";
import Button from "../../../../components/button/Button";

const {friendsOptions, headerBlack, blockCard, validateButton, title, subTitle, wrapper} = friendsClasses;

/**
 * Friends block component
 * @param choice
 * @param onAction {function}
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const FriendsBlock = ({choice, onAction}) => {

    const {
        blockText,
        blockTextSubTitle,
        blockTextSubTitle2,
        friendBlocked,
        blockingFriendInProgress, serverError
    } = useSelector((state) => state.language[state.language.default]);

    const [workingState, setWorkingState] = useState("");

    const auth = useSelector((state) => state.auth.data);

    const target = choice.userTo["@id"] === auth["@id"] ? "userFrom" : "userTo";

    const block = useCallback(() => {
        if (workingState === PROCESSING) {
            showNotification(blockingFriendInProgress, WARNING);
            return
        }

        setWorkingState(PROCESSING);

        API.post(CURRENT_ENDPOINT.BLOCK.ADD, {
            userTo: choice[target]["@id"]
        }, false, {"Content-Type": "application/ld+json"})
            .then(response => {
                if (response.status === 201) {
                    setWorkingState(SUCCESS)
                    showNotification(friendBlocked, SUCCESS);
                    onAction && onAction(choice);
                    globalDispatcher(closeModal, null);
                }
            }).catch(_ => {
            setWorkingState(FAILED)
            showNotification(serverError, DANGER);
        })

    }, [choice, workingState, onAction, friendBlocked, serverError, blockingFriendInProgress, target])

    return <div className={friendsOptions}>
        <div className={headerBlack}>{blockText}</div>
        <div className={blockCard}>
            <div className={wrapper}>
                <div className={title}>{blockText}</div>
                <div className={title}>{choice[target].username} ?</div>
            </div>
            <div className={subTitle}>{blockTextSubTitle}</div>
            <div className={subTitle}>{blockTextSubTitle2}</div>
        </div>
        <Button indicator={workingState === PROCESSING ? {
            color: stylesVariables.neutralColor
        } : false} onClick={block} className={[validateButton]} title={blockText}
                label={blockText}/>
    </div>
}

export default FriendsBlock