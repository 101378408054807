import React, {useCallback} from "react";
import FriendsCardClasses from "./friendsCard.module.scss";
import defaultAvatar from "../../assets/avatar/defaultAvatar.png";
import OptionsIcon from "../../assets/icons/options.svg";
import MessagesIcon from "../../assets/icons/messages.svg";
import Icon from "../icon/Icon";
import {globalDispatcher, multipleClasses, SAFE} from "../../util";
import {showModal} from "../../redux/modalSlice/modalSlice";
import {APP_ROUTE, FRIENDS_OPTIONS} from "../../util/constants";
import {useSelector} from "react-redux";
import stylesVariables from "../../assets/styles/_variables.scss";
import {useNavigate} from "react-router-dom";

const {wrapper, deskWrapper, avatar, options, text, optionsBtn, optionsBtnLabel, tools, send} = FriendsCardClasses;

/**
 * Friends card component
 * @param data
 * @param mobile {boolean}
 * @param onAction {function}
 * @param onMessage
 * @param classNames {string[]}
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const FriendsCard = ({data, mobile, onAction, onMessage, classNames = []}) => {

    const auth = useSelector((state) => state.auth.data);

    const {
        sendMessage,manageContact
    } = useSelector((state) => state.language[state.language.default])

    const moreOptions = useCallback(() => {
        globalDispatcher(showModal, {
            type: FRIENDS_OPTIONS,
            wrapperStyles: {
                height: mobile ? "50%" : "330px",
                top: mobile ? "75%" : "50%",
                width: "100%",
                borderBottomLeftRadius: mobile ? 0 : "12px",
                borderBottomRightRadius: mobile ? 0 : "12px"
            },
            choice: data,
            action: onAction,
            extra: {
                mobile
            }
        });
    }, [data, onAction,mobile])

    const target = data.userTo["@id"] === auth["@id"] ? "userFrom" : "userTo";

    const navigate = useNavigate();

    const openChat = useCallback(() => {
        navigate(APP_ROUTE.MESSAGE.replace(":id", SAFE.encode(data[target].id + "_" + data[target].username)));
    }, [navigate, data, target])

    return mobile ? <div onClick={!onMessage ? null : onMessage} className={multipleClasses(wrapper, ...classNames)}>
        <img className={avatar} src={data[target].filePath ?? defaultAvatar} alt={`avatar-${data.id}`}/>
        <div className={text}>{data[target].username}</div>
        {!onMessage && <Icon onClick={moreOptions} globalWrapperClassName={options} src={OptionsIcon} keepColor/>}
    </div> : <div onClick={!onMessage ? null : onMessage} className={multipleClasses(deskWrapper, ...classNames)}>
        <img className={avatar} src={data[target].filePath ?? defaultAvatar} alt={`avatar-${data.id}`}/>
        <div className={text}>{data[target].username}</div>
        {!onMessage && <div className={options}>
            <div onClick={moreOptions} className={multipleClasses(optionsBtn, tools)}>
                <Icon size={22} globalWrapperClassName={options} src={OptionsIcon} keepColor/>
                <span className={optionsBtnLabel}>{manageContact}</span>
            </div>
            <div onClick={openChat} className={multipleClasses(optionsBtn, send)}>
                <Icon size={22} globalWrapperClassName={options} src={MessagesIcon}
                      color={stylesVariables.neutralColor}/>
                <span className={optionsBtnLabel}>{sendMessage}</span>
            </div>
        </div>}
    </div>
}

export default React.memo(FriendsCard)
