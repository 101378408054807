import ArrowLeftIcon from "../../assets/icons/arrowLeft.svg";
import searchClasses from "./search.module.scss";
import Icon from "../../components/icon/Icon";
import Input from "../../components/input/Input";
import {useSelector} from "react-redux";
import stylesVariables from "../../assets/styles/_variables.scss";
import CloseIcon from "../../assets/icons/close.svg";
import {API, globalDispatcher, makeIndex} from "../../util";
import {useCallback, useState, useTransition} from "react";
import {closeModal} from "../../redux/modalSlice/modalSlice";
import SearchCard from "../../components/searchCard/SearchCard";
import {CURRENT_ENDPOINT} from "../../util/constants";
import {NestedFallbackLoading} from "../loading/fallbackLoading/FallBackLoading";

const {searchWrapper, header, icon, input, closeClass, content} = searchClasses;

/**
 * Search component
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Search = () => {

    const {
        makeSearch, noResultFor
    } = useSelector(state => state.language[state.language.default]);

    const [searchValue, setSearchValue] = useState('');

    const [searchData, setSearchData] = useState({
        page: "1",
        ipp: "30",
        searching: false,
        loaded: false,
        data: []
    })

    // eslint-disable-next-line
    const [_, startTransition] = useTransition();

    const {page, ipp,loaded, searching, data} = searchData;

    const handleChange = useCallback((value) => {
        startTransition(() => {
            setSearchValue(value);
            setSearchData(searchData => {
                return {
                    ...searchData,
                    searching: true
                }
            })
            API.get(CURRENT_ENDPOINT.ACTIVITIES.SEARCH
                .replace(":page", page)
                .replace(":ipp", ipp)
                .replace(":title", value))
                .then(response => {
                    setSearchData(searchData => {
                        return {
                            ...searchData,
                            data: [...response.data["hydra:member"]],
                            searching: false,
                            loaded: true
                        }
                    })
                })
        });
    }, [page, ipp])

    const closeModalAction = useCallback(() => {
        globalDispatcher(closeModal, null)
    }, [])

    return <div className={searchWrapper}>
        <div className={header}>
            <Icon onClick={closeModalAction} color={stylesVariables.modalClose} width={29} height={20} globalWrapperClassName={icon}
                  src={ArrowLeftIcon}/>
            <Input value={searchValue} onChange={({target}) => handleChange(target.value)} icon={{
                src: CloseIcon,
                color: stylesVariables.siteColor3,
                size: 16,
                className: closeClass,
                onClick: () => setSearchValue('')
            }} className={[input]} type="text" placeholder={makeSearch}/>
        </div>
        <div className={content}>
            {searching ? <NestedFallbackLoading/> : data.map(item => <SearchCard
                key={makeIndex(item.id, 'search-card')} item={item}/>)}
        </div>
        {
            !searching && loaded && data.length <= 0 && searchValue !== '' && <SearchCard launch={{
                label: noResultFor,
                value: searchValue
            }}/>
        }
    </div>
}

export default Search