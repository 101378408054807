import inputClasses from "./input.module.scss";
import EyeIcon from "../../assets/icons/eye-open.svg";
import EyeHideIcon from "../../assets/icons/eye-hide.svg";
import {date, isSafariMacOrFirefox, multipleClasses} from "../../util";
import Error from "../error/Error";
import {useCallback, useState, useId} from "react";
import Icon from "../icon/Icon";
import stylesVariables from '../../assets/styles/_variables.scss';
import Dropdown from "../dropdown/Dropdown";
import {DEFAULT_ICON_SIZE} from "../../util/constants";
import Switch from "../switch/Switch";
import ForcedTimePicker from "./ForcedTimePicker";

const {
    wrapper,
    input,
    forcedInput,
    disabledClassName,
    revealIconClassName,
    revealIconClassNameMode,
    labelClassName,
    forcedDate,
} = inputClasses;

/**
 * Input component
 * @param type {string} The input type
 * @param error {{show, message}} Has an error
 * @param label {string} Input label
 * @param placeholder {string} The input placeholder
 * @param recoveringClassName
 * @param className {string[]} all useful class name for the input
 * @param switchClassName {string[]} all useful class name for the switch input
 * @param selectOptions {object} all useful props for dropdown
 * @param disabled {boolean} disabled input
 * @param recovering {boolean} The input mode
 * @param icon {{src, size, color,noRight, className,onClick}} The input mode
 * @param wrapperClassName {string[]} all useful class name for the input wrapper
 * @param rest {object} all other props
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Input = ({
                   type = "text",
                   error = {},
                   placeholder,
                   className = [],
                   switchClassName = [],
                   selectOptions,
                   disabled,
                   recovering,
                   recoveringClassName,
                   label,
                   icon,
                   wrapperClassName = [],
                   ...rest
               }) => {

    const [passwordState, setPasswordState] = useState("password");

    const [showPicker, setShowPicker] = useState(false);

    const randId = useId();

    const showTime = useCallback(() => {
        if (isSafariMacOrFirefox()) {
            setShowPicker(true)
        }
    }, [])

    if (type === "select") {
        return <Dropdown color={rest.color} placeholder={placeholder} value={rest.dropdownValue}
                         type={selectOptions.type}
                         options={selectOptions.data}
                         lock={rest.lock}
                         onChange={rest.onDropdownChange}/>
    }

    if (type === "switch") {
        return <Switch value={rest.value} switchClassName={switchClassName} onSwitch={rest.onSwitch} icon={icon}
                       label={placeholder}/>
    }

    return <div data-checkbox-wrapper={type === "checkbox"} className={multipleClasses(wrapper, ...wrapperClassName)}>
        {
            type === "textarea" ?
                <textarea placeholder={placeholder}
                          className={multipleClasses(input, disabled ? disabledClassName : "_", ...className)} {...rest}/> :


                ["date", "time"].includes(type) ? <div className={forcedDate}>
                        <input onChange={() => null}
                               value={rest.value !== "" ? date(type === "date" ? rest.value : "01/01/2000 " + rest.value).format(type === "date" ? "forcedDate" : "pickerTime") : rest.value}
                               type="text"
                               placeholder={placeholder}
                               className={multipleClasses(input, forcedInput, disabled ? disabledClassName : "_", ...className)}/>
                        <input onClick={type === "time" ? showTime : null} data-checkbox={type === "checkbox"}
                               data-date-time={(type === "date") || (type === "time")}
                               type={type === "password" ? passwordState : type}
                               placeholder={placeholder}
                               className={multipleClasses(input, disabled ? disabledClassName : "_", ...className)} {...rest}/>
                        {type === "time" && showPicker &&
                            <ForcedTimePicker hide={() => setShowPicker(false)} onChange={rest.onChange} value={rest.value}/>}
                    </div> :
                    <input data-checkbox={type === "checkbox"} data-date-time={(type === "date") || (type === "time")}
                           type={type === "password" ? passwordState : type}
                           placeholder={placeholder}
                           id={multipleClasses(input + randId)}
                           className={multipleClasses(input, disabled ? disabledClassName : "_", ...className)} {...rest}/>

        }
        {icon && icon.src && !icon.noRight &&
            <Icon onClick={icon.onClick} globalWrapperClassName={icon.className} src={icon.src} size={icon.size} color={icon.color}/>}
        {label && <label htmlFor={multipleClasses(input + randId)} className={labelClassName}>{label}</label>}
        {type === "password" &&
            <Icon color={stylesVariables.siteColor3}
                  globalWrapperClassName={recovering ? recoveringClassName ? multipleClasses(recoveringClassName, revealIconClassName) : revealIconClassNameMode : revealIconClassName}
                  size={DEFAULT_ICON_SIZE}
                  src={passwordState === "password" ? EyeIcon : EyeHideIcon}
                  onClick={() => setPasswordState(passwordState => passwordState === "password" ? "text" : "password")}
            />}
        <Error show={error.show} message={error.message}/>
    </div>
}

export default Input