import switchClasses from "./switch.module.scss";
import Icon from "../icon/Icon";
import {useCallback, useState} from "react";
import {multipleClasses} from "../../util";

const {
    label,
    switchSection,
    info, icon,
    switchEl,
    activeSwitch,
    slider
} = switchClasses;

/**
 * Switch component
 * @param labelText {string} label
 * @param iconOptions {{src, size, color, keepColor, className, noStroke}} The input mode
 * @param value {boolean} The value to control the switch
 * @param switchClassName {string[]} Switch class name
 * @param onSwitch {function} on Switch action
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Switch = ({label: labelText, icon: iconOptions = {}, switchClassName = [], onSwitch, value}) => {

    const [active, setActive] = useState(value ?? false);

    const handleSwitch = useCallback(() => {
        setActive(!active);
        onSwitch && onSwitch(!active);
    }, [onSwitch, active]);

    const trigger = useCallback(() => {
        return typeof value === "undefined" ? active : value
    }, [active, value])

    return <div onClick={handleSwitch} className={switchSection}>
        <div className={info}>
            {
                iconOptions.src &&
                <Icon keepColor={iconOptions.keepColor}
                      globalWrapperClassName={multipleClasses(icon, iconOptions.className)} src={iconOptions.src}
                      size={iconOptions.size}
                      color={iconOptions.color}
                      noStroke={iconOptions.noStroke ?? false}
                />
            }
            <span className={multipleClasses(label, ...switchClassName)}>{labelText}</span>
        </div>
        <div className={multipleClasses(switchEl, trigger() ? activeSwitch : "_")}>
            <div className={slider}/>
        </div>
    </div>
}

export default Switch
