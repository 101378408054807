import {createSlice} from '@reduxjs/toolkit'
import {TOP_CENTER} from "../../util/constants";

/**
 * Notification slice
 * A reducer for the notification system. So we can easily show notification
 * @author Arnaud LITAABA
 */
const notifySlice = createSlice({
    name: 'notify',
    initialState: {
        id: "",
        content: "",
        showing: false,
        duration: 5000,
        position: TOP_CENTER,
        type: "default",
    },
    reducers: {
        show: (state, actions) => {
            state.content = actions.payload.content;
            state.id = actions.payload.id;
            state.type = actions.payload.type;
            state.duration = actions.payload.duration || state.duration;
            state.position = actions.payload.position || state.position;
            state.showing = true;
        },
        close: (state) => {
            state.showing = false
        }
    }
})

export const {show: notify, close: closeNotification} = notifySlice.actions;


export default notifySlice.reducer