import {createSlice} from '@reduxjs/toolkit'

/**
 * Global slice
 * A reducer for the global data
 * @author Arnaud LITAABA
 */
const globalSlice = createSlice({
    name: 'global',
    initialState: {
        data: {
            islands: [],
            activitiesType: [],
            towns: [],
            receiveInvitations: {}
        }
    },
    reducers: {
        setIslandsData: (state, actions) => {
            state.data = {
                ...state.data,
                islands: actions.payload
            }
        },
        setActivitiesTypeData: (state, actions) => {
            state.data = {
                ...state.data,
                activitiesType: actions.payload
            }
        },
        setTowns: (state, actions) => {
            state.data = {
                ...state.data,
                towns: actions.payload
            }
        },
        setCustomData: (state, actions) => {
            state.data = {
                ...state.data,
                [actions.payload.target]: {
                    ...state.data[actions.payload.target],
                    ...actions.payload.data
                }
            }
        },
    }
})

export const {setIslandsData, setActivitiesTypeData,setTowns,setCustomData} = globalSlice.actions;


export default globalSlice.reducer