import filterClasses from "./filter.module.scss";
import Icon from "../../components/icon/Icon";
import BackIcon from "../../assets/icons/arrowLeft.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import DateIcon from "../../assets/icons/agenda.svg";
import TimeIcon from "../../assets/icons/timeIcon.svg";
import LocationIcon from "../../assets/icons/location.svg";
import ActivityIcon from "../../assets/icons/activity.svg";
import stylesVariables from "../../assets/styles/_variables.scss";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {
    formatData,
    globalDispatcher,
    makeIndex,
    multipleClasses,
    resetAForm,
    setSelectData,
    showNotification,
    toArray,
    useQuery,
    validate
} from "../../util";
import Input from "../../components/input/Input";
import {APP_ROUTE, SUCCESS} from "../../util/constants";
import {update} from "../../redux/filterSlice/filterSlice";
import {closeModal} from "../../redux/modalSlice/modalSlice";

const {
    filterWrapper,
    filterModalWrapper,
    inputModal,
    header,
    title,
    content,
    inputSection,
    checkboxSection,
    label,
    pickerIcon,
    pickerIconModal,
    applyClass
} = filterClasses;

/**
 * Filter component
 * @param modal {boolean}
 * @return {JSX.Element}
 * @author Arnaud LITAABA
 */
const Filter = ({modal}) => {

    const {
        filter: filterText,
        dateText,
        hourText,
        islandText,
        communeText,
        activityTypeText,
        applyText,
        choosePeriod,
        chooseTime,
        chooseIsland,
        chooseCommune,
        chooseActivityType,
        chooseValidDate,
        chooseValidTime,
        pmrText,
        insideText,
        outsideText,
        availableText,
        filterResetText,
        shuttleText
    } = useSelector((state) => state.language[state.language.default]);

    const {
        islands: islandsData,
        activitiesType: activitiesTypeData,
        towns: townsData
    } = useSelector((state) => state.global.data);

    const userLanguage = useSelector((state) => state.language.default);

    const {from: actualTab, data: filterData} = useSelector((state) => state.filter);

    const navigate = useNavigate();

    const params = useQuery(filterData[actualTab])

    const [data, setData] = useState(
        {
            date: {
                label: "date",
                name: dateText,
                icon: DateIcon,
                data: [],
                placeholder: choosePeriod,
                type: "date",
                touched: false,
                value: params.date ?? "",
                // value: params.date ?? date('01/01/2000').format("picker"),
                defaultValue: "",
                valid: !!params.date,
                validation: {
                    required: true
                },
                errorMessage: chooseValidDate
            },
            hour: {
                label: "hour",
                name: hourText,
                icon: TimeIcon,
                data: [],
                placeholder: chooseTime,
                type: "time",
                touched: false,
               // value: params["time_start[before]"] ?? date('01/01/2000').format("pickerTime"),
                value: params["timeStart[before]"] ?? "",
                defaultValue: "",
                valid: !!params["timeStart[before]"],
                validation: {
                    required: true
                },
                errorMessage: chooseValidTime
            },
            island: {
                label: "island",
                name: islandText,
                icon: LocationIcon,
                data: [],
                type: "select",
                placeholder: chooseIsland,
                touched: false,
                value: setSelectData(islandsData, userLanguage, params["island.id"]),
                defaultValue: {},
                valid: !!params["island.id"],
                validation: {
                    requiredSelect: true
                },
                errorMessage: chooseIsland
            },
            commune: {
                label: "commune",
                name: communeText,
                icon: LocationIcon,
                data: [],
                type: "select",
                placeholder: chooseCommune,
                touched: false,
                value: setSelectData(townsData, userLanguage, params["town.id"]),
                defaultValue: {},
                valid: !!params["town.id"],
                validation: {
                    requiredSelect: true
                },
                errorMessage: chooseCommune
            },
            activityType: {
                label: "activityType",
                name: activityTypeText,
                icon: ActivityIcon,
                data: [],
                type: "select",
                placeholder: chooseActivityType,
                touched: false,
                value: setSelectData(activitiesTypeData, userLanguage, params["typeActivity.id"]),
                defaultValue: {},
                valid: !!params["typeActivity.id"],
                validation: {
                    requiredSelect: true
                },
                errorMessage: chooseActivityType
            },
        }
    );

    const [checkboxData, setCheckboxData] = useState(
        {
            pmr: {
                label: "pmr",
                name: pmrText,
                placeholder: pmrText,
                type: "checkbox",
                value: params.pmr === 'true',
                defaultValue: false,
            },
            outside: {
                label: "outside",
                name: outsideText,
                placeholder: outsideText,
                type: "checkbox",
                value: params.exterieur === 'true',
                defaultValue: false,
            },
            available: {
                label: "available",
                name: availableText,
                placeholder: availableText,
                type: "checkbox",
                value: params.disponibility === 'true',
                defaultValue: false,
            },
            inside: {
                label: "inside",
                name: insideText,
                placeholder: insideText,
                type: "checkbox",
                value: params.interieur === 'true',
                defaultValue: false,
            },
            shuttleAvailable: {
                label: "shuttle",
                name: shuttleText,
                placeholder: shuttleText,
                type: "checkbox",
                value: params.shuttleAvailable === "true"
            }
        }
    );

    const handleCheckboxChange = useCallback((target) => {
        setCheckboxData(data => {
            return {
                ...data,
                [target]: {
                    ...data[target],
                    value: data[target].value === false
                }
            }
        });

    }, []);

    useEffect(() => {
        setData(state => {
            return {
                ...state,
                island: {
                    ...state.island,
                    data: [...islandsData]
                },
                activityType: {
                    ...state.activityType,
                    data: [...activitiesTypeData]
                },
                commune: {
                    ...state.commune,
                    data: [...townsData]
                }
            }
        })

    }, [islandsData, activitiesTypeData, townsData])

    const handleChange = useCallback((value, target) => {
        setData(data => {
            return {
                ...data,
                [target]: {
                    ...data[target],
                    value: value,
                    touched: true,
                    valid: validate(value, data[target].validation)
                }
            }
        });

    }, []);

    const applyFilter = useCallback(() => {
        let filterURI = "";
        const {date, island, commune, activityType, hour} = data;
        const {pmr, inside, outside, shuttleAvailable} = checkboxData;
        if (date.valid) {
            filterURI += `&date=${date.value}`
        }
        if (hour.valid) {
            filterURI += `&timeStart[before]=${hour.value}`
        }
        if (island.valid) {
            filterURI += `&island.id=${island.value.id}`
        }
        if (commune.valid) {
            filterURI += `&town.id=${commune.value.id}`
        }
        if (activityType.valid) {
            filterURI += `&typeActivity.id=${activityType.value.id}`
        }
        if (pmr.value) {
            filterURI += `&pmr=${pmr.value}`
        }
        if (outside.value) {
            filterURI += `&exterieur=${outside.value}`
        }
        if (inside.value) {
            filterURI += `&interieur=${inside.value}`
        }
        if (shuttleAvailable.value) {
            filterURI += `&shuttleAvailable=${shuttleAvailable.value}`
        }

        globalDispatcher(update, {[actualTab]: filterURI});
        if (modal) {
            globalDispatcher(closeModal, null);
            return
        }
        navigate(-1);

    }, [data, checkboxData, actualTab, navigate, modal]);

    const resetFilter = useCallback(() => {
        setData(data => {
            return {...resetAForm(data)}
        })
        setCheckboxData(checkboxData => {
            return {...resetAForm(checkboxData)}
        })
        globalDispatcher(update, {[actualTab]: ""});
        showNotification(filterResetText, SUCCESS)
    }, [actualTab, filterResetText])

    return <div className={multipleClasses(filterWrapper, modal ? filterModalWrapper : "_")}>
        <div className={header}>
            {!modal &&
                <Icon onClick={() => navigate(APP_ROUTE.BASE)} src={BackIcon} color={stylesVariables.neutralColor}
                      size={24}/>}
            <div className={title}>{filterText}</div>
            <Icon globalWrapperClassName={"pointer"} onClick={resetFilter} src={RefreshIcon}
                  color={modal ? stylesVariables.siteColor3 : stylesVariables.neutralColor} size={modal ? 22 : 26}/>

        </div>
        <div className={content}>
            {toArray(data).map(({id, data: inputData}, index) => {
                return <div data-select={inputData.type === "select"} key={makeIndex(id, index)}
                            className={inputSection}>
                    <div className={title}>
                        <Icon src={inputData.icon} size={20} color={stylesVariables.siteBlack}/>
                        <span className={label}>{inputData.name}</span>
                    </div>
                    <Input
                        className={modal ? [inputModal] : []}
                        icon={{
                            src: inputData.icon,
                            color: stylesVariables.siteColor3,
                            size: 20,
                            className: modal ? pickerIconModal : pickerIcon
                        }}
                        selectOptions={{
                            data: formatData(inputData.data, userLanguage),
                            type: "object"
                        }}
                        autoComplete="new-password"
                        value={inputData.value}
                        {...modal ? {color: stylesVariables.siteColor5} : {}}
                        {...inputData.type === "select" ? {
                            dropdownValue: inputData.value.name,
                            onDropdownChange: (value) => handleChange({
                                name: value.translations[userLanguage] ? value.translations[userLanguage].name : value.translations.fr.name,
                                id: value.id,
                            }, id)
                        } : {}}
                        error={{
                            show: inputData.touched && !inputData.valid,
                            message: inputData.errorMessage
                        }}
                        onChange={({target}) => handleChange(target.value, id)}
                        placeholder={inputData.placeholder}
                        type={inputData.type}
                    />
                </div>
            })}

            <div className={checkboxSection}>
                {toArray(checkboxData).map(({id, data: inputData}, index) => {
                    return <Input
                        className={modal ? [inputModal] : []}
                        checked={inputData.value}
                        label={inputData.name}
                        key={makeIndex(id, index)}
                        autoComplete="new-password"
                        error={{
                            show: inputData.touched && !inputData.valid,
                            message: inputData.errorMessage
                        }}
                        onChange={_ => handleCheckboxChange(id)}
                        placeholder={inputData.placeholder}
                        type={inputData.type}
                    />
                })}
            </div>
        </div>
        <div onClick={applyFilter} className={applyClass}>
            {applyText}
        </div>

    </div>
}

export default Filter
