import {createSlice} from '@reduxjs/toolkit'
import {DEFAULT_LANGUAGE, FR, TAH} from "../../util/constants";
import {FR_WORDS, TAH_WORDS} from "../../util/i18n";

/**
 * Language slice
 * A reducer for the i18n. So we can easily handle the desired user language without any library
 * @author Arnaud LITAABA
 */
const languageSlice = createSlice({
    name: 'language',
    initialState: {
        [TAH]: {...TAH_WORDS},
        [FR]: {...FR_WORDS},
        default: localStorage.getItem("lng") || DEFAULT_LANGUAGE
    },
    reducers: {
        update: (state, actions) => {
            localStorage.setItem("lng",actions.payload)
            state.default = actions.payload
        }
    }
})

export const {update} = languageSlice.actions;


/**
 * Change language
 * A function to set app language asynchronously
 * @param language {string} The desired language of the user
 * @return function A dispatcher to dispatch the action and sync with reducer
 * @author Arnaud LITAABA
 */
export const changeLanguage = (language) => (dispatch) => {
    dispatch(update(language))
}

export default languageSlice.reducer