import {makeIndex} from "../../../util";
import defaultAvatar from "../../../assets/avatar/defaultAvatar.png";
import Button from "../../../components/button/Button";
import {DEFAULT_ICON_SIZE, PROCESSING} from "../../../util/constants";
import stylesVariables from "../../../assets/styles/_variables.scss";
import MessagesIcon from "../../../assets/icons/messages.svg";
import SentIcon from "../../../assets/icons/check.svg";
import AddUserIcon from "../../../assets/icons/addUser.svg";
import membersClasses from "./members.module.scss";
import {useSelector} from "react-redux";

const {
    avatar,
    member,
    memberDetails,
    memberUsername,
    friendClass,
    notFriendClass,
    sentClass,
} = membersClasses;

/**
 *
 * @param p participant
 * @param isPassed
 * @param cancelParticipation
 * @param engageConversation
 * @param receiveInvitations
 * @param askFriend
 * @param index
 * @param workingState
 *
 * @returns {*|JSX.Element}
 *
 * @constructor
 */
export const MemberNotOwner = ({p, isPassed, cancelParticipation, engageConversation, receiveInvitations, askFriend, index, workingState}) => {
    const {
        sent,
        sendMessage, add, cancelRegister, activityAlreadyPassed, hideProfile,
        askingFriend, cancelingInscription,
        serverError, friendRequestSent, successCancelInscription
    } = useSelector((state) => state.language[state.language.default]);

    const auth = useSelector((state) => state.auth.data);
    const authId = auth["@id"];

    return (
        <div className={member} key={makeIndex('members', p.id)}>
            <img
                src={p.user["@id"] === authId ? p.user.filePath ?? defaultAvatar : p.user.profilVisibleActivities ? p.user.filePath ?? defaultAvatar : defaultAvatar}
                alt="avatar" className={avatar}/>
            <div className={memberDetails}>
                <div
                    className={memberUsername}>{p.user["@id"] === authId ? p.user.username : p.user.profilVisibleActivities ? p.user.username : hideProfile}</div>
                {p.user["@id"] === authId ?
                    <Button
                        title={isPassed ? activityAlreadyPassed : cancelRegister}
                        className={[isPassed ? sentClass : notFriendClass]}
                        label={isPassed ? activityAlreadyPassed : cancelRegister}
                        onClick={isPassed ? null : () => cancelParticipation(p)}
                        indicator={
                            workingState[p.id] === PROCESSING && {
                                color: stylesVariables.neutralColor
                            }}
                    />
                    :
                    p.user.profilVisibleActivities ? p.user.isFriend ?
                        <Button
                            title={sendMessage}
                            onClick={() => engageConversation(p.user)}
                            icon={{
                                src: MessagesIcon,
                                color: stylesVariables.siteColor3,
                                size: DEFAULT_ICON_SIZE
                            }}
                            className={[friendClass]}
                            label={sendMessage}/>
                        :
                        (p.user.isFriendPending || receiveInvitations[p.user["@id"]]) ? <Button
                            title={sent}
                            icon={{
                                src: SentIcon,
                                color: stylesVariables.neutralColor,
                                size: DEFAULT_ICON_SIZE
                            }}
                            className={[sentClass]}
                            label={sent}/> : <Button
                            title={add}
                            onClick={() => askFriend(p, index)}
                            icon={{
                                src: AddUserIcon,
                                keepColor: true,
                                size: DEFAULT_ICON_SIZE
                            }}
                            className={[notFriendClass]} indicator={
                            workingState[p.id] === PROCESSING && {
                                color: stylesVariables.neutralColor
                            }
                        }
                            label={add}/> : null
                }
            </div>
        </div>
    )
}
